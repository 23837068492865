import { injectBaseConstantMethods } from "./BaseConstants";
export const GuideTripType = {
  PRIVATE: 1,
  SHARED: 2,
};

const displayTextKeys = {
  [GuideTripType.PRIVATE]: "Private",
  [GuideTripType.SHARED]: "Shared",
};

const labelClass = {
  [GuideTripType.PRIVATE]: "",
  [GuideTripType.SHARED]: "",
};

export default injectBaseConstantMethods(
  GuideTripType,
  displayTextKeys,
  labelClass
);
