<template>
	<b-modal
		:id="id"
		:title="modalTitle"
		centered
		hide-footer
		modal-content-class="modal-container"
		@close="onClose"
		@hide="onClose"
		:no-close-on-backdrop="true"
		:no-close-on-esc="true"
		class="custom-modal"
		:body-class="modalBodyClasses"
		:size="size"
	>
		<slot name="showAllViewsUsers" />
		<slot name="showImage" />
		<slot name="showForm" />

		<slot />
	</b-modal>
</template>

<script>
import { BModal, BButton, VBModal, BAlert, BImg, BCard } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

import Button from "../components/Button.vue";
// import AllViewsUsers from "./AllViewsUsers.vue";

export default {
	name: "BasicModal",
	components: {
		VBModal,
		BButton,
		BModal,
		BAlert,
		Button,
		BImg,
		BCard,
		// AllViewsUsers,
	},
	data() {
		return {
			Image: require("@/assets/images/gallery/monthly-submissions/fish.png"),
		};
	},
	props: {
		messageText: {
			type: String,
			default: "Are you sure you want to delete this?",
		},
		messageIcon: {
			type: String,
			default: require("@/assets/images/icons/red-cross-icon.png"),
		},
		id: {
			type: String,
			default: "",
		},
		modalTitle: {
			type: String,
			default: "Modal",
		},
		classes: {
			type: String,
			default: "",
		},
		onClose: Function,
		modalBodyClasses: {
			type: String,
			default: "",
		},
		size: String,
	},
	directives: {
		"b-modal": VBModal,
		Ripple,
	},
};
</script>

<style scoped>
::v-deep .gallery-image {
	width: 100% !important;
	height: 100% !important;
	object-fit: fill !important;
}
::v-deep .modal-container {
	width: 469px !important;
	height: 638px !important;
}
::v-deep .modal-title {
	color: #003071 !important;
	font-size: 20px !important;
	font-weight: bolder !important;
}
::v-deep .small-modal {
	width: 469px !important;
	height: 261px !important;
}
::v-deep .medium-modal {
	width: 469px !important;
	height: 395px !important;
}
::v-deep .modal .modal-header {
	background-color: white !important;
}

::v-deep .modal-body-height {
	height: 70vh !important;
}

::v-deep .close {
	height: 30px !important;
	width: 30px !important;
}

::v-deep .modal-header {
	display: flex !important;
	justify-content: space-between !important;
	align-items: center !important;
}
</style>
