<template>
  <div class="table-container">
    <data-table
      :perPageLimit="pageLimit"
      entriesName="Listings"
      :isLoading="fetching"
      :showPagination="true"
      :config="config"
      :data="data"
      :perPage="data.length"
      :selectedPageValue="page"
      :onHandlePageChange="handlePageClick"
      :totalRecords="totalCatalogs"
      tableClasses="br-6"
      :onSortClick="handleSortChange"
    >
      <template #cell(Id)="data">
        <span class="font-weight-bolder"> {{ data.value }} </span>
      </template>

      <template #cell(ListingItem)="data">
        <span class="text-nowrap font-weight-bolder">
          {{ data.item.Address || data.item.Name }}
        </span>
      </template>

      <template #cell(GearCategory)="data">
        <span class="font-black">
          {{ data.item.Address ? "Location" : "Gear" }}
        </span>
      </template>

      <template #cell(Sold)="data">
        <span class="font-black">
          {{ data.value || 1 }}
        </span>
      </template>

      <template #cell(Status)="data">
        <template>
          <b-badge v-if="data.value == 1" pill variant="light-success">
            Active
          </b-badge>
          <b-badge v-else pill variant="light-warning"> Suspend </b-badge>
        </template>
      </template>

      <template #cell(Price)="data">
        <span class="text-nowrap bold">
          ${{ data.item.SellPrice || data.item.Price || 0 }}
        </span>
      </template>

      <template #cell(Actions)="data">
        <div class="text-nowrap">
          <feather-icon
            :id="`detail-${data.item.Id}-preview-icon`"
            icon="EyeIcon"
            size="16"
            class="mx-1 detail-icon cursor-pointer"
            v-on:click="
              data.item.Address
                ? viewLocationDetails(data.item.Id)
                : viewGearDetails(data.item.Id)
            "
          />
          <b-tooltip
            title="Preview Catalog"
            :target="`detail-${data.item.Id}-preview-icon`"
          />
          <feather-icon
            v-b-modal="'delete-modal'"
            :id="`delete-row-${data.item.Id}-preview-icon`"
            icon="Trash2Icon"
            size="16"
            class="mx-1 trash"
          />
          <b-tooltip
            title="Delete Catalog"
            :target="`delete-row-${data.item.Id}-preview-icon`"
          />
        </div>
      </template>
    </data-table>
    <ConfirmModal id="delete-modal" />
  </div>
</template>

<script>
import { BBadge, BTooltip } from "bootstrap-vue";
import { computed, onMounted, reactive, toRefs } from "@vue/composition-api";

import DataTable from "../../../components/data-table/DataTable.vue";
import ConfirmModal from "../../../components/ConfirmModal.vue";
import { APP_ROUTES, getRoute } from "../../../helpers/routeHelpers";
import { SortOrder } from "../../../constants";
import {
  dateTimeFormat,
  convertDateTime,
} from "../../../utils/dateFormatUtils";
import { getMediaPath } from "../../../utils/commonUtils";
import { useStore } from "../../../store";
import { usePaginatedRequest } from "../../../hooks/usePaginatedRequest";

export default {
  name: "CatalogueTable",
  components: {
    DataTable,
    BBadge,
    BTooltip,
    ConfirmModal,
  },
  setup(props, context) {
    const userId = Number(context.root.$route.params.userId);
    const store = useStore();
    const config = [
      {
        label: "ID",
        key: "Id",
      },
      {
        label: "Listing Item",
        key: "ListingItem",
      },
      {
        label: "Category",
        key: "GearCategory",
        // isSortable: true,
      },
      {
        label: "Sold",
        key: "Sold",
        // isSortable: true,
      },
      {
        label: "Status",
        key: "Status",
        // isSortable: true,
      },
      {
        label: "Price",
        key: "Price",
        // isSortable: true,
      },
      {
        label: "ACTIONS",
        key: "Actions",
      },
    ];

    let fetching = computed(() => store.state.user.catalog.fetching);
    let data = computed(() => store.getters["user/getCatalogList"]);
    let totalCatalogs = computed(() => store.state.user.catalog.totalCatalogs);
    const {
      page,
      searchText,
      handleSearchChange,
      handlePageClick,
      pageLimit,
      sortOrder,
      sortColumn,
      handleSortChange,
    } = usePaginatedRequest("user/getUserCatalogListAction", () => ({
      Direction: SortOrder.DESC,
      Column: "CreatedAt",
      UserId: userId,
    }));

    function viewGearDetails(id) {
      context.root.$router.push(
        getRoute(APP_ROUTES.USER_FISH_GEAR_DETAILS, { gearId: id, userId })
      );
    }

    function viewLocationDetails(id) {
      context.root.$router.push(
        getRoute(APP_ROUTES.USER_FISH_SPOT_DETAILS, { fishSpotId: id, userId })
      );
    }

    return {
      fetching,
      data,
      totalCatalogs,
      config,
      dateTimeFormat,
      getMediaPath,
      convertDateTime,
      searchText,
      page,
      handleSearchChange,
      handlePageClick,
      pageLimit,
      viewLocationDetails,
      viewGearDetails,
      sortOrder,
      sortColumn,
      handleSortChange,
    };
  },
};
</script>

<style scoped>
.table-container {
  background-color: #fff;
  border-radius: 6px;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

::v-deep .br-6 {
  border-radius: 6px;
}

.bold {
  font-weight: bold;
}

.font-black {
  color: black;
  font-weight: 500;
}

.sorting {
  width: 5px;
  height: 9px;
  cursor: pointer;
}

.trash {
  color: #f44336;
  cursor: pointer;
}

::v-deep .detail-icon {
  color: #888888;
}
</style>
