<template>
  <input
    ref="placeInputRef"
    type="text"
    placeholder="Type Country Name"
    v-model="getValue"
    class="input"
    :class="classes"
    :size="size"
  />
</template>
<script>
import { BFormInput } from "bootstrap-vue";

export default {
  name: "GoogleAutoCompleteInput",
  components: {
    BFormInput,
  },
  props: {
    size: {
      type: String,
      default: "lg",
    },
    value: String,
    classes: String,
    onChange: Function,
  },
  computed: {
    getValue: {
      get() {
        //this function will determine what is displayed in the input
        return this.value;
      },
      set(val) {
        //this function will run whenever the input changes and set thr value to field
        this.$emit("input", val);
      },
    },
  },
  methods: {
    initPlaceAPI() {
      let autocomplete = new window.google.maps.places.Autocomplete(
        this.$refs.placeInputRef
      );
      new window.google.maps.event.addListener(
        autocomplete,
        "place_changed",
        () => {
          let place = autocomplete.getPlace();
          this.$props.onChange(place);
        }
      );
    },
  },
  mounted() {
    this.initPlaceAPI();
  },
};
</script>

<style>
.input {
  padding: 0.438rem 1rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
}

.pac-container {
  z-index: 999999 !important;
  position: absolute !important;
}
</style>
