import { injectBaseConstantMethods } from './BaseConstants'

const GroupsVisibility = {
    PUBLIC: 1,
    PRIVATE: 2,
}

const displayTextKeys = {
    [GroupsVisibility.PRIVATE]: 'Private',
    [GroupsVisibility.PUBLIC]: 'Public',
}

const labelClass = {}

export default injectBaseConstantMethods(
    GroupsVisibility,
    displayTextKeys,
    labelClass
)
