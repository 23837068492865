<template>
	<div>
		<b-row class="align-items-center mb-2">
			<b-col md="10" lg="10"> <Nav :menuItems="menuData" /></b-col>
			<b-col class="add-post" md="2" lg="2">
				<Button
					:clickHandler="addPost"
					buttonVariant="primary"
					buttonText="Add Post"
				></Button>
			</b-col>
		</b-row>
		<b-row v-for="([first, second], index) in data" :key="index">
			<b-col md="5" lg="5">
				<community-post-list-item
					:communityPost="first"
				></community-post-list-item>
			</b-col>
			<b-col md="5" lg="5" v-if="second">
				<community-post-list-item
					:communityPost="second"
				></community-post-list-item>
			</b-col>
		</b-row>
		<template v-if="fetching">
			<div class="text-center text-primary my-2">
				<b-spinner class="align-middle"></b-spinner>
			</div>
		</template>
		<template v-if="!fetching && isDataAvailable">
			<div class="d-flex justify-content-center">
				<Button
					containerClasses="mb-2"
					:clickHandler="showMorePost"
					buttonVariant="primary"
					buttonText="Show More"
				></Button>
			</div>
		</template>
		<template v-if="!fetching && !data.length">
			<div class="d-flex justify-content-center">
				<img :src="emptyImage" class="w-25 h-25 text-center" />
			</div>
		</template>
	</div>
</template>

<script>
import { BRow, BCol, BCard, BSpinner } from 'bootstrap-vue'
import { computed, reactive, ref, toRefs, watch } from '@vue/composition-api'

import Nav from '../../components/Nav.vue'
import Button from '../../components/Button.vue'
import CommunityPostListItem from './CommunityPostListItem.vue'

import { useStore } from '../../store'
import { usePaginatedRequest } from '../../hooks/usePaginatedRequest'
import { CommunityPostCategory } from '../../constants'

export default {
	name: 'CommunityPostList',
	components: {
		BRow,
		BCol,
		BCard,
		BSpinner,
		Nav,
		CommunityPostListItem,
		Button,
	},
	setup(props, context) {
		const store = useStore()
		const routeName = context.root.$route.fullPath

		const state = reactive({
			path: routeName,
		})

		function getCategory() {
			switch (true) {
				case state.path.includes('news'):
					return CommunityPostCategory.News
				case state.path.includes('faqs'):
					return CommunityPostCategory.Faqs
				case state.path.includes('announcements'):
					return CommunityPostCategory.Announcements
				default:
					return null
			}
		}

		watch(
			() => context.root.$route,
			async (route) => {
				state.path = route.fullPath
				handlePageClick(1)
			}
		)

		const menuData = [
			{
				menuName: 'All',
				menuRoute: '/community-post',
			},
			{
				menuName: 'Blogs',
				menuRoute: '/community-post/news',
			},
			{
				menuName: 'Announcement',
				menuRoute: '/community-post/announcements',
			},
			// {
			//   menuName: "FAQS",
			//   menuRoute: "/community-post/faqs",
			// },
		]

		let fetching = computed(() => store.state.communityPost.fetching)
		let isDataAvailable = computed(
			() => store.state.communityPost.isDataAvailable
		)
		let data = computed(
			() => store.getters['communityPost/getCommunityPostList'] || []
		)
		let totalPosts = computed(() => store.state.communityPost.totalPosts)

		const {
			page,
			searchText,
			pageLimit,
			sortOrder,
			sortColumn,
			handleSearchChange,
			handlePageLimit,
			handlePageClick,
			handleSortChange,
		} = usePaginatedRequest(
			'communityPost/getPostListAction',
			() => ({
				...(getCategory() && { CategoryId: getCategory() }),
			}),
			[state]
		)

		function addPost() {
			this.$router.push('/community-post/new')
		}

		function showMorePost() {
			handlePageClick(page.value + 1)
		}
		return {
			addPost,
			menuData,
			fetching,
			data,
			totalPosts,
			emptyImage: require('@/assets/images/gallery/no-data.png'),
			showMorePost,
			isDataAvailable,
		}
	},
}
</script>

<style scoped>
.add-post {
	display: flex;
	flex-direction: row-reverse;
}
</style>