<template>
	<div class="table-container">
		<SearchHeader
			:showStatusFilter="false"
			:searchText="searchText"
			:onSearchChange="handleSearchChange"
			:pageLimitValue="pageLimit"
			:onPageLimitChange="handlePageLimitChange"
		/>
		<data-table
			:perPageLimit="pageLimit"
			entriesName="Trips"
			:config="config"
			:data="data"
			tableClasses="br-6"
			:isLoading="fetching"
			:perPage="data.length"
			:selectedPageValue="page"
			:onHandlePageChange="handlePageChange"
			:totalRecords="totalTrips"
			:onSortClick="handleSortChange"
			:currentSortOrder="sortOrder"
			:currentSortColumn="sortColumn"
		>
			<template #cell(Id)="data">
				<span class="font-weight-bolder"> {{ data.value }} </span>
			</template>

			<template #cell(Title)="data">
				<span class="text-nowrap font-weight-bolder">
					{{ data.value }}
				</span>
			</template>

			<template #cell(FishSpot)="data">
				<span class="text-nowrap font-weight-bolder">
					{{ data.item.FishSpot.Country }}
				</span>
			</template>

			<template #cell(StartDate)="data">
				<span class="text-nowrap fw-500">
					{{ data.value }}
				</span>
			</template>

			<template #cell(EndDate)="data">
				<span class="text-nowrap fw-500">
					{{ data.value }}
				</span>
			</template>

			<template #cell(TripDuration)="data">
				<p class="text-nowrap fw-500 mb-0">
					{{
						`${data.item.TripDuration} ${data.item.TripDurationUnit}`
					}}
				</p>
				<p class="text-nowrap fw-500 mb-0">
					{{
						`${convertTime({
							tz: 'LOCAL_TIME',
							time: data.item.DepartureTime,
							format: dateTimeFormat.timeWithAmPm,
						})}`
					}}
				</p>
			</template>

			<template #cell(PricePerPerson)="data">
				<span class="text-nowrap bold"> $ {{ data.value }} </span>
			</template>

			<template #cell(WeekDays)="data">
				<span
					class="text-nowrap fw-500"
					v-for="(item, index) in data.item.WeekDays"
					:key="index"
				>
					{{
						`${index != 0 ? ', ' : ''}` +
						DaysOfWeek.getDisplayTextKey(item)
					}}
				</span>
			</template>

			<template #cell(Type)="data">
				<span class="text-nowrap fw-500">
					{{ GuideTripType.getDisplayTextKey(data.value) }}
				</span>
			</template>

			<template #cell(Actions)="data">
				<div class="text-nowrap">
					<feather-icon
						:id="`details-row-${data.item.Id}-detail-icon`"
						icon="EyeIcon"
						size="16"
						v-on:click="viewTripDetails(data.item.Id)"
						class="mx-1 detail-icon cursor-pointer"
					/>
					<b-tooltip
						title="Details"
						:target="`details-row-${data.item.Id}-detail-icon`"
					/>
					<feather-icon
						:id="`invoice-row-${data.item.Id}-invoice-icon`"
						icon="FileTextIcon"
						size="16"
						v-on:click="viewBookingDetails(data.item.Id)"
						class="mx-1 detail-icon cursor-pointer blue"
					/>
					<b-tooltip
						title="View Invoice"
						:target="`invoice-row-${data.item.Id}-invoice-icon`"
					/>
					<feather-icon
						v-b-modal="'trip-details-modal'"
						:id="`trash-row-${data.item.Id}-preview-icon`"
						icon="Trash2Icon"
						size="16"
						class="mx-1 trash"
						@click="handleDeletePress(data.item.Id)"
					/>
					<b-tooltip
						title="Delete Trip"
						:target="`trash-row-${data.item.Id}-preview-icon`"
					/>
				</div>
			</template>
		</data-table>
		<ConfirmModal
			id="trip-details-modal"
			:onYesPress="deleteTrip"
			:isLoading="isLoading"
			:isSuccess="isDeleteSuccess"
			:onOkayPress="handleOkayPress"
			messageText="Are you sure you want to delete this trip?"
			successText="Trip deleted successfully !"
		/>
	</div>
</template>

<script>
import { BBadge, BTooltip } from 'bootstrap-vue'
import { computed, reactive, ref } from '@vue/composition-api'
import moment from 'moment'

import DataTable from '../../../components/data-table/DataTable.vue'
import SearchHeader from '../../../components/SearchHeader.vue'
import ConfirmModal from '../../../components/ConfirmModal.vue'
import { GuideTripType, SortOrder } from '../../../constants'
import { DaysOfWeek } from '../../../constants'
import {
	dateTimeFormat,
	convertDateTime,
	convertTime,
} from '../../../utils/dateFormatUtils'
import { APP_ROUTES, getRoute } from '../../../helpers/routeHelpers'
import { useStore } from '../../../store'
import { usePaginatedRequest } from '../../../hooks/usePaginatedRequest'

export default {
	name: 'TripsTable',
	components: {
		BTooltip,
		DataTable,
		SearchHeader,
		BBadge,
		ConfirmModal,
	},

	setup(props, context) {
		const store = useStore()
		let paramId = context.root.$route.params.guideId

		let fetching = computed(() => store.state.guides.trips.fetching)
		let data = computed(() => store.state.guides.trips.list)
		let totalTrips = computed(() => store.state.guides.trips.totalTrips)
		let config = [
			{
				label: 'ID',
				key: 'Id',
			},
			{
				label: 'Trip Name',
				key: 'Title',
			},
			{
				label: 'Location',
				key: 'FishSpot',
			},
			{
				label: 'Starting Date',
				key: 'StartDate',
			},
			{
				label: 'Ending Date',
				key: 'EndDate',
			},
			{
				label: 'Time/Duration',
				key: 'TripDuration',
			},
			{
				label: 'Days Available',
				key: 'WeekDays',
			},
			{
				label: 'Type',
				key: 'Type',
				isSortable: true,
			},
			{
				label: 'Price',
				key: 'PricePerPerson',
				isSortable: true,
			},
			{
				label: 'ACTIONS',
				key: 'Actions',
			},
		]
		// const deleteModal = ref(false);

		const selectedId = ref(null)
		const isLoading = ref(false)
		const isDeleteSuccess = ref(false)

		const {
			page,
			pageLimit,
			searchText,
			handleSearchChange,
			sortOrder,
			sortColumn,
			handlePageClick,
			handlePageLimit,
			handleSortChange,
		} = usePaginatedRequest('guides/getAGuideTripListAction', () => ({
			GuideId: paramId,
			Direction: SortOrder.DESC,
			Column: 'CreatedAt',
		}))

		function getTripEndTime(duration, starttime) {
			return moment(starttime, 'HH:mm:ss')
				.add(duration, 'hours')
				.format('HH:mm')
		}

		function viewTripDetails(id) {
			this.$router.push(
				getRoute(APP_ROUTES.TRIP_DETAILS, {
					guideId: this.$router.currentRoute.params.guideId,
					tripId: id,
				})
			)
		}

		function viewBookingDetails(id) {
			this.$router.push(
				getRoute(APP_ROUTES.BOOKING_DETAILS, {
					guideId: this.$router.currentRoute.params.guideId,
					bookingId: id,
				})
			)
		}

		const handleDeletePress = (id) => {
			selectedId.value = id
		}

		const handleOkayPress = () => {
			isDeleteSuccess.value = false
			selectedId.value = null
		}

		function deleteTrip() {
			isLoading.value = true
			store
				.dispatch('guides/deleteEntireTrip', selectedId.value)
				.then(() => {
					isDeleteSuccess.value = true
				})
				.finally(() => {
					isLoading.value = false
				})
		}
		function handlePageChange(val) {
			handlePageClick(val)
		}

		function handlePageLimitChange(val) {
			handlePageLimit(val)
		}

		return {
			// deleteModal,
			dateTimeFormat,
			convertDateTime,
			convertTime,
			GuideTripType,
			DaysOfWeek,
			data,
			fetching,
			totalTrips,
			config,
			page,
			searchText,
			handleSearchChange,
			getTripEndTime,
			viewTripDetails,
			viewBookingDetails,
			handlePageChange,
			handlePageLimitChange,
			deleteTrip,
			handleDeletePress,
			isLoading,
			isDeleteSuccess,
			handleOkayPress,
			sortOrder,
			sortColumn,
			handleSortChange,
		}
	},
}
</script>

<style scoped>
.table-container {
	background-color: #fff;
	border-radius: 6px;
	padding-bottom: 10px;
	padding-top: 2px;
}

::v-deep .br-6 {
	border-radius: 6px;
}

.bold {
	font-weight: bold;
}

.fw-500 {
	font-weight: 500;
}

::v-deep .detail-icon {
	color: #888888;
}

.blue {
	color: #1273de;
}

.trash {
	color: #f44336;
	cursor: pointer;
}
</style>
