<template>
	<div>
		<b-form-checkbox
			@change="onChange"
			:checked="isChecked"
			name="check-button"
			switch
			inline
			:disabled="isDisable"
		/>
	</div>
</template>

<script>
import { BFormCheckbox } from 'bootstrap-vue'

export default {
	name: 'SwitchButton',
	components: {
		BFormCheckbox,
	},
	props: {
		isChecked: {
			Type: Boolean,
			default: false,
		},
		isDisable: {
			Type: Boolean,
			default: false,
		},
		onChange: {
			default: () => {},
			Type: Function,
		},
	},
}
</script>
 