import { injectBaseConstantMethods } from "./BaseConstants";

export const PaymentStatus = {
  PENDING: 1,
  PROCESSING: 2,
  REFUNDED: 10,
  CANCELLED: 11,
  FAILED: 12,
  PAID: 20,
  PENDING_CAPTURE: 21,
};

const displayTextKeys = {
  [PaymentStatus.PENDING]: "Pending",
  [PaymentStatus.PROCESSING]: "Processing",
  [PaymentStatus.REFUNDED]: "Refunded",
  [PaymentStatus.CANCELLED]: "Cancelled",
  [PaymentStatus.FAILED]: "Failed",
  [PaymentStatus.PAID]: "Paid",
  [PaymentStatus.PENDING_CAPTURE]: "Pending Capture",
};

const labelClass = {
  [PaymentStatus.PENDING]: "",
  [PaymentStatus.PROCESSING]: "",
  [PaymentStatus.REFUNDED]: "",
  [PaymentStatus.CANCELLED]: "",
  [PaymentStatus.FAILED]: "",
  [PaymentStatus.PAID]: "",
  [PaymentStatus.PENDING_CAPTURE]: "",
};

export default injectBaseConstantMethods(
  PaymentStatus,
  displayTextKeys,
  labelClass
);
