export default [
  { label: "Week 01", value: "01" },
  { label: "Week 02", value: "02" },
  { label: "Week 03", value: "03" },
  { label: "Week 04", value: "04" },
  { label: "Week 05", value: "05" },
  { label: "Week 06", value: "06" },
  { label: "Week 07", value: "07" },
  { label: "Week 08", value: "08" },
  { label: "Week 09", value: "09" },
  { label: "Week 10", value: "10" },
  { label: "Week 11", value: "11" },
  { label: "Week 12", value: "12" },
  { label: "Week 13", value: "13" },
  { label: "Week 14", value: "14" },
  { label: "Week 15", value: "15" },
  { label: "Week 16", value: "16" },
  { label: "Week 17", value: "17" },
  { label: "Week 18", value: "18" },
  { label: "Week 19", value: "19" },
  { label: "Week 20", value: "20" },
  { label: "Week 21", value: "21" },
  { label: "Week 22", value: "22" },
  { label: "Week 23", value: "23" },
  { label: "Week 24", value: "24" },
  { label: "Week 25", value: "25" },
  { label: "Week 26", value: "26" },
  { label: "Week 27", value: "27" },
  { label: "Week 28", value: "28" },
  { label: "Week 29", value: "29" },
  { label: "Week 30", value: "30" },
  { label: "Week 31", value: "31" },
  { label: "Week 32", value: "32" },
  { label: "Week 33", value: "33" },
  { label: "Week 34", value: "34" },
  { label: "Week 35", value: "35" },
  { label: "Week 36", value: "36" },
  { label: "Week 37", value: "37" },
  { label: "Week 38", value: "38" },
  { label: "Week 39", value: "39" },
  { label: "Week 40", value: "40" },
  { label: "Week 41", value: "41" },
  { label: "Week 42", value: "42" },
  { label: "Week 43", value: "43" },
  { label: "Week 44", value: "44" },
  { label: "Week 45", value: "45" },
  { label: "Week 46", value: "46" },
  { label: "Week 47", value: "47" },
  { label: "Week 48", value: "48" },
  { label: "Week 49", value: "49" },
  { label: "Week 50", value: "50" },
  { label: "Week 51", value: "51" },
  { label: "Week 52", value: "51" },
];
