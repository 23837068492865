<template>
  <b-container class="bv-example-row">
    <template v-if="!fetching && data.length === 0">
      <div class="d-flex justify-content-center">
        <img :src="emptyImage" class="w-25 h-25 text-center" />
      </div>
    </template>
    <div v-for="(item, index) in data" :key="index">
      <b-card>
        <b-row>
          <b-col col md="6" lg="4" sm="12">
            <LabelText
              label="Customer"
              :text="item.User.FullName"
              showRightSection
              textClasses="font-weight-bolder"
            >
              <UserInfo
                iconSize="md"
                :avatarImage="getMediaPath(item.User.Media)"
              />
            </LabelText>
            <LabelText
              label="Purchase Date"
              :text="
                convertDateTime({
                  date: item.PurchaseDate,
                  customFormat: dateTimeFormat.dateWithYearMonthFormat,
                  dateOnly: true,
                })
              "
              textClasses="font-weight-bolder"
            />
            <LabelText
              label="Category"
              :text="item.GuideTrip ? 'Guide' : 'Location'"
              textClasses="font-weight-bolder"
            />
            <LabelText
              label="Ratings"
              :text="''"
              showRightSection
              textClasses="font-weight-bolder"
            >
              <Ratings :rateInNumber="item.Rating" />
            </LabelText>
          </b-col>
          <b-col col md="6" lg="4" sm="12">
            <LabelText
              label="Seller"
              :text="currentUser.FullName"
              showRightSection
              textClasses="font-weight-bolder"
            >
              <UserInfo
                iconSize="md"
                :avatarImage="getMediaPath(currentUser.Media)"
              />
            </LabelText>
            <LabelText
              label="Listing Item"
              :text="item.Title"
              textClasses="font-weight-bolder"
            />
            <div v-if="item.GuideTrip">
              <LabelText
                label="Trip Name"
                :text="item.TripName"
                textClasses="font-weight-bolder"
              />
            </div>
            <LabelText
              label="Price"
              :text="item.Price"
              textClasses="font-weight-bolder"
            />
          </b-col>
          <b-col col md="12" lg="4" sm="12">
            <div class="">
              <p class="review-title">Reviews:</p>
              <p v-if="index == 0" class="font-weight-bolder review-height">
                {{ item.Content }}
              </p>
              <p v-else class="font-weight-bolder review-height">No Comments</p>
            </div>
          </b-col>
        </b-row>
      </b-card>
    </div>
    <template v-if="fetching">
      <div class="text-center text-primary my-2">
        <b-spinner class="align-middle"></b-spinner>
      </div>
    </template>
    <div v-if="dataAvailable" class="text-center mb-2">
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        variant="primary"
        size="sm"
        @click="loadMoreData"
      >
        Load More
      </b-button>
    </div>
  </b-container>
</template>

<script>
import { computed, onMounted, reactive, toRefs } from "@vue/composition-api";

import { dateTimeFormat, convertDateTime } from "../../utils/dateFormatUtils";
import LabelText from "../../components/LabelText.vue";
import Ratings from "../../components/Ratings.vue";
import UserInfo from "../../components/UserInfo.vue";
import AvatarImage from "../../assets/images/avatars/8.png";

import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BTableLite,
  BCardText,
  BButton,
  BAlert,
  BLink,
  BAvatar,
  BOverlay,
  BSpinner,
} from "bootstrap-vue";
import { usePaginatedRequest } from "@/hooks/usePaginatedRequest";
import { useStore } from "../../store";
import { getMediaPath } from "../../utils/commonUtils";
import { getUserDetail } from "../../api/api.service";
export default {
  name: "Reviews",
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
    LabelText,
    BAvatar,
    BOverlay,
    BSpinner,
    Ratings,
    UserInfo,
  },
  setup(props, context) {
    const userId = Number(context.root.$route.params.userId);
    const store = useStore();

    let fetching = computed(() => store.state.user.review.fetching);
    let data = computed(() => store.getters["user/getReviewList"]);
    let totalReviews = computed(() => store.state.user.review.totalReviews);
    let dataAvailable = computed(() => store.state.user.review.dataAvailable);
    const state = reactive({ currentUser: {} });

    state.currentUser =
      computed(() => store.getters["user/getUserById"](userId)).value || {};

    onMounted(() => {
      getUserDetail(userId).then((res) => {
        state.currentUser = res.User;
      });
    });

    const { page, searchText, handleSearchChange, handlePageClick } =
      usePaginatedRequest("user/getUserReviewListAction", () => ({
        UserId: userId,
      }));

    function loadMoreData() {
      handlePageClick(page.value + 1);
    }

    return {
      ...toRefs(state),
      AvatarImage,
      emptyImage: require("@/assets/images/gallery/no-data.png"),
      getMediaPath,
      fetching,
      data,
      totalReviews,
      dataAvailable,
      loadMoreData,
      dateTimeFormat,
      convertDateTime,
    };
  },
};
</script>

<style lang="scss">
@import "../~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style scoped>
.review-height {
  line-height: 25px;
  color: black;
}
.review-title {
  color: #888888;
}
.card-body {
  padding-bottom: 0px !important;
}
</style>
