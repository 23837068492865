import { injectBaseConstantMethods } from './BaseConstants'

export const LCAApproval = {
    VERIFIED: 1,
    UNVERIFIED: 2,
}
const displayTextKeys = {
    [LCAApproval.VERIFIED]: 'Verified by Louisiana Charters Board Association',
    [LCAApproval.UNVERIFIED]:
        'Unverified by Louisiana Charters Board Association',
}

const labelClass = {
    [LCAApproval.VERIFIED]: '',
}

export default injectBaseConstantMethods(
    LCAApproval,
    displayTextKeys,
    labelClass
)
