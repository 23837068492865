import { injectBaseConstantMethods } from "./BaseConstants";

export const VoucherStatus = {
  Pending: 1,
  Redeemed: 10,
  Expired: 20,
  UnSold: 30,
};
const displayTextKeys = {
  [VoucherStatus.Pending]: "Pending",
  [VoucherStatus.Expired]: "Expired",
  [VoucherStatus.Redeemed]: "Redeemed",
  [VoucherStatus.UnSold]: "UnSold",
};

const labelClass = {
  [VoucherStatus.Pending]: "",
  [VoucherStatus.Expired]: "",
  [VoucherStatus.Redeemed]: "",
  [VoucherStatus.UnSold]: "",
};

export default injectBaseConstantMethods(
  VoucherStatus,
  displayTextKeys,
  labelClass,
);
