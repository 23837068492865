<template>
	<div :class="containerClasses">
		<b-button
			v-ripple.400="'rgba(255, 255, 255, 0.15)'"
			:size="size"
			@click="handleClick"
			:class="classes"
			:variant="buttonVariant"
			:block="blockButton"
			:type="type"
		>
			<loader v-if="isLoading" :small="true"> </loader>
			{{ isLoading ? '' : buttonText }}
		</b-button>
	</div>
</template>

<script>
import { BButton } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import Loader from './Loader.vue'

export default {
	name: 'Button',
	components: {
		BButton,
		Loader,
	},
	methods: {
		handleClick(e) {
			e.stopPropagation()
			if (
				!this.$props.isLoading &&
				typeof this.$props.clickHandler === 'function'
			) {
				this.clickHandler()
			}
		},
	},
	props: {
		clickHandler: Function,
		classes: String,
		isLoading: false,
		buttonText: {
			type: String,
			default: 'Submit',
		},
		buttonVariant: {
			type: String,
			default: 'danger',
		},
		buttonIcon: Function,
		size: {
			type: String,
			default: '',
		},
		type: {
			type: String,
			default: '',
		},

		blockButton: {
			type: Boolean,
			default: false,
		},
		containerClasses: {
			type: String,
			default: '',
		},
	},
	directives: {
		Ripple,
	},
}
</script>

<style scoped>
.btn-outline-grey {
	border-color: #6c757d;
	color: #82868b;
}
.btn-outline-danger {
	border-color: #ff6060;
	color: #ff6060;
}

::v-deep .loader {
	height: 2px;
	width: 2px;
}
</style>
