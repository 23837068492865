<template>
  <div class="table-container">
    <b-card body-class="card-body-style">
      <search-header
        :onSearchChange="handleSearchChange"
        :onFilterChange="handleStatusChange"
        :searchText="searchText"
        :statusOptions="spotLocationStatus"
        :filterValue="filters.Status"
        :filterConstants="FishSpotLocationStatus"
        :pageLimitValue="pageLimit"
        :onPageLimitChange="handlePageLimitChange"
      ></search-header>
      <data-table
        :perPageLimit="pageLimit"
        entriesName="Spots"
        :showPagination="true"
        :config="config"
        :data="data"
        :selectedPageValue="page"
        :perPage="data.length"
        :onHandlePageChange="handlePageChange"
        :onSortClick="handleSortChange"
        :totalRecords="total"
        :isLoading="isFetching"
      >
        <template #cell(Id)="data">
          <span class="font-weight-bolder"> {{ data.value }} </span>
        </template>

        <template #cell(SpotName)="data">
          <span class="font-weight-bolder">
            {{ data.item.Title || "-" }}
          </span>
        </template>

        <template #cell(SellerName)="data">
          <user-info
            iconSize="md"
            :fullName="data.item.User.FullName || '-'"
            :avatarImage="getMediaPath(data.item.User.Media)"
            nameClasses="font-weight-bolder"
          />
        </template>

        <template #cell(Sold)="data">
          <span class="time"> {{ data.item.SoldCount }} times </span>
        </template>

        <template #cell(Price)="data">
          <span class="font-weight-bolder">$ {{ data.item.SellPrice }} </span>
        </template>

        <template #cell(Location)="data">
          <div class="font-weight-bolder">{{ data.item.Address }}</div>
          <span class="address-cordinates"
            >{{ data.item.Location.coordinates[0] }} -
          </span>
          <span class="address-cordinates">{{
            data.item.Location.coordinates[1]
          }}</span>
        </template>

        <template #cell(Status)="data">
          <template>
            <b-badge
              v-if="data.item.Status == FishSpotLocationStatus.ACTIVE"
              pill
              variant="light-success"
            >
              {{
                FishSpotLocationStatus.getDisplayTextKey(data.item.Status) ||
                "-"
              }}
            </b-badge>
            <b-badge
              v-else-if="data.item.Status == FishSpotLocationStatus.SOLD"
              pill
              variant="light-secondary"
            >
              {{
                FishSpotLocationStatus.getDisplayTextKey(data.item.Status) ||
                "-"
              }}
            </b-badge>
          </template>
        </template>

        <template #cell(Actions)="data">
          <div class="text-nowrap">
            <feather-icon
              :id="`fish-spot-row-${data.item.Id}-detail-icon`"
              icon="EyeIcon"
              size="16"
              class="mx-1 detail-icon cursor-pointer"
              v-on:click="viewDetails(data.item.Id)"
            />
            <b-tooltip
              title="Detail"
              :target="`fish-spot-row-${data.item.Id}-detail-icon`"
            />

            <img
              v-b-modal="'fishing-spot-modal'"
              @click="handleDeleteClick(data.item.Id)"
              :id="`fish-spot-row-${data.item.Id}-action-icon`"
              class="delete-icon"
              :src="deleteIcon"
            />

            <b-tooltip
              title="Delete"
              class="cursor-pointer"
              :target="`fish-spot-row-${data.item.Id}-action-icon`"
            />
          </div>
        </template>
      </data-table>
    </b-card>
    <ConfirmModal
      id="fishing-spot-modal"
      :isLoading="isProcessing"
      :isSuccess="deleteSuccess"
      successText="Fish Spot Deleted Successfully !"
      :onOkayPress="handleOkayPress"
      :onYesPress="handleYesPress"
      messageText="Are you sure you want to delete this spot?"
    />
  </div>
</template>

<script>
import { BCard, BCardBody, BBadge, BLink, BTooltip } from "bootstrap-vue";
import { reactive, ref } from "@vue/composition-api";

import DataTable from "../../components/data-table/DataTable.vue";
import UserInfo from "../../components/UserInfo.vue";
import SearchHeader from "../../components/SearchHeader.vue";
import ConfirmModal from "../../components/ConfirmModal.vue";
import { FishSpotLocationStatus, SortOrder } from "../../constants";
import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers";
import { getFishSpotListing, deleteFishSpot } from "../../api/api.service";
import { getMediaPath } from "../../utils/mediaUtils";
import { useLocalPagination } from "../../hooks/useLocalPagination";

import DeleteIcon from "../../assets/images/icons/delete.svg";

const config = [
  {
    label: "ID",
    key: "Id",
  },
  {
    label: "SPOT NAME",
    key: "SpotName",
  },
  {
    label: "SELLER NAME",
    key: "SellerName",
  },
  {
    label: "LOCATION",
    key: "Location",
  },
  {
    label: "STATUS",
    key: "Status",
    isSortable: true,
  },
  {
    label: "SOLD",
    key: "SoldCount",
    isSortable: true,
  },
  {
    label: "PRICE",
    key: "SellPrice",
    isSortable: true,
  },
  {
    label: "ACTIONS",
    key: "Actions",
  },
].map((item) => {
  return { ...item, tdClass: "table-row" };
});

export default {
  name: "FishSpotListing",
  components: {
    DataTable,
    UserInfo,
    BCard,
    BCardBody,
    BBadge,
    BLink,
    BTooltip,
    SearchHeader,
    ConfirmModal,
  },
  setup(props, context) {
    const filters = reactive({ Status: FishSpotLocationStatus.SOLD });
    const selectedId = ref(null);
    const isProcessing = ref(false);
    const deleteSuccess = ref(false);

    const {
      data,
      isFetching,
      total,
      page,
      searchText,
      pageLimit,
      sortOrder,
      sortColumn,
      handleSearchChange,
      handlePageClick,
      handlePageLimit,
      handleSortChange,
    } = useLocalPagination(
      getFishSpotListing,
      () => ({
        Direction: SortOrder.DESC,
        Column: "CreatedAt",
        ...(filters.Status && { Status: filters.Status }),
      }),
      [filters],
      "Q",
      "Locations",
      "TotalLocations"
    );

    const spotLocationStatus = Object.values(FishSpotLocationStatus).map(
      (val) => ({
        label: FishSpotLocationStatus.getDisplayTextKey(val),
        value: FishSpotLocationStatus.getDisplayTextKey(val),
        id: val,
      })
    );

    const handlePageChange = (pageNumber) => {
      handlePageClick(pageNumber);
    };

    const handlePageLimitChange = (val) => {
      page.value = 1;

      handlePageLimit(val);
    };

    const handleStatusChange = (item) => {
      page.value = 1;
      if (!item?.id) {
        filters.Status = null;
        return;
      }
      filters.Status = item.id;
    };

    const handleYesPress = () => {
      isProcessing.value = true;

      deleteFishSpot(selectedId.value)
        .then(() => {
          data.value = data.value.filter(
            (item) => item.Id !== selectedId.value
          );
          deleteSuccess.value = true;
        })
        .finally(() => {
          isProcessing.value = false;
        });
    };

    const handleOkayPress = () => {
      deleteSuccess.value = false;
      selectedId.value = null;
    };

    const handleDeleteClick = (id) => {
      selectedId.value = id;
    };

    const viewDetails = (id) => {
      context.root.$router.push(
        getRoute(APP_ROUTES.FISH_SPOT_DETAILS, { fishSpotId: id })
      );
    };

    return {
      deleteIcon: DeleteIcon,
      config,
      data,
      total,
      page,
      pageLimit,
      isFetching,
      sortOrder,
      sortColumn,
      searchText,
      FishSpotLocationStatus,
      spotLocationStatus,
      filters,
      isProcessing,
      deleteSuccess,
      handleOkayPress,
      handleDeleteClick,
      handleYesPress,
      handleSearchChange,
      handlePageChange,
      handlePageLimit,
      handleSortChange,
      handleStatusChange,
      getMediaPath,
      viewDetails,
      handlePageLimitChange,
    };
  },
};
</script>

<style scoped>
::v-deep .active-icon {
  color: #28c76f;
}

::v-deep .inactive-icon {
  color: #d88416;
}

::v-deep .detail-icon {
  color: #888888;
}

.delete-icon {
  width: 14px;
  height: 14px;
  cursor: pointer;
}

.time {
  font-weight: 500;
}

.card-body-style {
  padding: 0 !important;
}
::v-deep .table-row {
  font-weight: bold;
}

::v-deep .address-cordinates {
  color: #888888;
  font-size: 12px;
}
.table-container {
  margin-top: -10px;
}
</style>
