<template>
	<div class="table-container">
		<b-card body-class="p-0">
			<search-header
				searchInputClasses="w-50"
				:searchText="searchText"
				:onSearchChange="handleSearchChange"
				:pageLimitValue="pageLimit"
				:onPageLimitChange="handlePageLimit"
				:showStatusFilter="false"
			>
			</search-header>
			<data-table
				:perPageLimit="pageLimit"
				entriesName="Email templates"
				:perPage="data.length"
				:isLoading="isFetching"
				:showPagination="true"
				:config="config"
				:data="data"
				:selectedPageValue="page"
				:onHandlePageChange="handlePageClick"
				:onSortClick="handleSortChange"
				:totalRecords="total"
			>
				<!--#ID-->
				<template #cell(Id)="data">
					<span class="font-weight-bolder"> {{ data.value }} </span>
				</template>
				<!--#TEMPLATE NAME-->
				<template #cell(Name)="data">
					<span class="text-nowrap">
						<span class="font-weight-bolder">
							{{ data.item.EmailTemplate.Name || '-' }}
						</span>
					</span>
				</template>

				<!--#DATE CREATED-->
				<template #cell(CreatedAt)="data">
					<span class="text-nowrap">
						{{
							convertDateTime({
								date: data.value,
								customFormat: dateTimeFormat.appDateFormat,
								dateOnly: true,
							})
						}}
					</span>
				</template>

				<!--#SENT TO-->
				<template #cell(SendToUsers)="data">
					<span class="text-nowrap">
						{{ EmailSendType.getDisplayTextKey(data.item.SendToType) }}
					</span>
				</template>

				<!-- ACTIONS -->
				<template #cell(Actions)="data">
					<div class="text-nowrap d-flex justify-content-between w-50">
						<!-- View -->
						<feather-icon
							:id="`email-template-${data.item.Id}-view`"
							icon="EyeIcon"
							size="16"
							class="view-icon cursor-pointer"
							@click="handleViewEmailTemplateDetails(data.item.Id)"
						/>
						<b-tooltip
							title="View"
							:target="`email-template-${data.item.Id}-view`"
						/>

						<!-- Delete -->
						<feather-icon
							v-b-modal="`delete-template-modal`"
							:id="`email-template-${data.item.Id}-delete`"
							icon="Trash2Icon"
							size="16"
							class="trash-icon cursor-pointer"
							@click="handleDeleteEmailTemplate(data.item.Id)"
						/>
						<b-tooltip
							title="Delete"
							class="cursor-pointer"
							:target="`email-template-${data.item.Id}-delete`"
						/>
					</div>
				</template>
			</data-table>

			<DeleteModal
				:id="`delete-template-modal`"
				:onYesPress="handleDelete"
				:isLoading="isDeleting"
				:isSuccess="deleteSuccess"
				:onOkayPress="handleOkayPress"
				messageText="Are you sure you want to delete this email template history ?"
				successText="Email template history deleted successfully !"
			/>
		</b-card>
	</div>
</template>

<script>
import { BCard, BCardBody, BBadge, BLink, BTooltip } from 'bootstrap-vue'
import { ref } from '@vue/composition-api'

import DeleteModal from '../../components/ConfirmModal.vue'
import DataTable from '../../components/data-table/DataTable.vue'
import SearchHeader from '../../components/SearchHeader.vue'
import Button from '../../components/Button.vue'
import showToast from '../../hooks/useToast'
import { dateTimeFormat, convertDateTime } from '../../utils/dateFormatUtils'
import { useLocalPagination } from '../../hooks/useLocalPagination'
import { APP_ROUTES, getRoute } from '../../helpers/routeHelpers'
import { SortOrder, EmailSendType } from '../../constants'
import {
	getEmailTemplatesHistory,
	deleteEmailTemplateHistory,
} from '../../api/api.service'

let config = [
	{
		label: 'ID',
		key: 'Id',
	},
	{
		label: 'TEMPLATE NAME',
		key: 'Name',
	},

	{
		label: 'DATE SENT',
		key: 'CreatedAt',
		isSortable: true,
	},

	{
		label: 'SENT TO ',
		key: 'SendToUsers',
	},
	{
		label: 'ACTIONS',
		key: 'Actions',
	},
]

export default {
	name: 'EmailTemplateListing',
	components: {
		BCard,
		BCardBody,
		BBadge,
		BLink,
		BTooltip,
		DataTable,
		SearchHeader,
		DeleteModal,
		Button,
	},
	setup(_, context) {
		const selectedEmailTemplateId = ref('')
		const isDeleting = ref(false)
		const deleteSuccess = ref(false)

		const {
			data,
			isFetching,
			total,
			page,
			searchText,
			pageLimit,
			sortOrder,
			sortColumn,
			handleSortChange,
			handleSearchChange,
			handlePageClick,
			handlePageLimit,
			request,
		} = useLocalPagination(
			getEmailTemplatesHistory,
			() => ({
				Direction: SortOrder.DESC,
				Column: 'CreatedAt',
			}),
			[],
			'Q',
			'SentEmails',
			'TotalSentEmails'
		)

		function handleViewEmailTemplateDetails(id) {
			this.$router.push(
				getRoute(APP_ROUTES.VIEW_EMAIL_TEMPLATE_HISTORY_DETAILS, {
					historyTemplateId: id,
				})
			)
		}

		function handleEditEmailTemplate(id) {
			let getTemplateName = data?.value?.find((item) => item.Id == id)?.Name
			let strWithoutSpaces = getTemplateName.replaceAll(' ', '-')

			this.$router.push(
				getRoute(APP_ROUTES.EDIT_EMAIL_TEMPLATE, {
					templateId: id,
					templateName: strWithoutSpaces,
				})
			)
		}

		function handleOkayPress() {
			deleteSuccess.value = false
			selectedEmailTemplateId.value = null
		}

		function handleDeleteEmailTemplate(id) {
			selectedEmailTemplateId.value = id
		}

		const handleDelete = async () => {
			try {
				isDeleting.value = true
				let res = await deleteEmailTemplateHistory(
					selectedEmailTemplateId.value
				)
				data.value = data.value.filter(
					(item) => item.Id !== selectedEmailTemplateId.value
				)

				total.value = total.value - 1

				if (total.value && !Boolean(data.value.length)) {
					page.value = page.value - 1
				}
				deleteSuccess.value = true

				showToast(context.root, {
					Title: 'Deleted Successfully',
					Icon: 'CheckCircleIcon',
					Text: res.Message,
					Variant: 'success',
				})
			} catch (err) {
				showToast(context.root, {
					Title: 'Error',
					Variant: 'danger',
					Icon: 'XCircleIcon',
					Text: err.Message,
				})
			} finally {
				isDeleting.value = false
			}
		}

		return {
			data,
			page,
			total,
			config,
			sortOrder,
			EmailSendType,
			sortColumn,
			isDeleting,
			deleteSuccess,
			searchText,
			pageLimit,
			isFetching,
			dateTimeFormat,
			handleDelete,
			convertDateTime,
			handlePageClick,
			handlePageLimit,
			handleOkayPress,
			handleSortChange,
			handleSearchChange,
			handleDeleteEmailTemplate,
			handleViewEmailTemplateDetails,
			handleEditEmailTemplate,
		}
	},
}
</script>

<style scoped>
.view-icon {
	color: #888888;
}

.trash-icon {
	color: #f44336;
}

.table-container {
	margin-top: -10px;
}
</style>
