<template>
  <b-row class="mb-1" no-gutters>
    <div class="w-100 text-center">
      <BSpinner variant="primary" class="spinner" v-if="isLoading" />
    </div>
    <p
      v-if="!Object.keys(userBookings).length && !isLoading"
      class="font-weight-bolder"
    >
      No Bookings
    </p>
    <b-col
      lg="12"
      md="12"
      sm="12"
      v-for="(bookings, bookingDate) in userBookings"
      :key="bookingDate"
      class="mb-1"
    >
      <app-collapse v-if="!isLoading">
        <app-collapse-item headerTitleClass="header-title" :title="bookingDate">
          <template v-slot:header-content>
            <!-- Need to visit it again -->
            <Button
              v-if="isFutureTrips"
              size="md"
              :buttonVariant="`${
                bookings[0].Status == TripsBookingStatus.TRIPCANCELLED
                  ? 'danger'
                  : 'outline-danger'
              }`"
              classes="wd-211"
              :clickHandler="
                () => {
                  bookings[0].Status != TripsBookingStatus.TRIPCANCELLED &&
                    isTripToCancle(bookingDate);
                }
              "
              :buttonText="`${
                bookings[0].Status == TripsBookingStatus.TRIPCANCELLED
                  ? 'Trip Cancelled'
                  : 'Cancel Trip'
              }`"
            />
          </template>
          <template class="mt-3">
            <hr class="separator" />
            <div
              v-for="(item, index) in bookings"
              :key="index"
              class="d-flex justify-content-between flex-wrap w-100"
            >
              <div class="bold name">
                <user-info
                  iconSize="sm"
                  :fullName="validateValue(item.User.FullName)"
                  :avatarImage="getMediaPath(item.User.Media)"
                  nameClasses="f-bold"
                />
              </div>
              <div class="bold">
                <LabelText
                  label="Phone Number"
                  :text="item.User.PhoneNumber"
                  textClasses="f-bold"
                />
              </div>
              <div class="bold">
                <label-text
                  label="Booking Date"
                  :text="formatBookingDate(item.BookingDate)"
                  textClasses="f-bold"
                />
              </div>
              <div class="bold">
                <label-text
                  label="Booked Seats"
                  :text="validateValue(item.NumberOfPerson)"
                  textClasses="f-bold"
                />
              </div>
              <div class="bold">
                <label-text
                  label="Price"
                  :text="`$${item.NetAmount}`"
                  textClasses="f-bold"
                />
              </div>
              <div class="bold">
                <label-text
                  label="Payment Status"
                  :text="`${
                    item.IsRefundable
                      ? 'To Be Refunded'
                      : PaymentStatus.getDisplayTextKey(item.TransactionStatus)
                  }`"
                  textClasses="f-bold"
                />
              </div>
              <div v-on:click="() => onDetailClick(item)">
                <feather-icon
                  icon="EyeIcon"
                  size="16"
                  class="mx-1 detail-icon cursor-pointer"
                />
              </div>
            </div>
          </template>
        </app-collapse-item>
      </app-collapse>
    </b-col>
    <ConfirmModal
      id="cancel-trip-modal"
      :onYesPress="cancleATripHandler"
      :isLoading="tripCancleLoader"
      :isSuccess="isTripDeleteSuccess"
      :onOkayPress="handleOkayPress"
      messageText="Are you sure you want to cancel this trip?"
      successText="Trip cancelled successfully !"
    />
    <b-modal
      id="view-details"
      centered
      hide-footer
      hide-header
      modal-content-class="modal-container"
      @close="onClose"
      @hide="onClose"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      class="custom-modal"
    >
      <div v-if="selectedBooking" class="detail-modal-body">
        <div class="detail-modal-header">
          <user-info
            iconSize="md"
            :fullName="validateValue(selectedBooking.User.FullName)"
            :avatarImage="getMediaPath(selectedBooking.User.Media)"
            nameClasses="f-bold"
          />
          <feather-icon
            icon="XCircleIcon"
            size="20"
            class="cursor-pointer close-icon"
            v-on:click="handleDetailModalClose()"
          />
        </div>
        <div class="dm-body">
          <LabelText
            label="Booking ID"
            :text="selectedBooking.OrderNumber"
            textClasses="font-weight-bolder"
            containerClasses="dm-label"
          />
          <LabelText
            label="No of Persons Booked"
            :text="
              !selectedBooking.NumberOfAdult &&
              !selectedBooking.NumberOfChildren
                ? `${selectedBooking.NumberOfPerson || 1} Persons`
                : `${selectedBooking.NumberOfAdult} Adults + ${selectedBooking.NumberOfChildren} Children`
            "
            containerClasses="dm-label"
            textClasses="font-weight-bolder"
          />
          <LabelText
            label="Total Amount"
            :text="`$${selectedBooking.TotalAmount || '0'}`"
            containerClasses="dm-label"
            textClasses="font-weight-bolder"
          />
          <LabelText
            v-if="
              selectedBooking.Meta &&
              selectedBooking.Meta.PaymentType === TripPaymentType.DEPOSIT
            "
            label="Down Payment"
            :text="`$${selectedBooking.DownPayment} (${
              selectedBooking.Meta && selectedBooking.Meta.DepositPercent
                ? selectedBooking.Meta.DepositPercent + '%'
                : ''
            })`"
            containerClasses="dm-label"
            textClasses="font-weight-bolder"
          />
          <LabelText
            v-if="
              selectedBooking.Meta &&
              selectedBooking.Meta.PaymentType === TripPaymentType.DEPOSIT
            "
            label="Remaining Payment"
            :text="`$${selectedBooking.RemainingAmount}`"
            containerClasses="dm-label"
            textClasses="font-weight-bolder"
          />
          <LabelText
            label="Transaction Fee"
            :text="`$${selectedBooking.TransactionFee || '0'}`"
            containerClasses="dm-label"
            textClasses="font-weight-bolder"
          />
          <LabelText
            label="Payment Processing Fee"
            :text="`$${selectedBooking.GatewayFee || '0'}`"
            containerClasses="dm-label"
            textClasses="font-weight-bolder"
          />
          <LabelText
            label="Paid Amount"
            :text="`$${selectedBooking.NetAmount || '0'}`"
            containerClasses="dm-label"
            textClasses="font-weight-bolder"
          />
          <div class="additional-notes-wrapper">
            <p class="additional-notes-title">Notes:</p>
            <p class="additional-notes">
              {{ selectedBooking.AdditionalNotes || "-" }}
            </p>
          </div>
        </div>
      </div>
    </b-modal>
  </b-row>
</template>

<script>
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BTableLite,
  BCardText,
  BButton,
  BAlert,
  BLink,
  BSpinner,
  BModal,
  VBModal,
} from "bootstrap-vue";
import moment from "moment";
import ConfirmModal from "../../../components/ConfirmModal.vue";

import Avatar from "@/components/Avatar.vue";
import LabelText from "@/components/LabelText.vue";
import Button from "@/components/Button.vue";
import UserInfo from "@/components/UserInfo.vue";
import Title from "@/components/Title.vue";
import FileItem from "@/components/FileItem.vue";
import TripsListing from "./TripsListing.vue";
import showToast from "@/hooks/useToast";
import { getTripBookingsList, cancleATrip } from "@/api/api.service";
import { getRouteId } from "@/utils/getRouteId";
import { TripsBookingStatus } from "@/constants/index";
import { getMediaPath, validateValue } from "@/utils/commonUtils";
import { PaymentStatus, TripPaymentType } from "@/constants";
// import BasicModal from "@/components/BasicModal.vue";

export default {
  name: "BookingDetailItem",
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
    BSpinner,
    AppCollapse,
    AppCollapseItem,
    LabelText,
    Avatar,
    Button,
    Title,
    FileItem,
    TripsListing,
    UserInfo,
    ConfirmModal,
    // BasicModal,
    VBModal,
    BModal,
  },

  data() {
    return {
      tripDate: "",
      id: "",
      tripCancleLoader: false,
      isLoading: false,
      selectedBooking: null,
      TripPaymentType,
      isTripDeleteSuccess: false,
      userBookings: {},
      TripsBookingStatus,
      PaymentStatus,
      getMediaPath,
      validateValue,
      getRouteId,
    };
  },

  props: {
    isFutureTrips: {
      type: Boolean,
      default: true,
    },
  },
  directives: {
    "b-modal": VBModal,
  },
  methods: {
    structureTripBooking(tripbookings) {
      let structuredData = {};
      let bookingDatesArr = tripbookings.map((item) => item.BookingDate);
      let filteredDates = [...new Set(bookingDatesArr)];

      filteredDates.forEach((item) => {
        structuredData[item] = [
          ...tripbookings.filter((data) => data.BookingDate == item),
        ];
      });

      return structuredData;
    },
    handleOkayPress() {
      console.log("upper wala");
      this.isTripDeleteSuccess = false;
    },
    onDetailClick(data) {
      this.selectedBooking = data;
      this.$bvModal.show("view-details");
    },
    handleDetailModalClose() {
      this.selectedBooking = null;
      this.$bvModal.hide("view-details");
    },
    isTripToCancle(date) {
      this.$bvModal.show("cancel-trip-modal");
      this.tripDate = date;
    },

    async cancleATripHandler() {
      let date = this.tripDate;
      if (!date) {
        return;
      }

      let bodyData = {
        BookingDate: date,
      };
      try {
        this.tripCancleLoader = true;
        let response = await cancleATrip(this.id, bodyData);
        if (response.Message == "Success") {
          this.tripCancleLoader = false;
          this.isTripDeleteSuccess = true;
          this.userBookings[date].map(
            (item) => (item.Status = TripsBookingStatus.TRIPCANCELLED)
          );
          showToast(this, {
            Title: `Trip Has Been Cancelled`,
            Icon: "CheckCircleIcon",
            Position: "top-right",
            Text: response.Message,
          });
        }
      } catch (err) {
        this.tripCancleLoader = false;
        showToast(this, {
          Title: "Error",
          Icon: "XCircleIcon",
          Text: err.Message,
          Variant: "danger",
          Position: "top-right",
        });
      } finally {
        this.tripCancleLoader = false;
      }
    },
    formatBookingDate(val) {
      return moment(val, "YYYY-MM-DD").format("Do MMM YYYY");
    },

    getTripBookingHandler(futuretrip) {
      this.id = this.$route.params.bookingId;

      let parameters = {
        TripId: this.id,
        FutureTrips: futuretrip,
      };
      this.isLoading = true;

      getTripBookingsList(parameters)
        .then((res) => {
          let { TripBookings, TotalTripBookings } = res;
          if (TripBookings && TotalTripBookings) {
            this.userBookings = { ...this.structureTripBooking(TripBookings) };
          } else {
            this.userBookings = {};
          }
          this.isLoading = false;
        })
        .catch((err) => {
          showToast(this, {
            Title: "Error",
            Icon: "XCircleIcon",
            Text: err.Message,
            Variant: "danger",
            Position: "top-right",
          });
          this.isLoading = false;
        });
    },
  },
  created() {
    this.getTripBookingHandler(this.isFutureTrips);
  },
  watch: {
    isFutureTrips: function (val) {
      this.getTripBookingHandler(val);
    },
  },
};
</script>

<style scoped>
::v-deep .header-title {
  font-weight: bolder !important;
  color: black;
}

.separator {
  margin: 0 !important;
  margin-bottom: 17px !important;
}

::v-deep .bold .f-bold {
  font-weight: 800 !important;
  color: black !important;
}
.spinner {
  width: 5rem;
  height: 5rem;
  margin-top: 20px;
}

.name {
  margin-top: 3px;
}
.detail-modal-body {
  padding: 10px 0;
}
.detail-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}
.detail-modal-header .close-icon {
  color: red;
}
.dm-label {
  margin-bottom: 5px !important;
}
.additional-notes-wrapper {
  display: flex;
}
.additional-notes-title {
  margin-bottom: 0;
  margin-right: 5px;
}
.additional-notes {
  font-weight: bold;
  color: #000;
}
</style>
