<template>
  <b-spinner :variant="variant" :class="classes" :small="small"></b-spinner>
</template>

<script>
import { BSpinner } from "bootstrap-vue";

export default {
  components: {
    BSpinner,
  },
  props: {
    classes: String,
    variant: String,
    small: false,
  },
};
</script>

<style></style>
