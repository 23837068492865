<template>
	<b-card class="card-container mt-2 mb-2 mr-2">
		<div>
			<div class="view-header">
				<UserInfo
					iconSize="md"
					:fullName="userName"
					:avatarImage="userAvatar"
				/>
			</div>

			<div class="d-flex justify-content-between">
				<p class="font-weight-bold sub-text mt-1">
					{{ subText }}
				</p>
				<div v-if="submissionId > 0" class="text-nowrap">
					<img
						v-b-modal="'user-story-modal'"
						id="user-story-action-icon"
						@click="handleDelete(submissionId)"
						class="delete-icon"
						:src="deleteIcon"
					/>

					<b-tooltip
						title="Delete"
						class="cursor-pointer"
						:target="'user-story-action-icon'"
					/>
				</div>
				<slot name="showLikes" />
			</div>
		</div>

		<div :class="imageContainerClasses">
			<slot v-if="showClickableImage" name="clickableImage" />
			<!-- <b-img v-else :src="thumbnailImage" fluid /> -->
		</div>

		<div class="d-flex justify-content-between align-items-center">
			<slot name="showViewersAvatar" />
			<slot name="showActionButtons" />
		</div>
	</b-card>
</template>

<script>
import { BCard, BImg, BCardBody, BCardHeader, BCardFooter } from 'bootstrap-vue'

import DeleteIcon from '../assets/images/icons/delete.svg'

import UserInfo from './UserInfo.vue'
import Button from './Button.vue'
import GroupedAvatar from './GroupedAvatar.vue'

export default {
	name: 'SubmissionView',
	components: {
		BCard,
		BImg,
		UserInfo,
		Button,
		GroupedAvatar,
		BCardBody,
		BCardHeader,
		BCardFooter,
	},
	props: {
		submissionId: {
			type: Number,
			default: 0,
		},
		thumbnailImage: {
			type: String,
			default: require('@/assets/images/gallery/monthly-submissions/fish.png'),
		},
		userName: {
			type: String,
			default: 'Peter Parker',
		},
		userAvatar: {
			type: String,
			default: null,
		},
		subText: {
			type: String,
			default: 'Ontario Canada - 18 Dec, 2021',
		},
		showClickableImage: Boolean,
		imageContainerClasses: {
			type: String,
			default: 'shrink-height-img',
		},
		handleDelete: {
			type: Function,
			default: () => {},
		},
	},
	data() {
		return {
			deleteIcon: DeleteIcon,
		}
	},
}
</script>

<style scoped>
/* .view-card {
  width: 308px;
} */
::v-deep.thumbnail-image {
	width: 100% !important;
	height: 100% !important;
	object-fit: cover !important;
}
.sub-text {
	color: black;
}
::v-deep.card-container {
	width: 270px !important;
	height: 420px !important;
}
::v-deep.shrink-height-img {
	height: 250px !important;
}
::v-deep.full-height-img {
	height: 300px !important;
}
.delete-icon {
	width: 18px;
	height: 18px;
	cursor: pointer;
	margin-top: 18px;
}
::v-deep .view-header {
	/* max-width: 100%;
	overflow: auto;*/
}
</style>