<template>
  <div>
    <b-form-input
      :id="id"
      :placeholder="placeholder"
      :class="classes"
      :disabled="disable"
      :readonly="readOnly"
      v-model="getValue"
      :plaintext="plainText"
      :size="size"
      :type="type"
      :min="min"
      :state="errors && errors.length > 0 ? false : null"
    />
  </div>
</template>
    

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BIconTools,
  BInputGroup,
  BFormInput,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormTextarea,
} from "bootstrap-vue";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
export default {
  name: "Input",
  components: {
    BRow,
    BCol,
    BIconTools,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormGroup,
    BFormTextarea,
  },
  props: {
    errors: {
      type: Array,
      default: null,
    },
    label: String,
    id: String,
    classes: String,
    disable: String,
    placeholder: String,
    readOnly: String,
    plainText: String,
    size: String,
    type: String,
    feedbackText: String,
    value: String,
    min:String
  },
  mixins: [togglePasswordVisibility],

  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    getValue: {
      get() {
        //this function will determine what is displayed in the input
        return this.value;
      },
      set(val) {
        //this function will run whenever the input changes and set thr value to field
        this.$emit("input", val);
      },
    },
  },
};
</script>

<style scoped>
.input-field {
  font-weight: bold !important;
}
</style>
 