<template>
	<div>
		<b-carousel
			ref="userStoriesSlider"
			v-if="!renderVideo"
			style="text-shadow: 0px 0px 2px #000; height: 250px"
			fade
			:indicators="mediaObj.Media.length > 1"
			:controls="mediaObj.Media.length > 1"
			interval="10000000"
			@sliding-start="onSlideStart"
			@sliding-end="onSlideEnd"
			v-model="currentSlide"
		>
			<b-carousel-slide
				v-for="item in mediaObj.Media"
				:key="item.Id"
				class="slide-wrapper"
			>
				<img
					slot="img"
					class="d-block img-fluid w-100"
					:src="getMediaPath(item)"
					alt="image slot"
					v-on:click="isVideo(item)"
				/>

				<div
					class="play-btn-wrapper"
					v-if="item.Type == MediaServerTypes.Video"
				>
					<b-img
						v-on:click="isVideo(item)"
						class="play-btn"
						:src="videPlayIcon"
						fluid
						alt="Responsive image"
						rounded
						blank-color="#777"
					/>
				</div>
			</b-carousel-slide>
		</b-carousel>
		<div v-if="renderVideo && !isPreview" class="video-container">
			<VideoPlayer
				:videoSrc="renderVideoUrl"
				:videoEndHandler="handleVideoEnd"
			/>
		</div>
	</div>
</template>

<script>
import { BCarousel, BCarouselSlide, BImg } from 'bootstrap-vue'

import { getMediaPath } from '@/utils/commonUtils'
import videPlayIcon from '@/assets/images/icons/play-icon.png'
import { MediaServerTypes } from '@/constants'
import VideoPlayer from './VideoPlayer.vue'

export default {
	name: 'StorySlider',
	components: {
		BCarousel,
		BCarouselSlide,
		BImg,
		VideoPlayer,
	},
	props: {
		mediaObj: {
			default: {},
			type: Object,
		},
		isPreview: {
			default: false,
			type: Boolean,
		},
	},
	data() {
		return {
			MediaServerTypes,
			videPlayIcon,
			getMediaPath,
			slideIndex: 0,
			sliding: null,
			renderVideo: false,
			renderVideoUrl: '',
			currentSlide: 0,
		}
	},
	methods: {
		isVideo(item) {
			if (!this.isPreview && item.Type == MediaServerTypes.Video) {
				this.renderVideo = true
				this.renderVideoUrl = this.getVideoPath(item)
			} else {
				this.renderVideo = false
				this.renderVideoUrl = ''
			}
		},

		getVideoPath(item) {
			if (!item) return
			return `${item.BasePath}${item.Path}`
		},
		onSlideStart(slide) {
			this.slideIndex = slide
			this.sliding = true
		},
		onSlideEnd(slide) {
			this.slideIndex = slide
			this.sliding = false
		},

		handleVideoEnd(evt) {
			this.renderVideo = false
			this.renderVideoUrl = ''
			this.currentSlide = this.slideIndex
		},
	},
}
</script>

<style scoped>
::v-deep .carousel-item img {
	height: 100% !important;
	width: 100% !important;
	object-fit: cover !important;
}
::v-deep .slide-wrapper {
	height: 250px;
}

.play-btn-wrapper {
	width: 50px;
	width: 50px;
	left: -15%;
	right: -15%;
	position: relative;
}

#preview-image___BV_modal_content_ .slide-wrapper {
	height: 66vh !important;
}

#preview-image___BV_modal_content_ .slide {
	height: 66vh !important;
}

::v-deep .carousel-caption {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100% !important;
	height: 100% !important;

	padding: 0px !important;
}
</style>