import { injectBaseConstantMethods } from "./BaseConstants";

const FishGearStatus = {
  ACTIVE: 1,
  SOLD: 2,
};

const displayTextKeys = {
  [FishGearStatus.ACTIVE]: "Active",
  [FishGearStatus.SOLD]: "Sold",
};

const labelClass = {
  [FishGearStatus.ACTIVE]: "light-success",
  [FishGearStatus.SOLD]: "light-warning",
};

export default injectBaseConstantMethods(
  FishGearStatus,
  displayTextKeys,
  labelClass
);
