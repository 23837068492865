<template>
  <div class="file-container" :class="classes" @click="onClick">
    <div class="file">
      <img :src="ifDocument(fileIcon) ? image : fileIcon" class="file-img" />
    </div>

    <div class="info">
      <p class="title multiline-truncate">{{ title }}</p>
      <p class="size">{{ (text / 1000).toFixed(1) }} kb</p>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BTableLite,
  BCardText,
  BButton,
  BAlert,
  BLink,
} from "bootstrap-vue";

import { ifDocument } from "../utils/commonUtils";
const image = require("../assets/images/icons/file-icons/file.png");

export default {
  name: "FileItem",
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
  },
  props: {
    title: {
      type: String,
      default: "-",
    },
    text: {
      type: String,
      default: "-",
    },
    fileIcon: {
      type: String,
      default: image,
    },
    onClick: {
      type: Function,
    },
    fileType: {
      type: String,
    },

    classes: String,
  },
  data() {
    return {
      image,
      ifDocument,
    };
  },
};
</script>

<style scoped>
.file-container {
  background-color: #ebe9f1;
  border: 1px solid #ebe9f1;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  cursor: pointer;
}

.file {
  /* height: 116px; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.file-img {
  margin-top: 38px;
  margin-bottom: 38px;
  width: 100%;
  height: 100px;
  align-self: center;
}

.info {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #ffffff;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}

.title {
  color: #000000;
  font-size: 14px;
  font-weight: bold;
  width: 135px;
  margin-bottom: 0px;
  margin-left: 12px;
  margin-top: 7px;
}

.size {
  margin-bottom: 0px;
  margin-top: 5px;
  color: #888888;
  font-size: 12px;
  font-weight: 500;
  margin-left: 12px;
  margin-bottom: 8px;
}

.multiline-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
