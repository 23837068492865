<template>
	<vue-perfect-scrollbar
		:settings="perfectScrollbarSettings"
		class="ps-customizer-area scroll-area cust-scrollbar"
	>
		<b-list-group class="list-container">
			<b-list-group-item
				class="pl-0 pr-0"
				v-for="item in usersData"
				:key="item.Id"
				><UserInfo
					:avatarImage="getMediaPath(item.Media)"
					:fullName="item.FullName"
			/></b-list-group-item>
		</b-list-group>
	</vue-perfect-scrollbar>
</template>

<script>
import { BListGroup, BListGroupItem, BSpinner } from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

import UserInfo from '../../components/UserInfo.vue'
import { getMediaPath } from '@/utils/commonUtils'
import { dateTimeFormat, convertDateTime } from '@/utils/dateFormatUtils'

export default {
	name: 'TournamentParticipantsModal',
	components: {
		BListGroup,
		BListGroupItem,
		BSpinner,
		UserInfo,
		VuePerfectScrollbar,
	},

	data() {
		return {
			getMediaPath,
			dateTimeFormat,
			convertDateTime,
		}
	},

	props: {
		viewedUsers: {
			type: Array,
			default: [
				{
					id: '0',
					userName: 'Jonh Mike',
					userAvatar: require('@/assets/images/avatars/2.png'),
					userTimeStamp: '19 Dec, 2021',
				},
				{
					id: '0',
					userName: 'Gregory Moaldonado',
					userAvatar: require('@/assets/images/avatars/2.png'),
					userTimeStamp: '5 min ago',
				},
				{
					id: '0',
					userName: 'Leonard Cohen',
					userAvatar: require('@/assets/images/portrait/small/avatar-s-5.jpg'),
					userTimeStamp: '19 Dec, 2021',
				},
				{
					id: '0',
					userName: 'Matilda Robertson',
					userAvatar: require('@/assets/images/portrait/small/avatar-s-7.jpg'),
					userTimeStamp: '19 Dec, 2021',
				},
				{
					id: '0',
					userName: 'Martha Barnes',
					userAvatar: require('@/assets/images/portrait/small/avatar-s-10.jpg'),
					userTimeStamp: '19 Dec, 2021',
				},
				{
					id: '0',
					userName: 'Monaco',
					userAvatar: require('@/assets/images/portrait/small/avatar-s-8.jpg'),
					userTimeStamp: '19 Dec, 2021',
				},
				{
					id: '0',
					userName: 'Minnie Hoffman',
					userAvatar: require('@/assets/images/portrait/small/avatar-s-20.jpg'),
					userTimeStamp: '19 Dec, 2021',
				},
				{
					id: '0',
					userName: 'Lawrence Walton',
					userAvatar: require('@/assets/images/avatars/2.png'),
					userTimeStamp: '19 Dec, 2021',
				},
				{
					id: '0',
					userName: 'Monaco',
					userAvatar: require('@/assets/images/avatars/3.png'),
					userTimeStamp: '19 Dec, 2021',
				},
			],
		},
		usersData: {
			type: Array,
			default: [],
		},
	},
}
</script>

<style scoped>
.list-container > * {
	border: 0 !important;
}
.ps-customizer-area {
	width: 100% !important;
	height: 537px !important;
}
.spinner {
	width: 3rem;
	height: 3rem;
}
.spinner-container {
	display: flex;
	justify-content: center;
	align-items: center;
}
</style>