import { injectBaseConstantMethods } from "./BaseConstants";

export const VoucherType = {
  Self: 1,
  Gift: 10,
};
const displayTextKeys = {
  [VoucherType.Self]: "Self",
  [VoucherType.Gift]: "Gift",
};

const labelClass = {
  [VoucherType.Self]: "",
  [VoucherType.Gift]: "",
};

export default injectBaseConstantMethods(
  VoucherType,
  displayTextKeys,
  labelClass,
);
