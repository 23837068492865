import { ServerCodes } from '../constants'
import store from '../store'
import { SIGN_OUT } from '../store/auth'

export const handleFetchError = async (res) => {
  if (
    res.status >= ServerCodes.BAD_REQUEST &&
    res.status < ServerCodes.CONNECTION_TIMED_OUT
  ) {
    const response = await res.clone().json()
    let errRes = {
      ...response,
      Message: response.Message,
      Status: res.status,
    }
    if (res.status === ServerCodes.UNAUTHORIZED) {
      store.commit(SIGN_OUT)
      localStorage.clear()
    }
    // TODO: show an erorr toaster here
    // toaster({ title: response.Message, type: "danger" });
    throw errRes
  }
  return res.json()
}
