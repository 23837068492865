import { getSelectedSubmissionsCount } from '../../api/api.service'
import moment from 'moment'

export default {
    namespaced: true,
    state: {
        fetching: false,
        count: 0,
        activeStoryCount: 0,
    },
    getters: {
        GET_COUNT: (state) => {
            return state.count
        },
    },
    mutations: {
        countRequest: (state) => {
            state.fetching = true
            state.count = 0
        },
        countSuccess: (state, TotalStories) => {
            state.fetching = false
            state.count = TotalStories
        },
        countFail: (state) => {
            state.fetching = false
            state.count = 0
        },
        activeStoryCountSuccess: (state, payload) => {
            state.activeStoryCount = payload.totalCount
        },
    },

    actions: {
        async getCountsAction({ commit }, params = null) {
            commit('countRequest')
            try {
                // moment().add(1, "months").format("YYYY-MM")
                let month = !params ? moment().format('YYYY-MM') : params

                let { TotalStories = 0 } = await getSelectedSubmissionsCount({
                    Date: month,
                    Status: 10,
                })

                commit('countSuccess', TotalStories)
            } catch (err) {
                commit('countFail')
            }
        },
    },
}
