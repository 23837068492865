var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('b-container',{staticClass:"bv-example-row"},[_c('Title',{attrs:{"text":"Trip Details"}}),_c('b-row',{staticClass:"mt-2"},[_c('b-col',{attrs:{"md":"6","lg":"6","sm":"12"}},[_c('LabelText',{attrs:{"label":"Customer","text":_vm.orderDetails.User.FullName || '-',"showRightSection":"","textClasses":"font-weight-bolder"}},[_c('b-avatar',{attrs:{"size":"md","src":_vm.getMediaPath(_vm.orderDetails.User.Media),"text":!_vm.getMediaPath(_vm.orderDetails.User.Media) &&
								_vm.orderDetails.User.FullName &&
								_vm.orderDetails.User.FullName[0]}})],1),_c('LabelText',{attrs:{"label":"Location","text":_vm.orderDetails.TripBooking.GuideTrip.FishSpot
								.Address || '-',"textClasses":"font-weight-bolder"}}),_c('LabelText',{attrs:{"label":"Trip Name","text":_vm.orderDetails.TripBooking.GuideTrip.Title || '-',"textClasses":"font-weight-bolder"}}),_c('LabelText',{attrs:{"label":"Booking ID","text":_vm.orderDetails.TripBooking.OrderNumber || '-',"textClasses":"font-weight-bolder"}}),_c('LabelText',{attrs:{"label":"Captain Name","text":_vm.orderDetails.TripBooking.GuideTrip.CaptainName ||
							'-',"textClasses":"font-weight-bolder"}}),_c('LabelText',{attrs:{"label":"Captain Phone","text":((_vm.orderDetails.TripBooking.GuideTrip
								.CaptainContactCountryCode || '') + " " + (_vm.orderDetails.TripBooking.GuideTrip
								.CaptainContactNumber || '-')),"textClasses":"font-weight-bolder"}}),_c('LabelText',{attrs:{"label":"Booking Date","text":_vm.orderDetails.TripBooking.BookingDate || '-',"textClasses":"font-weight-bolder"}}),_c('LabelText',{attrs:{"label":"Trip Time","text":("" + (_vm.getTripDuration(
							_vm.orderDetails.TripBooking.GuideTrip.TripDuration,
							_vm.orderDetails.TripBooking.GuideTrip.DepartureTime
						))),"textClasses":"font-weight-bolder"}}),_c('LabelText',{attrs:{"label":"Trip Type","text":("" + (_vm.GuideTripType.getDisplayTextKey(
							_vm.orderDetails.TripBooking.GuideTrip.Type
						))),"textClasses":"font-weight-bolder"}}),_c('LabelText',{attrs:{"label":"No of Persons Booked","text":!_vm.orderDetails.TripBooking.NumberOfAdult &&
							!_vm.orderDetails.TripBooking.NumberOfChildren
								? ((_vm.orderDetails.TripBooking
											.NumberOfPerson || 1) + " Persons")
								: ((_vm.orderDetails.TripBooking.NumberOfAdult) + " Adults + " + (_vm.orderDetails.TripBooking.NumberOfChildren) + " Children"),"textClasses":"font-weight-bolder"}})],1),_c('b-col',{attrs:{"md":"6","lg":"4","sm":"12"}},[_c('div',{staticClass:"d-flex flex-row align-items-center"},[_c('p',{staticClass:"mr-2"},[_vm._v("Seller :")]),_c('user-info',{attrs:{"subText":_vm.orderDetails.TripBooking.GuideTrip.Guide.User
									.PhoneNumber || '-',"fullName":_vm.orderDetails.TripBooking.GuideTrip.Guide.User
									.FullName || '-',"avatarImage":_vm.getMediaPath(
									_vm.orderDetails.TripBooking.GuideTrip.Guide
										.User.Media
								) || null,"iconSize":"md","containerClasses":"mb-1"}})],1),_c('div',{staticClass:"d-flex flex-row"},[_c('LabelText',{attrs:{"label":"Price","text":(" $ " + (_vm.orderDetails.TotalAmount || '0') + " + $" + (_vm.orderDetails.FishFinFee || '0') + " (transaction fee) + " + (_vm.orderDetails.GatewayFee) + " (payment processing fee)"),"textClasses":"font-weight-bolder"}})],1),(
							_vm.orderDetails.TripBooking.Meta &&
							_vm.orderDetails.TripBooking.Meta.PaymentType ===
								_vm.TripPaymentType.DEPOSIT
						)?_c('LabelText',{attrs:{"label":"Down Payment","text":("$" + (_vm.orderDetails.TripBooking.DownPayment)),"textClasses":"font-weight-bolder"}}):_vm._e(),(
							_vm.orderDetails.TripBooking.Meta &&
							_vm.orderDetails.TripBooking.Meta.PaymentType ===
								_vm.TripPaymentType.DEPOSIT
						)?_c('LabelText',{attrs:{"label":"Remaining Payment","text":("$" + (_vm.orderDetails.TripBooking.RemainingAmount)),"textClasses":"font-weight-bolder"}}):_vm._e(),_c('LabelText',{attrs:{"label":"Meeting Point","text":("" + (_vm.orderDetails.TripBooking.GuideTrip
								.MeetingPointAddress || '-')),"textClasses":"font-weight-bolder"}}),_c('p',[_vm._v("Additional Notes:")]),_c('p',{staticClass:"additional-notes"},[_vm._v(" "+_vm._s(_vm.orderDetails.TripBooking.AdditionalNotes || '-')+" ")])],1)],1),_c('hr'),(!_vm.orderDetails.TripBooking)?_c('Title',{attrs:{"text":"No Details Found","classes":"mt-3"}}):_vm._e(),_c('b-row',[_c('b-col',{attrs:{"lg":"6","md":"6","sm":"12"}},[_c('Title',{attrs:{"text":"Boat Details","classes":"mt-3"}}),_c('b-row',{staticClass:"mt-3"},[_c('b-col',{attrs:{"md":"12","lg":"12","sm":"12"}},[_c('LabelText',{attrs:{"label":"List Type","text":_vm.orderDetails.TripBooking.GuideTrip
										.ListingType.Name || '-',"textClasses":"font-weight-bolder"}}),_c('LabelText',{attrs:{"label":"Boat Type","text":_vm.orderDetails.TripBooking.GuideTrip
										.BoatType || '-',"textClasses":"font-weight-bolder"}}),_c('LabelText',{attrs:{"label":"Manufacturer","text":_vm.orderDetails.TripBooking.GuideTrip
										.BoatManufacturer || '-',"textClasses":"font-weight-bolder"}}),_c('LabelText',{attrs:{"label":"Boat Length","text":_vm.orderDetails.TripBooking.GuideTrip
										.BoatLength +
										' ' +
										_vm.orderDetails.TripBooking.GuideTrip
											.BoatLengthUnit || '-',"textClasses":"font-weight-bolder"}}),_c('LabelText',{attrs:{"label":"Build Year","text":_vm.orderDetails.TripBooking.GuideTrip
										.BuildYear || '-',"textClasses":"font-weight-bolder"}}),_c('LabelText',{attrs:{"label":"Boat Speed","text":_vm.orderDetails.TripBooking.GuideTrip
										.BoatCruisingSpeed +
									' ' +
									_vm.orderDetails.TripBooking.GuideTrip
										.BoatCruisingSpeedUnit,"textClasses":"font-weight-bolder"}}),_c('LabelText',{attrs:{"label":"Boat Capacity","text":_vm.orderDetails.TripBooking.GuideTrip
										.MaxPersonLimit || '-',"textClasses":"font-weight-bolder"}})],1)],1)],1),_c('b-col',{attrs:{"lg":"6","md":"6","sm":"12"}},[_c('Title',{attrs:{"text":"Amenities Provided","classes":"mt-3"}}),_c('b-row',{staticClass:"mt-3"},[_c('b-col',{attrs:{"md":"12","lg":"12","sm":"12"}},[_c('LabelText',{attrs:{"label":"Feature/Facilities","text":_vm.ConvertArrDataToStr(
										_vm.orderDetails.TripBooking.GuideTrip
											.Facilities,
										',',
										'Name'
									) || '-',"textClasses":"font-weight-bolder"}}),_c('LabelText',{attrs:{"label":"Gears","text":_vm.ConvertArrDataToStr(
										_vm.orderDetails.TripBooking.GuideTrip
											.Gears,
										',',
										'Name'
									) || '-',"textClasses":"font-weight-bolder"}}),_c('LabelText',{attrs:{"label":"Policies","text":_vm.ConvertArrDataToStr(
										_vm.orderDetails.TripBooking.GuideTrip
											.Policies,
										',',
										'Name'
									) || '-',"textClasses":"font-weight-bolder"}}),_c('LabelText',{attrs:{"label":"Amenities","text":_vm.ConvertArrDataToStr(
										_vm.orderDetails.TripBooking.GuideTrip
											.Amenities,
										',',
										'Name'
									) || '-',"textClasses":"font-weight-bolder"}}),_c('p',[_vm._v("Trip Description:")]),_c('p',{staticClass:"additional-notes"},[_vm._v(" "+_vm._s(_vm.orderDetails.TripBooking.GuideTrip .Description || '-')+" ")])],1)],1)],1)],1),_c('hr'),_c('b-row',[_c('b-col',{attrs:{"lg":"6","md":"6","sm":"12"}},[_c('Title',{attrs:{"text":"Fishing Details","classes":"mt-3"}}),_c('b-row',{staticClass:"mt-3"},[_c('b-col',{attrs:{"md":"12","lg":"12","sm":"12"}},[(
									!_vm.orderDetails.TripBooking.GuideTrip
										.FishSpecies.length
								)?_c('p',[_vm._v(" No Images ")]):_vm._l((_vm.orderDetails.TripBooking
									.GuideTrip.FishSpecies),function(item,index){return _c('div',{key:index,staticClass:"d-flex flex-row align-items-center mb-1"},[_c('img',{staticClass:"fish-img",attrs:{"src":_vm.getMediaPath(item.Media)}}),_c('p',{staticClass:"fish-name"},[_vm._v(_vm._s(item.Name))])])})],2)],1)],1),_c('b-col',{attrs:{"lg":"6","md":"6","sm":"12"}},[_c('Title',{attrs:{"text":"Glimpse of Last Tour","classes":"mt-3"}}),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"md":"12","lg":"12","sm":"12"}},[_c('b-row',{staticClass:"mb-2"},[(
										!_vm.orderDetails.TripBooking.GuideTrip
											.Medias.length
									)?_c('p',[_vm._v(" No Past Trip Pictures ")]):_vm._l((_vm.orderDetails
										.TripBooking.GuideTrip.Medias),function(item,index){return _c('img',{key:index,staticClass:"mt-2 gallery-img ml-1",attrs:{"src":_vm.getMediaPath(item)}})})],2)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }