import { ref, watch, onMounted } from '@vue/composition-api'

import config from '../config'

export const useLocalPagination = (
    requestFn,
    getParams = () => ({}),
    deps = [],
    searchKey = 'Q',
    dataKey = 'Data',
    totalKey = 'TotalCount'
) => {
    const page = ref(1)
    const pageLimit = ref(config.LIMIT)
    const searchText = ref('')
    const sortOrder = ref(null)
    const sortColumn = ref(null)
    const isFetching = ref(false)
    const data = ref([])
    const total = ref(0)
    let requestAbortController = null

    watch(
        [searchText, page, pageLimit, sortOrder, sortColumn, ...deps],
        () => {
            request()
        },
        { deep: true }
    )

    onMounted(() => {
        request()
    })

    function request() {
        isFetching.value = true
        data.value = []
        if (requestAbortController && requestAbortController.abort) {
            requestAbortController.abort()
        }
        requestAbortController = new AbortController()

        requestFn(
            {
                Page: page.value,
                Limit: pageLimit.value,
                ...(searchText.value && { [searchKey]: searchText.value }),
                ...(Object.keys(getParams()).length && getParams()),
                ...(sortOrder.value &&
                    sortColumn.value && {
                        Column: sortColumn.value,
                        Direction: sortOrder.value,
                    }),
            },
            requestAbortController.signal
        )
            .then((res) => {
                data.value = res[dataKey]
                total.value = res[totalKey]
                isFetching.value = false
            })
            .catch((err) => {
                if (err.name !== 'AbortError') {
                    isFetching.value = false
                }
            })
    }

    const handleSearchChange = (val) => {
        searchText.value = val
        page.value = 1
    }

    const handlePageClick = (pageNo) => {
        page.value = pageNo
    }

    const handlePageLimit = (noOfRecords) => {
        page.value = 1
        pageLimit.value = noOfRecords
    }

    const handleSortChange = (column, order) => {
        page.value = 1
        if (sortOrder.value === order && sortColumn.value === column) {
            sortOrder.value = null
            sortColumn.value = null
            return
        }
        sortOrder.value = order
        sortColumn.value = column
    }

    return {
        isFetching,
        data,
        total,
        page,
        pageLimit,
        searchText,
        sortColumn,
        sortOrder,
        handleSearchChange,
        handlePageClick,
        handlePageLimit,
        handleSortChange,
        request,
    }
}
