import Vue from "vue";
import Vuex from "vuex";
import VuexPersistence from "vuex-persist";
import SecureLS from "secure-ls";

// Modules
import app from "./app";
import appConfig from "./app-config";
import verticalMenu from "./vertical-menu";
import auth, { initialState as authInitialState, SIGN_OUT } from "./auth";
import deviceId from "./deviceId";
import Config from "../config";
import fishingGuides from "./fishing-guides";
import ratingReviews from "./rating-reviews";
import orderListing from "./order-listing";
import fishingGear from "./fishing-gear";
import user from "./user-management";
import moderation from "./moderation";
import communityPost from "./community-post";
import monthlySubmission from "./monthly-submission";

Vue.use(Vuex);

const initialStates = {
  auth: authInitialState,
};

const ls = new SecureLS({
  encodingType: "aes",
  encryptionSecret: Config.PERSIST_SECRET_KEY,
});

const vuexLocal = new VuexPersistence({
  key: "fishfin",
  storage: {
    getItem: (key) => ls.get(key),
    setItem: (key, value) => ls.set(key, value),
    removeItem: (key) => ls.remove(key),
  },
  modules: ["auth", "deviceId"],
});

const store = new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    auth,
    guides: fishingGuides,
    ratingReviews,
    orderListing,
    fishingGear,
    deviceId,
    user,
    moderation,
    communityPost,
    monthlySubmission,
  },
  mutations: {
    [SIGN_OUT]: (state) => {
      Object.keys(initialStates).forEach((key) => {
        Object.assign(state[key], initialStates[key]);
      });
    },
  },
  strict: process.env.DEV,
  plugins: [vuexLocal.plugin],
});

const listener = (e) => {
  if (
    store.state.auth.isAuthenticated &&
    (!localStorage.hasOwnProperty("isAuthenticated") ||
      !localStorage.isAuthenticated)
  ) {
    store.commit(SIGN_OUT);
    localStorage.clear();
  }
};

window.addEventListener("storage", listener);

export const useStore = () => store;
export default store;
