<template>
	<div class="mx-2 mb-2">
		<b-row>
			<b-col
				cols="12"
				sm="6"
				class="
					d-flex
					align-items-center
					justify-content-center justify-content-sm-start
				"
			>
				<span v-if="showEntriesText" class="text-muted">
					<!-- Showing {{ currentPage }} {{ perPage }} of{{ totalRecords }} {{ entriesName }} -->
					Showing {{ noOfItems }} of {{ totalRecords }}
					{{ entriesName }}
				</span>
			</b-col>
			<!-- Pagination -->
			<b-col
				cols="12"
				sm="6"
				class="
					d-flex
					align-items-center
					justify-content-center justify-content-sm-end
				"
			>
				<b-pagination
					@change="onChange"
					:value="value"
					:total-rows="totalRecords"
					:per-page="perPage"
					first-number
					last-number
					class="mb-0 mt-1 mt-sm-0"
					prev-class="prev-item"
					next-class="next-item"
				>
					<template #prev-text>
						<feather-icon icon="ChevronLeftIcon" size="18" />
					</template>
					<template #next-text>
						<feather-icon icon="ChevronRightIcon" size="18" />
					</template>
				</b-pagination>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import { BPagination, BCol, BRow } from 'bootstrap-vue'
export default {
	name: 'Pagination',
	components: {
		BPagination,
		BCol,
		BRow,
	},

	props: {
		entriesName: {
			type: String,
			default: 'entries',
		},
		showEntriesText: {
			type: Boolean,
			default: true,
		},

		perPage: {
			type: Number,
			default: 10,
		},
		totalRecords: {
			type: Number,
			default: 10,
		},
		onChange: Function,
		value: {
			default: 1,
		},
		noOfItems: {
			default: 0,
		},
	},
}
</script>
<style lang="scss" scoped></style>
