<template>
	<div>
		<b-card :class="width" class="w-100 card-container">
			<div class="inner-container">
				<b-card-title class="text-secondary font-weight-bolder">
					Guide Verification Requests
				</b-card-title>

				<div
					v-if="isFetching"
					class="
						w-100
						h-100
						d-flex
						justify-content-center
						align-items-center
						mt-4
					"
				>
					<b-spinner variant="primary" class="spinner" />
				</div>

				<div v-if="!isFetching && guidesList">
					<vue-perfect-scrollbar
						:settings="perfectScrollbarSettings"
						class="ps-customizer-area scroll-area cust-scrollbar"
					>
						<div
							v-if="guidesList.length < 1"
							class="d-flex justify-content-center"
						>
							<img
								:src="emptyImage"
								class="w-50 h-50 text-center"
							/>
						</div>
						<div
							v-else
							class="
								w-100
								demo-inline-spacing
								d-flex
								justify-content-between
								align-content-center
							"
							v-for="(item, index) in guidesList"
							:key="index"
						>
							<div class="w-25">
								<UserInfo
									:fullName="item.User.FullName"
									:subText="item.User.PhoneNumber"
								/>
							</div>
							<div class="d-flex justify-content-end p-0">
								<div class="d-flex">
									<Button
										buttonText="View"
										buttonVariant="outline"
										size="md"
										classes="btn-outline-grey"
										:clickHandler="
											() => viewDetails(item.Id)
										"
									/>
									<!-- <div class="ml-1">
                  <Button
                    buttonText="Accept"
                    buttonVariant="primary"
                    size="md"
                  />
                </div> -->
								</div>
							</div>
						</div>
					</vue-perfect-scrollbar>
				</div>
			</div>
		</b-card>
	</div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import {
	BCard,
	BCardBody,
	BAvatar,
	BCardSubTitle,
	BCardTitle,
	BSpinner,
} from 'bootstrap-vue'

import Button from '../../components/Button.vue'
import UserInfo from '../../components/UserInfo.vue'
import { getGuideVerfRequests } from '../../api/api.service'

export default {
	name: 'GuideVerificationRequest',
	components: {
		Button,
		UserInfo,
		BCard,
		BCardBody,
		BAvatar,
		BCardSubTitle,
		BCardTitle,
		BSpinner,
		// PerfectScrollbar,
		VuePerfectScrollbar,
	},
	data() {
		const perfectScrollbarSettings = {
			maxScrollbarLength: 60,
			wheelPropagation: false,
			wheelSpeed: 1,
		}
		return {
			emptyImage: require('@/assets/images/gallery/no-data.png'),
			perfectScrollbarSettings,
			isFetching: false,
			guidesList: [],
		}
	},
	beforeMount() {
		this.getGuideVerfRequestsHandler()
	},
	methods: {
		async getGuideVerfRequestsHandler() {
			try {
				this.isFetching = true
				let response = await getGuideVerfRequests()
				this.guidesList = response.GuideVerificationRequests
				this.isFetching = false
			} catch (error) {
				this.isFetching = false
			} finally {
				this.isFetching = false
			}
		},
		viewDetails(id) {
			this.$router.push('/fishing-guide/' + id)
		},
	},
}
</script>


<style scoped>
.text-color {
	color: #003071;
}

.ps-customizer-area {
	height: 200px !important;
}
.card-container {
	height: 300px !important;
	overflow: hidden !important;
}
.spinner {
	width: 4rem;
	height: 4rem;
}
</style>
