import { injectBaseConstantMethods } from "./BaseConstants";

export const submissionsStatus = {
  INACTIVE: 1,
  ACTIVE: 10,
  EXPIRED: 20,
  REJECTED: 21,
};
const displayTextKeys = {
  [submissionsStatus.INACTIVE]: "Inactive",
  [submissionsStatus.ACTIVE]: "Accepted",
  [submissionsStatus.EXPIRED]: "Expired ",
  [submissionsStatus.REJECTED]: "Rejected ",
};

const labelClass = {
  [submissionsStatus]: "",
  [submissionsStatus]: "",
};

export default injectBaseConstantMethods(
  submissionsStatus,
  displayTextKeys,
  labelClass,
);
