<template>
	<video-player
		class="video-player-box"
		ref="videoPlayer"
		:options="playerOptions"
		:playsinline="true"
		customEventName="customstatechangedeventname"
		@play="onPlayerPlay($event)"
		@pause="onPlayerPause($event)"
		@ended="videoEndHandler($event)"
		@waiting="onPlayerWaiting($event)"
		@playing="onPlayerPlaying($event)"
		@loadeddata="onPlayerLoadeddata($event)"
		@timeupdate="onPlayerTimeupdate($event)"
		@canplay="onPlayerCanplay($event)"
		@canplaythrough="onPlayerCanplaythrough($event)"
		@statechanged="playerStateChanged($event)"
		@ready="playerReadied"
	>
	</video-player>
</template>
 
<script>
import 'video.js/dist/video-js.css'

import { videoPlayer } from 'vue-video-player'

export default {
	name: 'VideoPlayer',
	components: {
		videoPlayer,
	},
	props: {
		videoSrc: {
			type: String,
			default: '',
		},
		videoThumbnail: {
			type: String,
			default: '',
		},
		videoEndHandler: {
			type: Function,
			default: () => {},
		},
	},
	data() {
		return {
			playerOptions: {
				muted: true,
				autoplay: true,
				controls: true,
				language: 'en',
				playbackRates: [0.7, 1.0, 1.5, 2.0],
				sources: [
					{
						type: 'video/mp4',
						src: this.videoSrc,
					},
				],
				poster: '/static/images/author.jpg',
			},
		}
	},
	mounted() {
		// console.log('this is current player instance object', this.player)
	},
	computed: {
		player() {
			return this.$refs.videoPlayer.player
		},
	},
	methods: {
		// listen event
		onPlayerPlay(player) {
			// console.log('player play!', player)
		},
		onPlayerPause(player) {
			// console.log('player pause!', player)
		},
		// ...player event

		// or listen state event
		playerStateChanged(playerCurrentState) {
			// console.log('player current update state', playerCurrentState)
		},

		// player is ready
		playerReadied(player) {
			// you can use it to do something...
		},
	},
}
</script>

<style lang="css" scoped>
::v-deep.video-player-box .video-js {
	width: 100% !important;
	height: 66vh !important;
}

::v-deep button.vjs-big-play-button {
	display: none !important;
}
</style>