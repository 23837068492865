<template>
  <div class="container">
    <star-rating
      v-model="rateInNumber"
      :read-only="true"
      :star-size="18"
      :padding="5"
      text-class="text"
      :inline="true"
    ></star-rating>
  </div>
</template>

<script>
import StarRating from "vue-star-rating";

export default {
  name: "Ratings",
  components: {
    StarRating,
  },
  props: {
    rateInNumber: Number,
  },
};
</script>

<style>
.star-color {
  fill: #ffa200;
  color: #ffa200;
}
.container {
  padding-bottom: 6px !important;
}

.text {
  font-weight: bold;
  font-size: 16px;
  color: black;
  margin-top: 3px;
}
.vue-star-rating-rating-text {
  margin-left: 7px;
  margin-top: 4px !important;
}
</style>
