import {
  getAGuideTripsList,
  getGuideList,
  deleteEntireTrip,
} from "../../api/api.service";

export default {
  namespaced: true,
  // State
  state: {
    fetching: false,
    list: [],
    totalGuides: 0,
    trips: {
      fetching: false,
      list: [],
      totalTrips: 0,
      message: "",
    },
  },
  // Getter: To read values from state
  getters: {
    getTripById: (state) => (id) => {
      return state.trips.list.find((trip) => trip.Id === id);
    },
  },
  // Mutations: To assign changes to the state
  mutations: {
    // FETCH GUIDES
    guideRequest: (state) => {
      state.fetching = true;
      state.list = [];
    },
    guideSuccess: (state, action) => {
      state.fetching = false;
      state.list = action.payload;
      state.totalGuides = action.totalGuides;
    },
    guideFail: (state, payload) => {
      state.fetching = false;
    },
    // UPDATE GUIDE
    updateGuide: (state, payload) => {
      let { Status, Id } = payload;
      state.list.map((guide) => {
        if (guide.Id == Id) guide["Status"] = Status;
      });
    },
    // FETCH TRIPS
    tripRequest: (state) => {
      state.trips.fetching = true;
      state.trips.list = [];
    },
    tripSuccess: (state, action) => {
      state.trips.fetching = false;
      state.trips.list = action.payload;
      state.trips.totalTrips = action.TotalGuideTrips;
    },
    tripFail: (state, payload) => {
      state.trips.fetching = false;
    },
    // DELETE TRIPS
    deleteTripSuccess: (state, action) => {
      let { responseMessage, deletedTrip } = action;
      state.trips.list = state.trips.list.filter(
        (item) => item.Id !== deletedTrip,
      );
      state.trips.totalTrips = state.trips.totalTrips - 1;
    },

    deleteTripFail: (state, action) => {
      console.log("deleteTripFail", action.payload);
    },
  },
  // Actions: To commit changes to state using mutation
  actions: {
    // For fetching all guides
    async getGuideListAction({ commit }, params = {}) {
      const { signal, ...queryParams } = params;
      commit("guideRequest");
      try {
        let guideResponse = await getGuideList(queryParams, signal);

        commit("guideSuccess", {
          payload: guideResponse.Guides,
          totalGuides: guideResponse.TotalGuides,
        });
      } catch (err) {
        if (err.name !== "AbortError") {
          commit("guideFail");
        }
      }
    },
    // Update guide status
    updateGuideStatus({ commit }, guideData) {
      commit("updateGuide", guideData);
    },
    // For fetching trips related to selected guide
    async getAGuideTripListAction({ commit }, params = {}) {
      const { signal, ...queryParams } = params;
      commit("tripRequest");
      try {
        let tripResponse = await getAGuideTripsList(queryParams, signal);

        commit("tripSuccess", {
          payload: tripResponse.GuideTrips,
          totalTrips: tripResponse.TotalGuideTrips,
        });
      } catch (err) {
        if (err.name !== "AbortError") {
          commit("tripFail");
        }
      }
    },
    // For delte entire trip

    async deleteEntireTrip({ commit }, id) {
      try {
        let deleteTripResponse = await deleteEntireTrip(id);

        commit("deleteTripSuccess", {
          responseMessage: deleteTripResponse.Message,
          deletedTrip: id,
        });
        return Promise.resolve(deleteTripResponse);
      } catch (err) {
        commit("deleteTripFail", {
          payload: err.message,
        });
        return Promise.reject(err);
      }
    },
  },
};
