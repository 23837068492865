<template>
	<b-row>
		<b-col lg="12" md="12" sm="12">
			<b-card>
				<b-row class="mb-3">
					<b-col lg="6">
						<b-row lg="4">
							<b-col lg="4" class="align-self-center pt-1 pl-3">
								<Avatar
									:src="getMediaPath(data.User.Media)"
									size="100"
								/>
							</b-col>

							<b-col lg="8" class="pt-2">
								<LabelText
									label="Company Name"
									:text="data.BusinessName || '-'"
									labelClasses="wd-130"
									textClasses="text"
									containerClasses="mb-8"
								/>
								<LabelText
									label="Phone Number"
									:text="data.BusinessNumber || '-'"
									labelClasses="wd-130"
									textClasses="text"
									containerClasses="mb-8"
								/>
								<LabelText
									label="Email Address"
									:text="data.User.Email || '-'"
									labelClasses="wd-130"
									textClasses="text"
									containerClasses="mb-8"
								/>
							</b-col>
						</b-row>
					</b-col>

					<b-col lg="3" sm="auto" class="pt-2">
						<LabelText
							label="Full Name"
							:text="data.User.FullName || '-'"
							labelClasses=" mr-1"
							containerClasses="mb-8"
						/>
						<LabelText
							label="Address "
							:text="data.Address || '-'"
							labelClasses="mr-1"
							containerClasses="mb-8"
						/>

						<LabelText
							label="Verification"
							:text="getVerifyGuideText(data)"
							labelClasses="mr-1"
						/>
					</b-col>

					<b-col lg="3" sm="auto" class="earning-wrapper">
						<LabelText
							label="Weekly Earnings"
							:text="`$ ${
								validateValue(guideEarning.WeeklyEarnings) ||
								'-'
							}`"
							containerClasses="mb-0"
							textClasses="font-bold"
						/>
						<LabelText
							label="Monthly Earnings"
							:text="`$ ${
								validateValue(guideEarning.MonthlyEarnings) ||
								'-'
							}`"
							containerClasses="mb-0"
							textClasses="font-bold"
						/>
						<LabelText
							label="All Time Earnings"
							:text="`$ ${
								validateValue(guideEarning.TotalEarnings) || '-'
							}`"
							containerClasses="mb-0"
							textClasses="font-bold"
						/>
					</b-col>
				</b-row>
				<hr />
				<b-col lg="12" md="12" sm="12" class="mt-3"
					><Title text="Guide Documents" classes="mb-0" />
				</b-col>

				<b-row>
					<b-col lg="9" md="6" sm="6">
						<div class="flex-wrap d-flex flex-row">
							<FileItem
								v-for="(item, index) in data.Media"
								:classes="'mt-1 ml-1'"
								:key="index"
								:title="item.Meta.Name"
								:text="item.Meta.Size"
								:fileIcon="getMediaPath(item)"
								:onClick="
									() => previewMedia(getMediaPath(item))
								"
							/>
							<div
								v-if="data.Media.length == 0"
								class="ml-1 mt-2"
							>
								<p>No Documents</p>
							</div>
						</div>
					</b-col>

					<b-col lg="3" sm="auto" class="pt-4 btn-container">
						<div>
							<Button
								:clickHandler="
									() =>
										getGuideVerfValue(
											data.ApprovalStatus,
											data.Id
										)
								"
								size="md"
								:buttonVariant="`${
									data.ApprovalStatus ==
									ListingVerfCode.VERIFIED
										? 'danger'
										: 'success'
								}`"
								classes="wd-211"
								:buttonText="`${
									data.ApprovalStatus ==
									ListingVerfCode.VERIFIED
										? 'Unverify Guide'
										: 'Verify Guide'
								}`"
							/>

							<Button
								:clickHandler="
									() =>
										getLCAGuideVerfValue(
											data.LCAApprovalStatus,
											data.Id
										)
								"
								size="md"
								:buttonVariant="`${
									data.LCAApprovalStatus ==
									ListingVerfCode.VERIFIED
										? 'danger'
										: 'success'
								}`"
								classes="mt-1 wd-211"
								:buttonText="`${
									data.LCAApprovalStatus ==
									ListingVerfCode.VERIFIED
										? 'Unverify LCBA Guide'
										: 'Verify as LCBA Guide'
								}`"
							/>

							<Button
								:clickHandler="
									() =>
										getGuideStatusValue(
											data.Status,
											data.Id
										)
								"
								size="md"
								buttonVariant="outline-secondary"
								classes="mt-1 wd-211"
								:buttonText="`${
									data.Status == ListingStatusCode.ACTIVE
										? 'Suspend'
										: 'Active'
								}`"
							/>
						</div> </b-col
				></b-row>
			</b-card>
			<Title text="Available Trips" />
			<TripsListing />
		</b-col>
		<!-- Modal to confirm action -->
		<ConfirmModal
			:onYesPress="guideActionsHandler"
			:isLoading="isActionLoading"
			:isSuccess="isActionSuccess"
			:onOkayPress="handleOkayPress"
			:messageText="`Are you sure you want to ${modalMsgText} this guide?`"
			successText="Success !"
			id="guide-action-modal"
			:messageIcon="require('@/assets/images/icons/restrict-icon.svg')"
		/>

		<!-- Modal To Preview Images -->
		<BasicModal
			id="view-image"
			modalTitle="Image Preview"
			modalBodyClasses="modal-body-height"
		>
			<template #showImage>
				<img :src="imageToViewSrc" class="gallery-image" />
			</template>
		</BasicModal>
	</b-row>
</template>

<script>
import {
	BRow,
	BCol,
	BCard,
	BCardBody,
	BTableLite,
	BCardText,
	BButton,
	BAlert,
	BLink,
	BImg,
} from 'bootstrap-vue'

import Avatar from '../../../components/Avatar.vue'
import LabelText from '../../../components/LabelText.vue'
import Button from '../../../components/Button.vue'
import Title from '../../../components/Title.vue'
import FileItem from '../../../components/FileItem.vue'
import ConfirmModal from '../../../components/ConfirmModal.vue'
import TripsListing from './TripsListing.vue'
import BasicModal from '../../../components/BasicModal.vue'
import {
	getAGuideDetail,
	getGuideEarnings,
	changeGuideStatus,
} from '../../../api/api.service'
import {
	ListingStatusCode,
	ListingVerfCode,
	LCAApproval,
} from '../../../constants'
import { getRouteId } from '../../../utils/getRouteId'
import {
	getMediaPath,
	validateValue,
	ifDocument,
} from '../../../utils/commonUtils'
import showToast from '@/hooks/useToast'

export default {
	name: 'GuideDetails',
	components: {
		BRow,
		BCol,
		BCard,
		BCardBody,
		BTableLite,
		BCardText,
		BButton,
		BAlert,
		BLink,
		BImg,
		LabelText,
		Avatar,
		Button,
		Title,
		FileItem,
		TripsListing,
		ConfirmModal,
		BasicModal,
	},
	data() {
		return {
			data: {},
			guideEarning: {},

			guideStatusToSet: { Status: null, Id: null },
			guideVerfToSet: { VerfStatus: null, Id: null },
			guideLCAVerfToSet: { VerfStatus: null, Id: null },

			isActionLoading: false,
			isActionSuccess: false,
			isActionFailed: false,

			routeParamId: '',
			imageToViewSrc: '',
			modalMsgText: '',
			ListingStatusCode,
			ListingVerfCode,
			LCAApproval,
			getRouteId,
			getMediaPath,
			validateValue,
			ifDocument,
		}
	},
	methods: {
		getVerifyGuideText(guideObj = {}) {
			let { ApprovalStatus, LCAApprovalStatus } = guideObj

			if (
				ApprovalStatus == ListingVerfCode.VERIFIED &&
				LCAApprovalStatus == LCAApproval.VERIFIED
			) {
				return 'Verified by Fishfin and Louisiana Charters Board Association'
			} else if (ApprovalStatus == ListingVerfCode.VERIFIED) {
				return 'Verified by Fishfin'
			} else if (LCAApprovalStatus == LCAApproval.VERIFIED) {
				return 'Verified by Louisiana Charters Board Association'
			} else {
				return 'Unverified'
			}
		},
		async guideStatusChangeHandler() {
			try {
				this.isActionLoading = true
				let res = await changeGuideStatus(this.guideStatusToSet.Id, {
					Status: this.guideStatusToSet.Status,
				})
				if (res.Message === 'Success') {
					this.isGuideStatusChangedSuccess = true
					this.isStatusLoading = false
					this.$store.dispatch('guides/updateGuideStatus', {
						...this.guideStatusToSet,
					})

					this.data.Status = this.guideStatusToSet.Status
				}
				this.isActionLoading = false
				this.isActionSuccess = true
			} catch (err) {
				this.isActionLoading = false
				showToast(this, {
					Title: 'Error',
					Icon: 'XCircleIcon',
					Text: err.Message,
					Variant: 'danger',
					Position: 'top-right',
				})
			} finally {
				this.isActionLoading = false
			}
		},

		async guideLCAVerificationChangeHandler() {
			try {
				this.isActionLoading = true

				let res = await changeGuideStatus(this.guideLCAVerfToSet.Id, {
					LCAApprovalStatus: this.guideLCAVerfToSet.VerfStatus,
				})
				if (res.Message === 'Success') {
					this.isGuideVerfChangedSuccess = true
					this.isGuideVerfLoading = false
					this.$store.dispatch('guides/updateGuideStatus', {
						...this.guideLCAVerfToSet,
					})

					this.data.LCAApprovalStatus =
						this.guideLCAVerfToSet.VerfStatus
				}
				this.isActionLoading = false
				this.isActionSuccess = true
			} catch (err) {
				this.isActionLoading = false
				showToast(this, {
					Title: 'Error',
					Icon: 'XCircleIcon',
					Text: err.Message,
					Variant: 'danger',
					Position: 'top-right',
				})
			} finally {
				this.isActionLoading = false
			}
		},

		async guideVerificationChangeHandler() {
			try {
				this.isActionLoading = true

				let res = await changeGuideStatus(this.guideVerfToSet.Id, {
					ApprovalStatus: this.guideVerfToSet.VerfStatus,
				})
				if (res.Message === 'Success') {
					this.isGuideVerfChangedSuccess = true
					this.isGuideVerfLoading = false
					this.$store.dispatch('guides/updateGuideStatus', {
						...this.guideVerfToSet,
					})

					this.data.ApprovalStatus = this.guideVerfToSet.VerfStatus
				}
				this.isActionLoading = false
				this.isActionSuccess = true
			} catch (err) {
				this.isActionLoading = false
				showToast(this, {
					Title: 'Error',
					Icon: 'XCircleIcon',
					Text: err.Message,
					Variant: 'danger',
					Position: 'top-right',
				})
			} finally {
				this.isActionLoading = false
			}
		},

		getGuideStatusValue(statusValue, id) {
			this.$bvModal.show('guide-action-modal')
			this.guideStatusToSet.Id = id
			if (statusValue == ListingStatusCode.ACTIVE) {
				this.guideStatusToSet.Status = ListingStatusCode.SUSPENDED
				this.modalMsgText = 'Suspend'
			} else if ((statusValue = ListingStatusCode.SUSPENDED)) {
				this.guideStatusToSet.Status = ListingStatusCode.ACTIVE
				this.modalMsgText = 'Active'
			}
		},

		getGuideVerfValue(verValue, id) {
			this.$bvModal.show('guide-action-modal')
			this.guideVerfToSet.Id = id
			if (verValue == ListingVerfCode.VERIFIED) {
				this.guideVerfToSet.VerfStatus = ListingVerfCode.UNVERIFIED
				this.modalMsgText = 'Unverify'
			} else if (verValue == ListingVerfCode.UNVERIFIED) {
				this.guideVerfToSet.VerfStatus = ListingVerfCode.VERIFIED
				this.modalMsgText = 'Verify'
			}
		},

		getLCAGuideVerfValue(verValue, id) {
			this.$bvModal.show('guide-action-modal')
			this.guideLCAVerfToSet.Id = id
			if (verValue == ListingVerfCode.VERIFIED) {
				this.guideLCAVerfToSet.VerfStatus = ListingVerfCode.UNVERIFIED
				this.modalMsgText = 'mark as LCBA unverified'
			} else if (verValue == ListingVerfCode.UNVERIFIED) {
				this.guideLCAVerfToSet.VerfStatus = ListingVerfCode.VERIFIED
				this.modalMsgText = 'mark as LCBA verified'
			}
		},

		handleOkayPress() {
			this.isActionLoading = false
			this.isActionSuccess = false
			this.isActionFailed = false
			this.guideStatusToSet.Status = null
			this.guideVerfToSet.VerfStatus = null
			this.guideLCAVerfToSet.VerfStatus = null
		},

		guideActionsHandler() {
			if (this.guideStatusToSet.Status) {
				this.guideStatusChangeHandler()
			} else if (this.guideVerfToSet.VerfStatus) {
				this.guideVerificationChangeHandler()
			} else if (this.guideLCAVerfToSet.VerfStatus) {
				this.guideLCAVerificationChangeHandler()
			}
		},

		previewMedia(url) {
			if (ifDocument(url)) {
				window.open(url)
			} else if (!ifDocument(url)) {
				this.$bvModal.show('view-image')
				this.imageToViewSrc = url
			}
		},
	},
	created() {
		this.routeParamId = this.$route.params.guideId
		let parameters = {
			FilterType: '30',
			GuideId: this.routeParamId,
		}

		getAGuideDetail(this.routeParamId).then((res) => {
			let { Guide } = res
			this.data = { ...Guide }
		})

		getGuideEarnings(parameters)
			.then((res) => {
				if (res) {
					this.guideEarning = { ...res }
				}
			})
			.catch((err) => {
				showToast(this, {
					Title: 'Error',
					Icon: 'XCircleIcon',
					Text: err.Message,
					Variant: 'danger',
					Position: 'top-right',
				})
			})
	},
}
</script>

<style lang="scss" scoped>
::v-deep.gallery-image {
	width: 100% !important;
	height: 100% !important;
}

.align-wrapper {
	display: flex;
	align-items: center;
	margin-bottom: 11px;
}

.label {
	size: 14px;
	color: #888888;
}
::v-deep .text {
	font-weight: 500 !important;
}

::v-deep .font-bold {
	font-weight: 800 !important;
}

.earning-wrapper {
	display: flex;
	flex-direction: column;
	padding-top: 10px;
}

.btn-container {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: flex-end;
}

@media only screen and (max-width: 600px) {
	.earning-wrapper {
		align-items: flex-start;
	}

	.btn-container {
		align-items: flex-start;
		padding-left: 2rem;
	}
}

::v-deep .wd-211 {
	width: 211px;
}

::v-deep .wd-90 {
	width: 100px;
}

::v-deep .wd-130 {
	width: 130px;
}
</style>
