import { injectBaseConstantMethods } from "./BaseConstants";

export const ReportStatus = {
  ToBeViewed: 1,
  ListingRemoved: 2,
  ReportRejected: 3,
};
const displayTextKeys = {
  [ReportStatus.ToBeViewed]: "To Be Viewed",
  [ReportStatus.ListingRemoved]: "Listing Removed",
  [ReportStatus.ReportRejected]: "Report Rejected",
};

const labelClass = {
  [ReportStatus.ToBeViewed]: "light-warning",
  [ReportStatus.ListingRemoved]: "light-secondary",
  [ReportStatus.ReportRejected]: "light-info",
};

export default injectBaseConstantMethods(
  ReportStatus,
  displayTextKeys,
  labelClass,
);
