import { getOrdersLisiting } from "../../api/api.service";

export default {
  namespaced: true,
  // State
  state: {
    fetching: false,
    ordersList: [],
    totalOrders: 0,
  },
  // Getter: To read values from state
  getters: {
    getOrderDetailsById: (state) => (id) => {
      return state.ordersList.find((order) => order.Id === id);
    },
  },
  // Mutations: To assign changes to the state
  mutations: {
    orderListRequest: (state) => {
      state.fetching = true;
      state.ordersList = [];
    },
    orderListSuccess: (state, action) => {
      state.fetching = false;
      state.ordersList = action.payload;
      state.totalOrders = action.totalOrders;
    },
    orderListFail: (state, payload) => {
      state.fetching = false;
    },
  },
  // Actions: To commit changes to state using mutation
  actions: {
    async getOrdersListAction({ commit }, params = {}) {
      const { signal, ...queryParams } = params;
      commit("orderListRequest");
      try {
        let orderResponse = await getOrdersLisiting(queryParams, signal);

        commit("orderListSuccess", {
          payload: orderResponse.Transactions,
          totalOrders: orderResponse.TotalTransactions,
        });
      } catch (err) {
        if (err.name !== "AbortError") {
          commit("orderListFail");
        }
      }
    },
  },
};
