import { injectBaseConstantMethods } from "./BaseConstants";

const FishingGuideMetaType = {
  LISTING_TYPE: 1,
  FACILITIES: 2,
  GEARS: 3,
  POLICIES: 4,
  AMENITIES: 5,
  FISHING_TECHNIQUES: 6,
};

const displayTextKeys = {
  [FishingGuideMetaType.LISTING_TYPE]: "Listing Type",
  [FishingGuideMetaType.FACILITIES]: "Facility",
  [FishingGuideMetaType.GEARS]: "Gear",
  [FishingGuideMetaType.POLICIES]: "Policy",
  [FishingGuideMetaType.AMENITIES]: "Amenity",
  [FishingGuideMetaType.FISHING_TECHNIQUES]: "Fishing Technique",
};

const labelClass = {
  [FishingGuideMetaType.LISTING_TYPE]: "",
  [FishingGuideMetaType.FACILITIES]: "",
  [FishingGuideMetaType.GEARS]: "",
  [FishingGuideMetaType.POLICIES]: "",
  [FishingGuideMetaType.AMENITIES]: "",
  [FishingGuideMetaType.FISHING_TECHNIQUES]: "",
};

export default injectBaseConstantMethods(
  FishingGuideMetaType,
  displayTextKeys,
  labelClass,
);
