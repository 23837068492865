const LEFT_CURLY_BRACES_DOUBLE = '{{'
const RIGHT_CURLY_BRACES_DOUBLE = '}}'
const LEFT_CURLY_BRACES_SINGLE = '{'
const RIGHT_CURLY_BRACES_SINGLE = '}'

export const isPlaceholderValidate = (htmlStr = '') => {
  if (!htmlStr) return

  let templateStr = htmlStr

  return templateStr.includes(
    LEFT_CURLY_BRACES_DOUBLE && RIGHT_CURLY_BRACES_DOUBLE
  )
}
