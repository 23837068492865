<template>
  <!-- TO: default value for cleave is remaining -->
  <b-card>
    <b-container>
      <div
        v-if="isFetching"
        class="w-100 h-100 d-flex justify-content-center align-items-center"
      >
        <b-spinner variant="primary" class="spinner" />
      </div>
      <ValidationObserver ref="validationRules">
        <b-form @submit.prevent="saveChangesHandler()">
          <b-row v-if="!isFetching" class="row-height pt-1 pb-1">
            <b-col cols="12" sm="12" md="6" lg="4" xl="4">
              <div class="row-card">
                <b-form-group
                  label-cols="7"
                  label="Monthly Subscription fee ($) :"
                  label-for="monthly-subscription-fee"
                  label-size="md"
                >
                  <!-- <cleave
                id="input-default"
                class="form-control input-field"
                :raw="true"
                :options="options.prefix"
              /> -->
                  <ValidationProvider
                    #default="{ errors }"
                    rules="required"
                    :name="`monthly-subscription-fee`"
                  >
                    <Input
                      id="monthly-subscription-fee"
                      size="md"
                      classes="input-field"
                      :errors="errors"
                      v-model="FormData.monthlySubscriptionFee"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
                <!-- <b-form-group
                  label-cols="7"
                  label="Transaction fee ($) :"
                  label-for="input-default"
                  label-size="md"
                > -->
                  <!-- <cleave
                id="input-default"
                class="form-control input-field"
                :raw="false"
                :options="options.prefix"
              /> -->
                  <!-- <ValidationProvider
                    #default="{ errors }"
                    rules="required"
                    :name="`transaction-fee`"
                  >
                    <Input
                      id="transaction-fee"
                      size="md"
                      classes="input-field"
                      v-model="FormData.transactionFee"
                      :errors="errors"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group> -->
                                 <b-form-group
                    label-cols="7"
                    label="Voucher Valid for (Days):"
                    label-for="input-default"
                    label-size="md"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      rules="required"
                      :name="`voucher-validity`"
                    >
                      <Input
                        id="voucher-validity"
                        size="md"
                        classes="input-field"
                        :errors="errors"
                        v-model="FormData.voucherValidFor"
                      />

                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </b-form-group>
              </div>
            </b-col>

            <b-col cols="12" sm="12" md="6" lg="4" xl="4">
              <div class="row-card">
                <b-form-group
                  label-cols="7"
                  label="Log Catch Distance (Yards):"
                  label-for="distance"
                  label-size="md"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    rules="required"
                    :name="`distance`"
                  >
                    <Input
                      min="65"
                      type="number"
                      id="distance"
                      size="md"
                      classes="input-field"
                      v-model="FormData.logCatchDistance"
                      :errors="errors"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>

                <b-form-group
                  label-cols="7"
                  label="Voucher Expires in (Days) :"
                  label-for="voucher-expiry"
                  label-size="md"
                >
                  <ValidationProvider
                    #default="{ errors }"
                    rules="required"
                    :name="`input-field`"
                  >
                    <Input
                      id="voucher-expiry"
                      size="md"
                      classes="input-field"
                      :errors="errors"
                      v-model="FormData.voucherExpiresIn"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </div>
            </b-col>

            <b-col cols="12" sm="12" md="6" lg="4" xl="4">
              <div class="row-card">
                <div>
                  <!-- <b-form-group
                    label-cols="7"
                    label="Voucher Valid for (Days):"
                    label-for="input-default"
                    label-size="md"
                  >
                    <ValidationProvider
                      #default="{ errors }"
                      rules="required"
                      :name="`voucher-validity`"
                    >
                      <Input
                        id="voucher-validity"
                        size="md"
                        classes="input-field"
                        :errors="errors"
                        v-model="FormData.voucherValidFor"
                      />

                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </b-form-group> -->
                </div>

                <div class="text-right">
                  <Button
                    buttonVariant="primary"
                    buttonText="Save Changes"
                    classes="w-50"
                    :isLoading="isUpdating"
                    type="submit"
                  />
                </div>
              </div>
            </b-col>
          </b-row>
        </b-form>
      </ValidationObserver>
    </b-container>
  </b-card>
</template>

<script>
import {
  BContainer,
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BFormInput,
  BSpinner,
  BForm,
} from "bootstrap-vue";
import Cleave from "vue-cleave-component";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";

import Input from "../../components/Input.vue";
import Button from "../../components/Button.vue";
import { getConfigSettings, setConfigSettings } from "../../api/api.service";
import { ConfigSettings as ConfSettings } from "../../constants";
import showToast from "@/hooks/useToast";

export default {
  name: "Configuration Settings",
  components: {
    BContainer,
    BRow,
    BCol,
    BCard,
    BForm,
    BFormInput,
    BSpinner,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,

    Input,
    Button,
    Cleave,
  },
  data() {
    return {
      required,
      FormData: {
        monthlySubscriptionFee: "",
        transactionFee: "",
        logCatchDistance: "",
        voucherExpiresIn: "",
        voucherValidFor: "",
      },

      isFetching: false,
      isUpdating: false,

      form: {
        monthlySubscription: "12",
      },
      options: {
        prefix: {
          prefix: "$ 1.99",
          numeral: true,
        },
      },
    };
  },
  methods: {
    async getConfigSettingsHandler() {
      try {
        this.isFetching = true;
        let response = await getConfigSettings();

        let ConfigSettArr = response.ConfigSettings;

        ConfigSettArr.map((item, index) => {
          this.FormData[ConfSettings.getDisplayTextKey(item.Key)] = item.Value;
        });
        this.isFetching = false;
      } catch (err) {
        this.isFetching = false;
        showToast(this, {
          Title: "Error",
          Icon: "XCircleIcon",
          Text: err.Message,
          Variant: "danger",
          Position: "top-right",
        });
      } finally {
        this.isFetching = false;
      }
    },
    async setConfigSettingsHandler(bodyData) {
      try {
        this.isUpdating = true;
        let response = await setConfigSettings(bodyData);
        showToast(this, {
          Title: `Success`,
          Icon: "CheckCircleIcon",
          Position: "top-right",
          Text: response.Message,
        });
        this.isUpdating = false;
      } catch (err) {
        this.isUpdating = false;
        showToast(this, {
          Title: "Error",
          Icon: "XCircleIcon",
          Text: err.Message,
          Variant: "danger",
          Position: "top-right",
        });
      } finally {
        this.isUpdating = false;
      }
    },

    async saveChangesHandler() {
      let canSubmit = await this.$refs.validationRules.validate();
      if (!canSubmit) {
        return;
      }

      let bodyData = {
        ConfigSettings: [
          ...Object.values(ConfSettings).map((item) => ({
            Key: item,
            Value: this.FormData[ConfSettings.getDisplayTextKey(item)],
          })),
        ],
      };

      this.setConfigSettingsHandler(bodyData);
    },
  },
  beforeMount() {
    this.getConfigSettingsHandler();
  },
};
</script>

<style scoped>
.row-card {
  height: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.input-field {
  font-weight: bold !important;
}
.spinner {
  width: 5rem;
  height: 5rem;
  margin-top: 20px;
}
</style>