<template>
  <div>
    <b-form-file
      :required="isRequired"
      :placeholder="placeholderText"
      :state="errors && errors.length > 0 ? false : null"
      v-model="getValue"
      :multiple="multiple"
      :accept="acceptFormats"
      @input="onChange"
      :value="data"
    />

    <b-card-text class="my-1" v-if="showSelected">
      Selected file: <strong>{{ file ? file.name : "" }}</strong>
    </b-card-text>
  </div>
</template>


<script>
import { BFormFile } from "bootstrap-vue";

export default {
  name: "FileInput",
  components: {
    BFormFile,
  },
  props: {
    value: String | Array,
    onChange: Function,
    data: String | Array,
    multiple: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default: null,
    },
    showSelected: {
      type: Boolean,
      default: false,
    },
    placeholderText: {
      type: String,
      default: "placeholder ..",
    },
    acceptFormats: {
      type: String,
      default: ".jpg, .png",
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getValue: {
      get() {
        //this function will determine what is displayed in the input
        return this.value;
      },
      set(val) {
        //this function will run whenever the input changes and set thr value to field
        this.$emit("input", val);
      },
    },
  },
  // data() {
  //   return {
  //     file: null,
  //     file2: null,
  //   };
  // },
};
</script>
 