<template>
	<div>
		<b-nav pills class="font-weight-bolder nav-bar">
			<b-nav-item
				v-for="(item, index) in menuItems"
				:key="index"
				class="nav-item"
				:to="item.menuRoute"
				exact
				exact-active-class="active"
				>{{ item.menuName }}</b-nav-item
			>
		</b-nav>
	</div>
</template>

<script>
import { BNav, BNavItem } from 'bootstrap-vue'

export default {
	name: 'Nav',
	components: {
		BNav,
		BNavItem,
	},
	props: {
		menuItems: {
			type: Array,
			default: [
				{
					menuName: 'Home',
					menuRoute: '/',
				},
				{
					menuName: 'About',
					menuRoute: '/',
				},
			],
		},
	},
}
</script>

<style scoped>
@media only screen and (max-width: 576px) {
	.nav-item {
		width: 100% !important;
	}
}
@media only screen and (max-width: 768px) {
	.nav-item {
		width: 100% !important;
	}
}
.nav-pills .nav-link {
	color: black !important;
}
.nav-pills .nav-link.active {
	color: white !important;
}
.nav-pills {
	margin-bottom: 0 !important;
}
</style>