<template>
	<div class="m-2" :class="classes">
		<!-- Table Top -->
		<b-row>
			<!-- Per Page -->
			<b-col
				cols="12"
				md="6"
				class="
					d-flex
					align-items-center
					justify-content-start
					mb-1 mb-md-0
				"
			>
				<template v-if="showPerPageOption">
					<input-select
						:options="perPageOptions"
						:clearable="false"
						:value="pageLimitValue"
						:onChange="onLimitChange"
						placeholder="10"
						classes="per-page-selector d-inline-block ml-50 mr-1"
						:showLabel="true"
						:labelText="perPageSelectorText"
					></input-select>
				</template>

				<slot name="left-header-content"> </slot>
			</b-col>

			<!-- Search -->
			<b-col cols="12" md="6">
				<div class="d-flex align-items-center justify-content-end">
					<span v-if="showSearchField" class="search-label mr-2"
						>Search</span
					>
					<b-form-input
						@input="onSearchChange"
						:value="searchText"
						v-if="showSearchField"
						class="d-inline-block"
						:class="searchInputClasses"
					/>
					<input-select
						v-if="showStatusFilter"
						:options="statusOptions"
						:classes="`filter-select ml-3 ${filterClasses}`"
						:placeholder="selectPlaceholder"
						:value="
							filterConstants
								? filterConstants.getDisplayTextKey(filterValue)
								: filterValue
						"
						:onChange="onFilterChange"
						:class="selectInputClasses"
					/>
					<slot name="right-header-content"> </slot>
				</div>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import {
	BCard,
	BRow,
	BCol,
	BFormInput,
	BButton,
	BTable,
	BMedia,
	BAvatar,
	BLink,
	BBadge,
	BDropdown,
	BDropdownItem,
	BPagination,
	BTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import InputSelect from './InputSelect.vue'

export default {
	name: 'SearchHeader',
	components: {
		BCard,
		BRow,
		BCol,
		BFormInput,
		BButton,
		BTable,
		BMedia,
		BAvatar,
		BLink,
		BBadge,
		BDropdown,
		BDropdownItem,
		BPagination,
		BTooltip,
		vSelect,
		InputSelect,
	},

	props: {
		statusOptions: {
			type: Array,
			default: [{ label: 'Select', value: 1 }],
		},
		perPage: {
			type: Number,
			default: 10,
		},
		showPerPageOption: {
			type: Boolean,
			default: true,
		},
		showStatusFilter: {
			type: Boolean,
			default: true,
		},
		showSearchField: {
			type: Boolean,
			default: true,
		},
		perPageSelectorText: {
			type: String,
			default: 'Show',
		},
		perPageOptions: {
			type: Array,
			default: [
				{ label: '5', value: 5 },
				{ label: '10', value: 10 },
				{ label: '25', value: 25 },
			],
		},
		classes: {
			type: String,
			default: '',
		},
		onSearchChange: Function,
		searchText: {
			default: '',
			type: String,
		},
		onFilterChange: Function,
		filterValue: {
			default: '',
		},
		onPageLimitChange: Function,
		pageLimitValue: {
			default: '',
		},
		filterConstants: {
			type: Object,
			default: null,
		},
		searchInputClasses: {
			type: String,
			default: '',
		},
		selectInputClasses: {
			type: String,
			default: '',
		},
		selectPlaceholder: {
			type: String,
			default: 'Select Status',
		},
		filterClasses: {
			default: '',
		},
	},
	methods: {
		onLimitChange: function (val) {
			this.onPageLimitChange(val.value)
		},
	},
	methods: {
		onLimitChange: function (val) {
			this.onPageLimitChange(val.value)
		},
	},
}
</script>
<style lang="scss" scoped>
::v-deep .per-page-selector {
	width: 100px;
}
::v-deep .filter-select {
	width: 210px !important;

	::v-deep .vs__selected-options {
		flex-wrap: nowrap;
	}

	::v-deep .vs__selected {
		width: 100px;
	}
}
.search-label {
	color: #888888;
}
.margin-bottom-only {
	margin: 0 0 10px 0 !important;
}
</style>
