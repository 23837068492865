<template>
  <div>
    <b-card-title title-tag="h4" class="font-weight-bolder mb-1">
      Forgot Your Password
    </b-card-title>

    <validation-observer ref="loginValidation">
      <b-form class="auth-login-form mt-2" @submit.prevent>
        <!-- email -->
        <b-form-group>
          <label for="login-email" class="font-weight-bolder">Email</label>
          <validation-provider
            #default="{ errors }"
            name="Email"
            rules="required|email"
          >
            <b-form-input
              id="login-email"
              v-model="userEmail"
              :state="errors.length > 0 ? false : null"
              name="login-email"
              placeholder="john@example.com"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
          <br />
          <router-link to="/auth">
            <small>Back to Login</small>
          </router-link>
        </b-form-group>

        <!-- submit buttons -->

        <Button
          type="submit"
          buttonVariant="primary"
          blockButton
          :clickHandler="handleSubmit"
          buttonText="Verify"
          :isLoading="isLoading"
        />
      </b-form>
    </validation-observer>
    <b-modal
      id="forgot-password-confirm-modal"
      centered
      hide-header
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
    >
      <div class="container">
        <feather-icon icon="MailIcon" class="restrict" />

        <p class="text">
          A Confirmation code has been sent to your email please enter it to set
          your new password
        </p>

        <div class="btn-container">
          <Button
            size="md"
            buttonVariant="primary"
            classes="wd-211"
            buttonText="Okay"
            :clickHandler="handleOkayPress"
          />
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  VBModal,
  BModal,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";

import Input from "../../../components/Input.vue";
import Button from "../../../components/Button.vue";
import { APP_ROUTES, getRoute } from "../../../helpers/routeHelpers";
import { forgotPassword } from "../../../api/api.service";

export default {
  name: "AuthForgetPassword",
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    VBModal,
    BModal,
    Input,
    Button,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: "",
      password: "",
      userEmail: "",
      // validation rulesimport store from '@/store/index'
      required,
      email,
      isLoading: false,
      tokenUUID: null,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  methods: {
    handleSubmit() {
      this.$refs.loginValidation.validate().then((success) => {
        if (!success) {
          return;
        }

        this.isLoading = true;

        let payload = {
          Email: this.userEmail,
        };

        forgotPassword(payload)
          .then((res) => {
            this.tokenUUID = res.TokenUUID;
            this.$bvModal.show("forgot-password-confirm-modal");
          })
          .finally(() => {
            this.isLoading = false;
          });
      });
    },
    handleOkayPress() {
      this.$bvModal.hide("forgot-password-confirm-modal");
      this.$router.push(
        getRoute(APP_ROUTES.RESET_PASSWORD, { tokenUUID: this.tokenUUID })
      );

      this.tokenUUID = null;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>

<style scoped>
.middle-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.restrict {
  width: 96px;
  height: 96px;
  margin-top: 40px;
  align-self: center;
  color: #000;
}

.text {
  font-weight: 600;
  font-size: 20px;
  color: #000;
  align-self: center;
  text-align: center;
  margin-top: 38px;
}

.btn-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 65px;
  margin-bottom: 10px;
}

::v-deep .wd-211 {
  width: 200px;
}
</style>
