import {
    getpostList,
    getUserCatalogList,
    getUserReviewList,
    updateUser,
    getCommunityPostList,
    getCommunityPostById
} from "../../api/api.service";
import config from "../../config";
export default {
    namespaced: true,
    state: {
        fetching: false,
        list: [],
        totalPosts: 0,
        isDataAvailable: true
    },
    getters: {
        getCommunityPostById: (state) => (id) =>
            state.list.find((post) => post.Id === id),
        getCommunityPostList: (state) => {
            let communityPost = state.list;
            let transformedCommunityPosts = [];
            for (let i = 0; i < communityPost.length; i += 2) {
                let arr = [];
                arr.push(communityPost[i])
                if (communityPost[i + 1]) {
                    arr.push(communityPost[i + 1])
                }
                transformedCommunityPosts.push(arr)
            }

            return transformedCommunityPosts;
        }
    },
    actions: {
        async getPostListAction({ commit }, params = {}) {
            const { signal, ...queryParams } = params;
            commit("postListRequest", {
                page: params.Page
            });
            try {
                let response = await getCommunityPostList(queryParams, signal);
                commit("postListSuccess", {
                    payload: response.CommunityPost,
                    totalPosts: response.TotalCommunityPost,
                });
            } catch (err) {
                if (err.name !== "AbortError") {
                    commit("postListFail");
                }
            }
        },
        async getPostByIdAction({ commit }, params = {}) {
            const { signal, ...queryParams } = params;
            commit("postListRequest");
            try {
                let response = await getCommunityPostById(queryParams);
                commit("postListSuccess", {
                    payload: response.CommunityPost,
                    totalPosts: response.TotalCommunityPost,
                });
            } catch (err) {
                if (err.name !== "AbortError") {
                    commit("postListFail");
                }
            }
        },
    },
    mutations: {
        postListRequest: (state, action) => {
            state.fetching = true;
            state.list = (() => {
                if (!action.page || action.page === 1) {
                    return [];
                }
                return state.list;
            })();
        },
        postListSuccess: (state, action) => {
            state.fetching = false;
            state.list = [...state.list, ...action.payload];
            state.totalPosts = action.totalPosts;
            state.isDataAvailable = action.payload.length === Number(config.LIMIT)
        },
        postListFail: (state, payload) => {
            state.fetching = false;
        }
    },
};
