import { injectBaseConstantMethods } from "./BaseConstants";

export const DaysOfWeek = {
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
  SUNDAY: 7,
};
const labelClass = {};
const displayTextKeys = {
  [DaysOfWeek.MONDAY]: "Mon",
  [DaysOfWeek.TUESDAY]: "Tue",
  [DaysOfWeek.WEDNESDAY]: "Wed",
  [DaysOfWeek.THURSDAY]: "Thu",
  [DaysOfWeek.FRIDAY]: "Fri",
  [DaysOfWeek.SATURDAY]: "Sat",
  [DaysOfWeek.SUNDAY]: "Sun",
};

export default injectBaseConstantMethods(DaysOfWeek, displayTextKeys);
