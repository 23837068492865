import { injectBaseConstantMethods } from "./BaseConstants";

export const StatusCodes = {
  ACTIVE: 1,
  SUSPENDED: 2,
};

const displayTextKeys = {
  [StatusCodes.ACTIVE]: "Active",
  [StatusCodes.SUSPENDED]: "Suspended",
};

const labelClass = {
  [StatusCodes.ACTIVE]: "light-success",
  [StatusCodes.SUSPENDED]: "light-warning",
};

export default injectBaseConstantMethods(
  StatusCodes,
  displayTextKeys,
  labelClass
);
