<template>
  <b-row>
    <b-col lg="12" md="12" sm="12">
      <b-card v-if="selectedTripDetails">
        <b-col lg="12" md="12" sm="12" class="mb-0"
          ><Title text="Trip Detail Summary" classes="font-weight-bolder" />
        </b-col>
        <b-row class="mt-3 pl-1">
          <b-col cols="12" sm="12" md="4" lg="4" class="bold">
            <label-text
              label="Meeting Point"
              :text="validateValue(selectedTripDetails.MeetingPointAddress)"
              textClasses="f-bold"
            />
            <label-text
              label="Trip Name"
              :text="validateValue(selectedTripDetails.Title)"
              textClasses="f-bold"
            />
            <label-text
              label="Captain Name"
              :text="validateValue(selectedTripDetails.CaptainName)"
              textClasses="f-bold"
            />
            <label-text
              label="Captain Phone"
              :text="`${selectedTripDetails.CaptainContactCountryCode || ''} ${
                selectedTripDetails.CaptainContactNumber || '-'
              }`"
              textClasses="f-bold"
            />
            <label-text
              label="Days"
              :text="` ${validateValue(
                selectedTripDetails.WeekDays.map((item) =>
                  DaysOfWeek.getDisplayTextKey(item)
                )
              )}`"
              textClasses="f-bold"
            />

            <label-text
              label="Time/Duration"
              :text="
                validateValue(
                  `${selectedTripDetails.TripDuration} ${
                    selectedTripDetails.TripDurationUnit
                  }, ${convertTime({
                    tz: 'LOCAL_TIME',
                    time: selectedTripDetails.DepartureTime,
                    format: dateTimeFormat.appTimeFormat,
                  })}-${getTripEndTime(
                    selectedTripDetails.TripDuration,
                    selectedTripDetails.DepartureTime
                  )}`
                )
              "
              textClasses="f-bold"
            />
          </b-col>
          <b-col cols="12" sm="12" md="4" lg="5" class="bold">
            <label-text
              label="Start Date"
              :text="
                formatStartDate(validateValue(selectedTripDetails.StartDate))
              "
              textClasses="f-bold"
            />
            <label-text
              label="Trip Type"
              :text="
                validateValue(
                  GuideTripType.getDisplayTextKey(selectedTripDetails.Type)
                )
              "
              textClasses="f-bold"
            />
            <label-text
              v-if="
                selectedTripDetails.Type === GuideTripType.PRIVATE &&
                selectedTripDetails.PricingType === TripPricingType.GROUP &&
                selectedTripDetails.AdditionalPersonPricing ===
                  TripAdditionalPersonPricingType.ENABLED
              "
              label="Total Price Based on"
              :text="`${validateValue(selectedTripDetails.MinBookingPerson)}`"
              textClasses="f-bold"
            />
            <label-text
              v-else-if="
                selectedTripDetails.PricingType === TripPricingType.PERSON ||
                selectedTripDetails.Type === GuideTripType.SHARED
              "
              label="Minimum Persons Per Booking"
              :text="`${validateValue(
                selectedTripDetails.MinBookingPerson
              )} Persons`"
              textClasses="f-bold"
            />
            <label-text
              label="Listing Type"
              :text="validateValue(listingType)"
              textClasses="f-bold"
            />
            <label-text
              label="Boat Type"
              :text="validateValue(selectedTripDetails.BoatType)"
              textClasses="f-bold"
            />
            <label-text
              label="Boat Capacity"
              :text="
                validateValue(selectedTripDetails.MaxPersonLimit) + ' Persons'
              "
              textClasses="f-bold"
            />
          </b-col>
          <b-col cols="12" sm="12" md="4" lg="3" class="bold">
            <label-text
              v-if="!isFullPayment"
              label="Down Payment"
              :text="`$${validateValue(selectedTripDetails.DepositPercent)}%`"
              textClasses="f-bold"
            />
            <label-text
              label="Price"
              :text="`$${validateValue(selectedTripDetails.PricePerPerson)} ${
                selectedTripDetails.Type === GuideTripType.PRIVATE &&
                selectedTripDetails.PricingType === TripPricingType.GROUP
                  ? ''
                  : 'Per Person'
              }`"
              textClasses="f-bold"
            />
            <label-text
              v-if="
                selectedTripDetails.Type === GuideTripType.PRIVATE &&
                selectedTripDetails.PricingType === TripPricingType.GROUP &&
                selectedTripDetails.AdditionalPersonPricing ===
                  TripAdditionalPersonPricingType.ENABLED
              "
              label="Additional person charges"
              :text="`$${validateValue(
                selectedTripDetails.AdditionalPerPersonAmount
              )}`"
              textClasses="f-bold"
            />
            <Button
              size="md"
              buttonVariant="outline-secondary"
              classes="mt-1 wd-211 mb-1"
              buttonText="View Details"
              :clickHandler="() => viewTripDetails(selectedTripDetails.Id)"
            />
            <Button
              size="md"
              buttonVariant="danger"
              classes="wd-211"
              buttonText="Delete Entire Trip"
              :clickHandler="() => handleDeletePress(selectedTripDetails.Id)"
            />
          </b-col>
          <ConfirmModal
            id="delete-trip-modal"
            :onYesPress="deleteTripHandler"
            :isLoading="isLoading"
            :isSuccess="isDeleteSuccess"
            :onOkayPress="handleOkayPress"
            messageText="Are you sure you want to delete this trip?"
            successText="Trip deleted successfully !"
          />
        </b-row>
      </b-card>
      <b-row class="mb-2">
        <b-col lg="6" md="6" sm="12" class="pd-0"
          ><Title text="Booking Details" />
        </b-col>
        <b-col lg="6" md="6" sm="12" class="text-right">
          <div class="w-100">
            <Select
              :options="filterTripsOptions"
              :value="filterValue"
              :onChange="setFilterHandler"
              :clearable="false"
              placeholder="Filter Trips"
              classes="d-inline-block w-50 white-bg"
              :showLabel="true"
            />
          </div>
        </b-col>
      </b-row>

      <booking-detail-item :isFutureTrips="futureTrip" />
    </b-col>
  </b-row>
</template>

<script>
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BTableLite,
  BCardText,
  BButton,
  BAlert,
  BLink,
} from "bootstrap-vue";
import moment from "moment";

import ConfirmModal from "../../../components/ConfirmModal.vue";
import LabelText from "../../../components/LabelText.vue";
import Button from "../../../components/Button.vue";
import Title from "../../../components/Title.vue";
import TripsListing from "./TripsListing.vue";
import BookingDetailItem from "./BookingDetailItem.vue";
import { getRouteId } from "../../../utils/getRouteId";
import { validateValue } from "../../../utils/commonUtils";
import { getATripDetail } from "../../../api/api.service";
import {
  GuideTripType,
  DaysOfWeek,
  TripPaymentType,
  TripPricingType,
  TripAdditionalPersonPricingType,
} from "../../../constants";
import { APP_ROUTES, getRoute } from "../../../helpers/routeHelpers";
import {
  dateTimeFormat,
  convertDateTime,
  convertTime,
} from "../../../utils/dateFormatUtils";
import Select from "../../../components/InputSelect.vue";

export default {
  name: "BookingDetails",
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
    AppCollapse,
    AppCollapseItem,
    LabelText,
    Button,
    Title,
    TripsListing,
    BookingDetailItem,
    ConfirmModal,
    Select,
  },

  data() {
    return {
      getRouteId,
      validateValue,
      convertDateTime,
      convertTime,
      GuideTripType,
      TripPricingType,
      TripAdditionalPersonPricingType,
      dateTimeFormat,
      DaysOfWeek,
      selectedTripId: "",
      selectedTripDetails: null,
      isLoading: false,
      isDeleteSuccess: false,
      tripId: "",
      filterTripsOptions: [
        { label: "Past Trips", value: false },
        { label: "Future Trips", value: true },
      ],
      filterValue: null,
      futureTrip: true,
    };
  },

  created() {
    this.selectedTripId = this.getRouteId("bookingId");

    let selectedTrip = this.$store.getters["guides/getTripById"](
      this.selectedTripId
    );

    if (selectedTrip) {
      this.selectedTripDetails = selectedTrip;
    }
    getATripDetail(this.$route.params.bookingId).then((res) => {
      this.selectedTripDetails = res.GuideTrip;
    });
  },
  methods: {
    deleteTripHandler() {
      (this.isLoading = true),
        this.$store
          .dispatch("guides/deleteEntireTrip", this.tripId)
          .then(() => {
            this.isLoading = false;
            this.isDeleteSuccess = true;
          })
          .catch(() => {
            this.isLoading = false;
          })
          .finally(() => {
            this.isLoading = false;
            this.isDeleteSuccess = true;
          });
    },
    formatStartDate(val) {
      return moment(val, "YYYY-MM-DD").format("Do MMM YYYY");
    },
    handleOkayPress() {
      this.isDeleteSuccess = false;
      this.$router.go(-1);
    },
    viewTripDetails(id) {
      this.$router.push(
        getRoute(APP_ROUTES.BOOKING_TRIP_DETAILS, {
          tripId: id,
          guideId: this.$route.params.guideId,
          bookingId: this.selectedTripId,
        })
      );
    },
    getTripEndTime(duration, starttime) {
      return moment(starttime, "HH:mm:ss")
        .add(duration, "hours")
        .format("HH:mm");
    },
    handleDeletePress(id) {
      this.$bvModal.show("delete-trip-modal");
      this.tripId = id;
    },

    setFilterHandler(value) {
      this.filterValue = value;
      this.futureTrip = value.value;
    },
  },
  computed: {
    isFullPayment() {
      return (
        this.selectedTripDetails &&
        this.selectedTripDetails.PaymentType === TripPaymentType.FULL
      );
    },
    listingType() {
      return this.selectedTripDetails?.ListingType?.Name || "";
    },
  },
};
</script>

<style lang="scss" scoped>
.align-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 11px;
}

.label {
  size: 14px;
  color: #888888;
}
::v-deep .text {
  font-weight: 500 !important;
}

::v-deep .font-bold {
  font-weight: 800 !important;
}

.earning-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
}

@media only screen and (max-width: 600px) {
  .earning-wrapper {
    align-items: flex-start;
  }
}

::v-deep .wd-211 {
  width: 211px;
}

::v-deep .wd-90 {
  width: 90px;
}

::v-deep .wd-130 {
  width: 130px;
}

.card-body-container {
  padding-top: 0px !important;
}

::v-deep .bold .f-bold {
  font-weight: 800 !important;
}
</style>
