import { render, staticRenderFns } from "./CatalogueTable.vue?vue&type=template&id=4cdad7f8&scoped=true&"
import script from "./CatalogueTable.vue?vue&type=script&lang=js&"
export * from "./CatalogueTable.vue?vue&type=script&lang=js&"
import style0 from "./CatalogueTable.vue?vue&type=style&index=0&id=4cdad7f8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4cdad7f8",
  null
  
)

export default component.exports