import { getGearListing, deleteGear } from "../../api/api.service";

export const GET_GEAR_LIST = "getGearList";
export const GET_GEAR_FROM_STORE = "getGearFromStore";
export const GEARS_LISTING_REQUEST = "gearsListingRequest";
export const GEARS_LISTING_SUCCESS = "gearsListingSuccess";
export const GEARS_LISTING_FAILURE = "gearsListingFailure";
export const DELETE_GEAR = "deleteGear";
export const DELETE_GEAR_FROM_STORE = "deleteGearFromStore";

export default {
  state: {
    list: [],
    totalGears: 0,
    fetching: false,
  },
  actions: {
    [GET_GEAR_LIST]: async ({ commit }, params = {}) => {
      const { signal, ...queryParams } = params;
      commit(GEARS_LISTING_REQUEST);
      try {
        const res = await getGearListing(queryParams, signal);
        commit(GEARS_LISTING_SUCCESS, {
          payload: res.Gears,
          totalGears: res.TotalGears,
        });
      } catch (err) {
        if (err.name !== "AbortError") {
          commit(GEARS_LISTING_FAILURE);
        }
      }
    },
    [DELETE_GEAR]: async ({ commit }, id) => {
      try {
        const res = await deleteGear(id);
        commit(DELETE_GEAR_FROM_STORE, { id });
        return Promise.resolve(res);
      } catch (err) {
        return Promise.reject(err);
      }
    },
  },
  getters: {
    [GET_GEAR_FROM_STORE]: (state) => (id) => {
      return state.list.find((gear) => gear.Id === id);
    },
  },
  mutations: {
    [GEARS_LISTING_REQUEST]: (state) => {
      state.fetching = true;
      state.list = [];
      state.totalGears = 0;
    },
    [GEARS_LISTING_SUCCESS]: (state, action) => {
      state.fetching = false;
      state.list = action.payload;
      state.totalGears = action.totalGears;
    },
    [GEARS_LISTING_FAILURE]: (state, payload) => {
      state.fetching = false;
    },
    [DELETE_GEAR_FROM_STORE]: (state, payload) => {
      state.list = state.list.filter((item) => item.Id !== payload.id);
      state.totalGears = state.totalGears - 1;
    },
  },
};
