import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function useToast(reference, data) {
  let {
    Title = "Notification",
    Text = "",
    Variant = "success",
    Position = "top-right",

    Icon = "BellIcon",
    // Success Icon: CheckCircleIcon
    // Fail Icon: XCircleIcon
  } = data;

  reference.$toast(
    {
      component: ToastificationContent,
      props: {
        title: Title,
        icon: Icon,
        text: Text,
        variant: Variant,
      },
    },
    {
      position: Position,
    },
  );
}
