import { injectBaseConstantMethods } from "./BaseConstants";

export const TripsBookingStatus = {
  ACCEPTED: 1,
  REJECTED: 2,
  PENDING: 3,
  TRIPCANCELLED: 4,
};

const displayTextKeys = {
  [TripsBookingStatus.ACCEPTED]: "Accepted",
  [TripsBookingStatus.PENDING]: "Pending",
  [TripsBookingStatus.REJECTED]: "Rejected",
  [TripsBookingStatus.TRIPCANCELLED]: "Trip Cancelled",
};

// const labelClass = {};

export default injectBaseConstantMethods(
  TripsBookingStatus,
  displayTextKeys
  //   labelClass
);
