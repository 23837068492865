<template>
  <div>
    <b-card body-class="card-body-style">
      <SearchHeader
        searchInputClasses="w-50"
        :showStatusFilter="false"
        :searchText="searchText"
        :onSearchChange="handleSearchChange"
        :pageLimitValue="pageLimit"
        :onPageLimitChange="handlePageLimitChange"
      />
      <data-table
        :perPageLimit="pageLimit"
        entriesName="Reviews"
        :config="config"
        :data="listData"
        tableClasses="br-6 p-0"
        :isLoading="fetching"
        :perPage="data.length"
        :selectedPageValue="page"
        :onHandlePageChange="handlePageChange"
        :totalRecords="totalReviews"
        :onSortClick="handleSortChange"
        :currentSortOrder="sortOrder"
        :currentSortColumn="sortColumn"
      >
        <template #cell(No)="data">
          <span class="font-weight-bolder"> {{ data.item.Id }} </span>
        </template>

        <template #cell(SellerName)="data">
          <span class="text-nowrap">
            <user-info
              iconSize="md"
              :fullName="
                data.item.Location
                  ? data.item.Location.User.FullName || '-'
                  : data.item.GuideTrip.User.FullName || '-'
              "
              :avatarImage="
                data.item.Location
                  ? getMediaPath(data.item.Location.Media)
                  : getMediaPath(data.item.GuideTrip.Media)
              "
              nameClasses="black"
            />
          </span>
        </template>

        <template #cell(Listing)="data">
          <template>
            <div>
              <span class="font-weight-bolder">
                {{
                  data.item.Location
                    ? data.item.Location.Title || "-"
                    : data.item.GuideTrip.Title || "-"
                }}
              </span>
              <br />
              <span class="date-light">
                {{ data.item.GuideTrip && data.item.GuideTrip.StartDate }}
              </span>
            </div>
          </template>
        </template>

        <template #cell(Location)="data">
          <template>
            <span class="font-weight-bolder">
              {{
                data.item.Location
                  ? data.item.Location.User.CountryCodeAlpha || "-"
                  : data.item.GuideTrip.User.CountryCodeAlpha || "-"
              }}
            </span>
          </template>
        </template>

        <template #cell(CreatedAt)="data">
          <template>
            <span class="fw-500"
              >{{
                convertDateTime({
                  date: data.item.PurchaseDate,
                  customFormat: dateTimeFormat.appDateFormat,
                  dateOnly: true,
                })
              }}
            </span>
          </template>
        </template>

        <template #cell(CustomerName)="data">
          <span class="text-nowrap">
            <user-info
              iconSize="md"
              :fullName="data.item.User.FullName || '-'"
              :avatarImage="getMediaPath(data.item.User.Media)"
              nameClasses="black"
            />
          </span>
        </template>

        <template #cell(EntityType)="data">
          <template>
            <span class="fw-500">
              {{ purchaseType.getDisplayTextKey(data.item.EntityType) || "-" }}
            </span>
          </template>
        </template>

        <template #cell(Price)="data">
          <template>
            <span>$ {{ data.item.Price || "-" }} </span>
          </template>
        </template>

        <template #cell(Rating)="data">
          <template>
            <span class="fw-500"> {{ data.item.Rating || "-" }} </span>
          </template>
        </template>

        <!-- Column: Actions -->
        <template #cell(Reviews)="data">
          <div class="text-nowrap">
            <feather-icon
              v-on:click="viewReviewDetails(data.item.Id)"
              :id="`fish-gear-row-${data.item.Id}-detail-icon`"
              icon="EyeIcon"
              size="16"
              class="mx-1 detail-icon cursor-pointer"
            />
            <b-tooltip
              title="Details"
              :target="`fish-gear-row-${data.item.Id}-detail-icon`"
            />
          </div>
        </template>
      </data-table>
      <!-- `</b-card-body>` -->
    </b-card>
  </div>
</template>

<script>
import { BCard, BCardBody, BBadge, BLink, BTooltip } from "bootstrap-vue";
import { computed } from "@vue/composition-api";

import DataTable from "../../components/data-table/DataTable.vue";
import UserInfo from "../../components/UserInfo.vue";
import SearchHeader from "../../components/SearchHeader.vue";
import DeleteIcon from "../../assets/images/icons/delete.svg";
import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers";
import { getMediaPath } from "../../utils/commonUtils";
import { useStore } from "../../store";
import {
  dateTimeFormat,
  convertDateTime,
  convertTime,
} from "../../utils/dateFormatUtils";
import { usePaginatedRequest } from "../../hooks/usePaginatedRequest";
import { purchaseType } from "../../constants/index";

export default {
  name: "RatingReviews",
  components: {
    BCard,
    BCardBody,
    BBadge,
    BLink,
    BTooltip,
    DataTable,
    UserInfo,
    SearchHeader,
  },
  setup(props, context) {
    const store = useStore();

    let fetching = computed(() => store.state.ratingReviews.fetching);
    let listData = computed(() => store.state.ratingReviews.reviewsList);
    let totalReviews = computed(() => store.state.ratingReviews.totalReviews);
    let config = [
      {
        label: "NO",
        key: "No",
      },
      {
        label: "SELLER NAME",
        key: "SellerName",
      },
      {
        label: "Listing",
        key: "Listing",
      },
      {
        label: "LOCATION",
        key: "Location",
      },
      {
        label: "PURCHASE DATE",
        key: "CreatedAt",
        isSortable: true,
      },
      {
        label: "CUSTOMER NAME",
        key: "CustomerName",
      },
      {
        label: "PURCHASE TYPE",
        key: "EntityType",
        isSortable: true,
      },
      {
        label: "PRICE",
        key: "Price",
      },
      {
        label: "RATINGS",
        key: "Rating",
        isSortable: true,
      },
      {
        label: "REVIEWS",
        key: "Reviews",
      },
    ].map((item) => {
      return { ...item, tdClass: "table-row", thClass: "table-header" };
    });
    let data = [];
    const {
      page,
      pageLimit,
      searchText,
      sortOrder,
      sortColumn,
      handleSearchChange,
      handlePageClick,
      handlePageLimit,
      handleSortChange,
    } = usePaginatedRequest("ratingReviews/getReviewsListAction");

    function viewReviewDetails(id) {
      this.$router.push(
        getRoute(APP_ROUTES.RATING_REVIEWS_DETAILS, { reviewId: id })
      );
    }
    function handlePageChange(val) {
      handlePageClick(val);
    }

    function handlePageLimitChange(val) {
      handlePageLimit(val);
    }

    return {
      deleteIcon: DeleteIcon,
      config,
      data,
      fetching,
      listData,
      totalReviews,
      dateTimeFormat,
      page,
      pageLimit,
      searchText,
      sortOrder,
      sortColumn,
      purchaseType,
      viewReviewDetails,
      getMediaPath,
      convertDateTime,
      convertTime,
      handleSearchChange,
      handlePageClick,
      handlePageLimit,
      handleSortChange,
      handlePageChange,
      handlePageLimitChange,
    };
  },
};
</script>

<style scoped>
::v-deep .active-icon {
  color: #28c76f;
}

::v-deep .inactive-icon {
  color: #d88416;
}

::v-deep .detail-icon {
  color: #888888;
}

.delete-icon {
  width: 14px;
  height: 14px;
}

.fw-500 {
  font-weight: 500;
}

::v-deep .black {
  color: #000;
}

.date-light {
  font-weight: 400;
  color: #888888;
}

.card-body-style {
  padding: 0 !important;
}
::v-deep .table-row {
  font-weight: bold;
}
.table-header {
  width: auto;
}
</style>
