export const SET_DEVICE_ID = "setDeviceId";
export const GET_DEVICE_ID = "getDeviceId";

export const initialState = {
  deviceUUID: null,
};

export default {
  state: initialState,
  actions: {},
  getters: {},
  mutations: {
    [SET_DEVICE_ID]: (state, payload) => {
      state.deviceUUID = payload;
    },
  },
};
