<template>
  <div class="table-container">
    <b-card body-class="card-body-style">
      <search-header
        :statusOptions="getStatusOptions(ListingStatusCode)"
        :filterValue="filters.Status"
        :onFilterChange="handleFilterChange"
        :searchText="searchText"
        :onSearchChange="handleSearchChange"
        :pageLimitValue="pageLimit"
        :onPageLimitChange="handlePageLimit"
      />

      <data-table
        :perPageLimit="pageLimit"
        entriesName="Guides"
        :isLoading="fetching"
        :config="config"
        :data="data"
        :perPage="data.length"
        :selectedPageValue="page"
        :onHandlePageChange="handlePageClick"
        :totalRecords="totalGuides"
        :onSortClick="handleSortChange"
        :currentSortOrder="sortOrder"
        :currentSortColumn="sortColumn"
      >
        <!-- Column: Id -->
        <template #cell(Id)="data">
          <span class="font-weight-bolder"> #{{ data.item.Id }} </span>
        </template>

        <template #cell(BusinessName)="data">
          <span class="text-nowrap">
            <user-info
              iconSize="md"
              :fullName="data.item.User.FullName || '-'"
              :subText="data.item.User.Email || '-'"
              :avatarImage="getMediaPath(data.item.User.Media) || '-'"
            />
          </span>
        </template>

        <!-- Column: Issued Date -->
        <template #cell(BusinessNumber)="data">
          <span class="text-nowrap">
            {{ data.item.BusinessNumber || "-" }}
          </span>
        </template>
        <!-- Column: Registered On -->
        <template #cell(CreatedAt)="data">
          <span class="text-nowrap">
            {{
              convertDateTime({
                date: data.value,
                customFormat: dateTimeFormat.appDateFormat,
                dateOnly: true,
              }) || "-"
            }}
          </span>
        </template>

        <!-- Column: Verification -->
        <template #cell(ApprovalStatus)="data">
          <template>
            <span class="text-nowrap">
              {{ ListingVerfCode.getDisplayTextKey(data.value) || "-" }}
            </span>
          </template>
        </template>

          <!-- Column: LCA Verification -->
        <template #cell(LCAApprovalStatus)="data">
          <template>
            <span class="text-nowrap">
              {{ ListingVerfCode.getDisplayTextKey(data.value) || "-" }}
            </span>
          </template>
        </template>

        <!-- Column: Status -->
        <template #cell(Status)="data">
          <template>
            <b-badge
              pill
              :variant="ListingStatusCode.getLabelClass(data.value)"
            >
              {{ ListingStatusCode.getDisplayTextKey(data.value) || "-" }}
            </b-badge>
          </template>
        </template>

        <!-- Column: Actions -->
        <template #cell(Actions)="data">
          <div class="text-nowrap">
            <feather-icon
              :id="`fish-guide-row-${data.item.Id}-detail-icon`"
              icon="EyeIcon"
              size="16"
              class="mx-1 detail-icon cursor-pointer"
              v-on:click="viewDetails(data.item.Id)"
            />
            <b-tooltip
              title="Details"
              :target="`fish-guide-row-${data.item.Id}-detail-icon`"
            />
            <feather-icon
              v-b-modal="'action-modal'"
              @click="getGuideStatusValue(data.item.Status, data.item.Id)"
              :id="`fish-guide-row-${data.item.Id}-action-icon`"
              :icon="`${
                ListingStatusCode.getDisplayTextKey(data.item.Status) ==
                'Active'
                  ? 'SlashIcon'
                  : 'CheckCircleIcon'
              }`"
              :class="`cursor-pointer ${
                ListingStatusCode.getDisplayTextKey(data.item.Status) ==
                'Active'
                  ? 'inactive-icon'
                  : 'active-icon'
              }`"
              size="16"
            />
            <b-tooltip
              :title="`${
                ListingStatusCode.getDisplayTextKey(data.item.Status) ==
                'Active'
                  ? 'Suspend'
                  : 'Active'
              }`"
              class="cursor-pointer"
              :target="`fish-guide-row-${data.item.Id}-action-icon`"
            />
          </div>
        </template>
      </data-table>
      <ConfirmModal
        id="action-modal"
        :onYesPress="guideStatusChangeHandler"
        :isLoading="isLoading"
        :isSuccess="isGuideStatusChangedSuccess"
        :onOkayPress="handleOkayPress"
        :messageText="`${
          guideStatusToSet.Status == 1
            ? 'Are you sure you want to activate guide?'
            : 'Are you sure you want to suspend guide?'
        }`"
        successText="Success !"
      />
    </b-card>
  </div>
</template>

<script>
import { BCard, BCardBody, BBadge, BLink, BTooltip } from "bootstrap-vue";
import { computed, reactive, ref } from "@vue/composition-api";

import ConfirmModal from "../../components/ConfirmModal.vue";
import DataTable from "../../components/data-table/DataTable.vue";
import UserInfo from "../../components/UserInfo.vue";
import SearchHeader from "../../components/SearchHeader.vue";
import { ListingStatusCode, SortOrder } from "../../constants";
import { ListingVerfCode } from "../../constants";
import { dateTimeFormat, convertDateTime } from "../../utils/dateFormatUtils";
import { getMediaPath, getStatusOptions } from "../../utils/commonUtils";
import { useStore } from "../../store";
import { usePaginatedRequest } from "../../hooks/usePaginatedRequest";
import { changeGuideStatus } from "../../api/api.service";
import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers";

export default {
  name: "FishGuideListing",
  components: {
    BCard,
    BCardBody,
    BBadge,
    BLink,
    BTooltip,
    DataTable,
    UserInfo,
    SearchHeader,
    ConfirmModal,
  },
  setup(props, context) {
    let fetching = computed(() => store.state.guides.fetching);
    let data = computed(() => store.state.guides.list);
    let totalGuides = computed(() => store.state.guides.totalGuides);

    let config = [
      {
        label: "ID",
        key: "Id",
      },
      {
        label: "NAME",
        key: "BusinessName",
        isSortable: true,
      },
      {
        label: "PHONE",
        key: "BusinessNumber",
        isSortable: true,
      },
      {
        label: "REGISTERED ON",
        key: "CreatedAt",
        isSortable: true,
      },
      {
        label: "VERIFICATION",
        key: "ApprovalStatus",
        isSortable: true,
      },
      {
        label: "LCBA VERIFICATION",
        key: "LCAApprovalStatus",
        isSortable: true,
      },
      {
        label: "STATUS",
        key: "Status",
        isSortable: true,
      },
      {
        label: "ACTIONS",
        key: "Actions",
      },
    ];
    const store = useStore();
    const isLoading = ref(false);
    const isGuideStatusChangedSuccess = ref(false);
    const filters = reactive({ Status: null });
    const guideStatusToSet = reactive({ Status: null, Id: null });

    const {
      page,
      pageLimit,
      searchText,
      sortOrder,
      sortColumn,
      handleSearchChange,
      handlePageClick,
      handlePageLimit,
      handleSortChange,
    } = usePaginatedRequest(
      "guides/getGuideListAction",
      () => ({
        Direction: SortOrder.DESC,
        Column: "CreatedAt",
        ...(filters.Status && { Status: filters.Status.value }),
      }),
      [filters]
    );

    function viewDetails(id) {
      context.root.$router.push(
        getRoute(APP_ROUTES.FISHING_GUIDE_DETAILS, {
          guideId: id,
        })
      );
    }

    function handleFilterChange(val) {
      filters.Status = val;
    }

    function guideStatusChangeHandler() {
      isLoading.value = true;
      changeGuideStatus(guideStatusToSet.Id, {
        Status: guideStatusToSet.Status,
      })
        .then((res) => {
          if (res.Message === "Success") {
            isGuideStatusChangedSuccess.value = true;
            isLoading.value = false;
            store.dispatch("guides/updateGuideStatus", { ...guideStatusToSet });
          }
        })
        .catch((err) => {
          console.log(err.Message);
        });
    }

    function getGuideStatusValue(statusValue, id) {
      guideStatusToSet.Id = id;
      if (statusValue == 1) {
        guideStatusToSet.Status = 2;
      } else if (statusValue == 2) {
        guideStatusToSet.Id = id;
        guideStatusToSet.Status = 1;
      }
    }

    function handleOkayPress() {
      isGuideStatusChangedSuccess.value = false;
    }

    return {
      config,
      dateTimeFormat,
      ListingVerfCode,
      ListingStatusCode,
      data,
      fetching,
      totalGuides,
      pageLimit,
      searchText,
      page,
      filters,
      guideStatusToSet,
      isLoading,
      isGuideStatusChangedSuccess,
      sortOrder,
      sortColumn,
      handleSearchChange,
      handleFilterChange,
      handlePageLimit,
      handlePageClick,
      getStatusOptions,
      changeGuideStatus,
      guideStatusChangeHandler,
      getGuideStatusValue,
      viewDetails,
      getMediaPath,
      convertDateTime,
      handleOkayPress,
      handleSortChange,
      handleSortChange,
    };
  },
};
</script>

<style scoped>
::v-deep .active-icon {
  color: #28c76f;
}

::v-deep .inactive-icon {
  color: #d88416;
}

::v-deep .detail-icon {
  color: #888888;
}
.verification-icon {
  width: 14px;
  height: 14px;
  margin-right: 10px;
}
.card-body-style {
  padding: 0 !important;
}
.table-container {
  margin-top: -10px;
}
</style>
