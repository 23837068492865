<template>
	<div>
		<div class="d-flex align-items-center">
			<p class="font-italic placeholder-text" ref="placeholderText">
				* Must use placeholder<span>
					&#10100;&#10100; user_name &#10101;&#10101;</span
				>
			</p>
			<div
				class="copy-icon cursor-pointer"
				@click="() => handleCopyPlaceholder('{{user_name}}')"
			>
				<feather-icon icon="CopyIcon" size="16" />
			</div>
		</div>

		<b-card body-class="card-body-style">
			<b-row v-if="isEditorReady">
				<b-col xsm="12" sm="12" md="12" lg="6" xl="6">
					<span class="font-weight-bolder template-name">
						{{ templateName }}
					</span>
				</b-col>
				<b-col xsm="12" sm="12" md="12" lg="6" xl="6">
					<div v-if="templateIdFromParam" class="d-flex justify-content-end">
						<Button
							:isLoading="isUpdatingTemplate"
							buttonText="Update Template"
							buttonVariant="primary"
							size="md"
							classes="btn-outline-grey ml-lg-1 ml-md-1 mb-1"
							:clickHandler="
								() => handleSaveOrSendEmailTemplate(handleUpdateTemplate)
							"
						/>
					</div>
					<div
						v-else
						class="
							d-flex
							justify-content-md-end
							justify-content-lg-end
							justify-content-sm-between
							pb-1
						"
					>
						<Button
							:isLoading="isSavingTemplate && !sentToEmailType"
							buttonText="Save Template"
							buttonVariant="outline-primary"
							size="md"
							:clickHandler="() => handleSaveOrSendEmailTemplate(handleMail)"
						/>
						<Button
							buttonText="Save & Send Email"
							buttonVariant="primary"
							size="md"
							classes="btn-outline-grey ml-lg-1 ml-md-1"
							:clickHandler="openSendEmailModal"
						/>
					</div>
				</b-col>
			</b-row>
			<div>
				<EmailEditor
					ref="emailEditor"
					:project-id="projectId"
					:appearance="appearance"
					:min-height="minHeight"
					:locale="locale"
					:tools="tools"
					:options="options"
					v-on:load="editorLoaded"
					v-on:ready="handleEditorReady"
				/>
			</div>
			<ConfirmModal
				hideActionBtns
				errorOkBtn
				id="validation-modal"
				messageText="Placeholder are not validated"
				:messageIcon="require('@/assets/images/icons/red-cross-icon.png')"
			/>
			<SendEmailModal
				:onSubmit="handleSubmitSaveAndSend"
				:isSendingEmail="isSaveAndSendTemplate"
			/>
		</b-card>
	</div>
</template>

<script>
import { EmailEditor } from 'vue-email-editor'
import { BCard, BCardBody, BRow, BCol, BSpinner } from 'bootstrap-vue'
import Button from '../../components/Button.vue'
import ConfirmModal from '../../components/ConfirmModal.vue'
import { emailConfig } from './emailConfig'
import { isPlaceholderValidate } from '../../utils/emailUtils'
import { copyToClipboard } from '@/utils/commonUtils'
import {
	saveEmailTemplate,
	getEmailTemplateById,
	editEmailTemplate,
} from '../../api/api.service'
import SendEmailModal from './SendEmailModal.vue'
import showToast from '../../hooks/useToast'
import { APP_ROUTES } from '@/helpers/routeHelpers'

export default {
	name: 'CreateEmailTemplate',
	components: {
		EmailEditor,
		Button,
		ConfirmModal,
		SendEmailModal,
		BSpinner,
		BCard,
		BCardBody,
		BRow,
		BCol,
	},
	data() {
		return {
			isUpdatingTemplate: false,
			isSavingTemplate: false,
			isSaveAndSendTemplate: false,
			loadedHtmlStr: '',
			loadedDesignObj: {},
			saveTemplate: 1,
			saveAndSendEmail: 2,
			templateName: '',
			templateId: '',
			templateIdFromParam: '',
			templateInHtmlStr: '',
			templateInDesignObj: {},
			userIds: [],
			sentToEmailType: '',
			isEditorReady: false,
			templateHTMLStr: '',
			isEditorReady: false,
			...emailConfig,
		}
	},
	methods: {
		async handleSaveOrSendEmailTemplate(cb = () => {}) {
			this.$refs.emailEditor.editor.exportHtml((data) => {
				if (!isPlaceholderValidate(data?.html)) {
					this.$bvModal.show('validation-modal')
					return
				}
				this.templateInHtmlStr = data?.html
				this.$refs.emailEditor.editor.saveDesign(async (design) => {
					this.templateInDesignObj = design
					await cb()
				})
			})
		},

		async handleMail() {
			try {
				this.isSaveAndSendTemplate = true
				this.isSavingTemplate = true
				let payload = this.getPayload()
				let res = await saveEmailTemplate(payload)

				showToast(this, {
					Title: `${
						this.sendToType
							? 'Email has been sent successfully!'
							: 'Template saved and sent successfully!'
					}`,
					Icon: 'CheckCircleIcon',
					Text: res.Message,
					Variant: 'success',
				})

				if (this.sentToEmailType) {
					this.$router.push(APP_ROUTES.EMAILS_HISTORY_LISTING)
					return
				}
				this.$router.push(APP_ROUTES.EMAIL_TEMPLATE_LISTING)
			} catch (err) {
				showToast(this, {
					Title: 'Error',
					Variant: 'danger',
					Icon: 'XCircleIcon',
					Text: err.Message,
				})
			} finally {
				this.isSavingTemplate = false
				this.isSaveAndSendTemplate = false
			}
		},

		async handleUpdateTemplate() {
			let payload = this.getPayload()
			try {
				this.isUpdatingTemplate = true
				let res = await editEmailTemplate(this.templateIdFromParam, payload)
				showToast(this, {
					Title: `Updated!`,
					Icon: 'CheckCircleIcon',
					Text: res.Message,
					Variant: 'success',
				})
				this.$router.push(APP_ROUTES.EMAIL_TEMPLATE_LISTING)
			} catch (err) {
				showToast(this, {
					Title: 'Error',
					Variant: 'danger',
					Icon: 'XCircleIcon',
					Text: err.Message,
				})
			} finally {
				this.isUpdatingTemplate = false
			}
		},

		handleSubmitSaveAndSend(data) {
			let { sendToType = '', users = [] } = data
			this.sentToEmailType = sendToType
			this.userIds = users
			this.handleSaveOrSendEmailTemplate(this.handleMail)
		},

		openSendEmailModal() {
			this.$bvModal.show('send-email-modal')
		},
		// called when the editor is created
		editorLoaded() {
			console.log('Editor loaded')
		},
		// called when the editor has finished loading
		handleEditorReady() {
			this.isEditorReady = true
		},

		hideModal() {
			this.$bvModal.hide('validation-modal')
		},

		getPayload() {
			console.log('this.templateInDesignObj', this.templateInDesignObj)
			let payload = {
				...(this.templateInDesignObj && {
					HtmlData: JSON.stringify(this.templateInDesignObj),
				}),
				...(this.templateInHtmlStr && { Html: this.templateInHtmlStr }),
				...(this.templateName && { Name: this.templateName }),
				...(this.templateId && { TemplateId: this.templateId }),
				...(this.userIds?.length > 0 && { UserIds: this.userIds }),
				...(this.sentToEmailType && {
					SendToEmailType: this.sentToEmailType,
				}),
			}
			return payload
		},

		async handleGetEmailTemplateById(id) {
			if (!id) return
			try {
				let res = await getEmailTemplateById(id)

				let templateData = res?.Template
				this.loadedDesignObj = templateData?.HtmlData
				this.$refs.emailEditor.editor.loadDesign({ ...this.loadedDesignObj })
			} catch (error) {
				showToast(this, {
					Title: 'Error',
					Variant: 'danger',
					Icon: 'XCircleIcon',
					Text: err.Message,
				})
			} finally {
			}
		},

		handleCopyPlaceholder(text) {
			let textToCopy = text
			try {
				copyToClipboard(textToCopy)
				showToast(this, {
					Title: `Copied`,
					Icon: 'CheckCircleIcon',
					Variant: 'success',
				})
			} catch {
				showToast(this, {
					Title: 'Error',
					Variant: 'danger',
					Icon: 'XCircleIcon',
					Text: err.Message,
				})
			} finally {
			}
		},
	},

	mounted() {
		let nameAsParamFromRoute = this.$route?.params?.templateName || ''
		let idAsAParamFromRoute = this.$route?.params?.templateId || ''
		this.templateIdFromParam = idAsAParamFromRoute
		this.templateName = nameAsParamFromRoute.replaceAll('-', ' ')

		this.handleGetEmailTemplateById(idAsAParamFromRoute)
	},

	beforeDestroy() {
		this.loadedHtmlStr = ''
		this.loadedDesignObj = {}
		this.templateName = ''
		this.templateId = ''
		this.templateIdFromParam = ''
		this.templateInHtmlStr = ''
		this.templateInDesignObj = {}
		this.userIds = []
		this.sentToEmailType = ''
		this.isEditorReady = false
		this.templateHTMLStr = ''
	},
}
</script>


<style >
.unlayer-editor {
	height: 75vh !important;
}
.blockbuilder-preferences {
	display: none !important;
}
.template-name {
	color: #000000;
}
.placeholder-text {
	background-color: #e5e4e2;
	width: fit-content;
	margin: 0 0 5px 0;
	padding: 0 3px 0 3px;
	font-weight: bolder;
}
div#editor-1 iframe {
	min-width: 100% !important;
}
.copy-icon {
	padding-bottom: 0.5rem;
	padding-left: 0.5rem;
}
</style>


