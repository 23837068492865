<template>
	<div>
		<b-card body-class="card-body-style">
			<search-header
				:searchText="searchText"
				:onSearchChange="handleSearchChange"
				:pageLimitValue="pageLimit"
				:onPageLimitChange="handlePageLimit"
				:statusOptions="getStatusOptions(reportStatus)"
				:filterValue="filters.Status"
				:onFilterChange="handleFilterChange"
			></search-header>
			<data-table
				:perPageLimit="pageLimit"
				entriesName="Reports"
				:perPage="data.length"
				:isLoading="fetching"
				:showPagination="true"
				:config="config"
				:data="data"
				:selectedPageValue="page"
				:onHandlePageChange="handlePageClick"
				:totalRecords="totalReports"
				:onSortClick="handleSortChange"
			>
				<template #cell(Id)="data">
					<span class="font-weight-bolder">
						{{ data.index + 1 }}
					</span>
				</template>

				<template #cell(ReportedBy)="data">
					<span class="text-nowrap">
						<user-info
							iconSize="md"
							:fullName="data.item.User.FullName"
							:subText="data.item.User.PhoneNumber"
						/>
					</span>
					<!-- :avatarImage="getMediaPath(data.item.User.Media)" -->
				</template>

				<template #cell(IssuedIn)="data">
					<span class="font-weight-bolder text-nowrap">
						{{
							ReportEntityType.getDisplayTextKey(
								data.item.EntityType
							)
						}}</span
					>
					<br />
					<!-- <span style="color: #888888">{{
						data.item[findByReportEntity[data.item.EntityType]]
							.Name ||
						data.item[findByReportEntity[data.item.EntityType]]
							.Title ||
						'-'
					}}</span> -->
				</template>

				<template #cell(UnknownData)="data">
					<span class="text-nowrap">
						<user-info
							iconSize="md"
							:fullName="getValue(data, 'FullName')"
							:subText="getValue(data, 'PhoneNumber')"
							:avatarImage="
								() => getMediaPath(getValue(data, 'Media'))
							"
						/>
					</span>
				</template>

				<template #cell(CreatedAt)="data">
					<span class="text-nowrap">
						{{
							convertDateTime({
								date: data.value,
								customFormat: dateTimeFormat.appDateFormat,
								dateOnly: true,
							})
						}}
					</span>
				</template>

				<template #cell(ReportType)="data">
					<span class="font-weight-bolder text-nowrap">
						{{ reportType.getDisplayTextKey(data.value) }}
					</span>
				</template>

				<template #cell(Status)="data">
					<template>
						<b-badge
							pill
							:variant="reportStatus.getLabelClass(data.value)"
						>
							{{ reportStatus.getDisplayTextKey(data.value) }}
						</b-badge>
					</template>
				</template>

				<!-- Column: Actions -->
				<template #cell(Actions)="data">
					<div class="text-nowrap">
						<feather-icon
							:id="`moderation-list-row-${data.item.Id}-detail-icon`"
							icon="EyeIcon"
							size="16"
							class="mx-1 detail-icon cursor-pointer"
							v-on:click="viewDetails(data.item.Id)"
						/>
						<b-tooltip
							title="Detail"
							:target="`moderation-list-row-${data.item.Id}-detail-icon`"
						/>
						<img
							:id="`moderation-delete-row-${data.item.Id}-action-icon`"
							class="delete-icon cursor-pointer"
							:src="deleteIcon"
							v-b-modal="`action-${data.item.Id}-modal`"
						/>
						<b-tooltip
							title="Delete"
							:target="`moderation-delete-row-${data.item.Id}-action-icon`"
						/>
					</div>
					<ConfirmModal
						:isLoading="isLoading"
						:isSuccess="isModerationDeleteSuccess"
						:onOkayPress="handleOkayPress"
						successText="Success !"
						:onYesPress="() => deleteModeration(data.item.Id)"
						:id="`action-${data.item.Id}-modal`"
						messageText="Are you sure you want to delete this report?"
						:messageIcon="
							require('@/assets/images/icons/restrict-icon.svg')
						"
					/>
				</template>
			</data-table>
		</b-card>
	</div>
</template>

<script>
import { BCard, BCardBody, BBadge, BLink, BTooltip } from 'bootstrap-vue'
import { computed, reactive, ref, toRefs } from '@vue/composition-api'

import DataTable from '../../components/data-table/DataTable.vue'
import UserInfo from '../../components/UserInfo.vue'
import SearchHeader from '../../components/SearchHeader.vue'
import ConfirmModal from '../../components/ConfirmModal.vue'

import CrownIcon from '../../assets/images/icons/crown-icon.svg'
import DeleteIcon from '../../assets/images/icons/delete.svg'

import { APP_ROUTES, getRoute } from '../../helpers/routeHelpers'
import { dateTimeFormat, convertDateTime } from '../../utils/dateFormatUtils'
import {
	getMediaPath,
	getStatusOptions,
	swapObjectKeyValue,
	showModal,
} from '../../utils/commonUtils'
import { useStore } from '../../store'
import { usePaginatedRequest } from '../../hooks/usePaginatedRequest'
import {
	ReportStatus,
	ReportType,
	SortOrder,
	ReportEntityType,
} from '../../constants'

export default {
	name: 'ModerationMarketplace',
	components: {
		DataTable,
		UserInfo,
		BCard,
		BCardBody,
		BBadge,
		BLink,
		BTooltip,
		SearchHeader,
		ConfirmModal,
	},
	setup(props, context) {
		const store = useStore()

		const findByReportEntity = ref(swapObjectKeyValue(ReportEntityType))

		console.log(findByReportEntity)

		const filters = reactive({
			Status: null,
		})

		const state = reactive({
			isLoading: false,
			isModerationDeleteSuccess: false,
		})

		const config = [
			{
				label: 'NO',
				key: 'Id',
			},
			{
				label: 'REPORTED BY',
				key: 'ReportedBy',
			},
			{
				label: 'ISSUE IN',
				key: 'IssuedIn',
			},
			{
				label: 'REPORTED PERSON',
				key: 'UnknownData',
			},
			{
				label: 'DATE',
				key: 'CreatedAt',
				isSortable: true,
			},
			{
				label: 'REPORT TYPE',
				key: 'ReportType',
				isSortable: true,
			},
			{
				label: 'STATUS',
				key: 'Status',
				isSortable: true,
			},
			{
				label: 'ACTIONS',
				key: 'Actions',
			},
		]

		let fetching = computed(() => store.state.moderation.fetching)
		let data =
			computed(() => store.getters['moderation/getModerationList']) || []
		let totalReports = computed(() => store.state.moderation.totalReports)

		const {
			page,
			searchText,
			pageLimit,
			sortOrder,
			sortColumn,
			handleSearchChange,
			handlePageLimit,
			handlePageClick,
			handleSortChange,
		} = usePaginatedRequest(
			'moderation/getModerationListAction',
			() => ({
				Direction: SortOrder.DESC,
				Column: 'CreatedAt',
				ReportListingType: 'GroupsPosts',
				...(filters.Status && { Status: filters.Status.value }),
			}),
			[filters]
		)

		function viewDetails(id) {
			context.root.$router.push(
				getRoute(APP_ROUTES.MODERATION_GROUP_POSTS_DETAILS, {
					moderationId: id,
				})
			)
		}

		function handleFilterChange(val) {
			handlePageClick(1)
			filters.Status = val
		}

		function deleteModeration(id) {
			state.isLoading = true
			store
				.dispatch('moderation/deleteModeration', {
					id,
				})
				.then((res) => {
					state.isModerationDeleteSuccess = true
				})
				.finally(() => {
					state.isLoading = false
				})
		}

		function handleOkayPress() {
			state.isModerationDeleteSuccess = false
		}

		function getValue(data = {}, key = '') {
			if (
				data?.item[findByReportEntity.value[data?.item.EntityType]]
					?.User
			) {
				return (
					data?.item[findByReportEntity.value[data?.item.EntityType]]
						?.User[key] || '-'
				)
			}
		}

		return {
			...toRefs(state),
			crownIcon: CrownIcon,
			deleteIcon: DeleteIcon,
			config,
			searchText,
			page,
			pageLimit,
			fetching,
			data,
			filters,
			totalReports,
			getMediaPath,
			getStatusOptions,
			dateTimeFormat,
			convertDateTime,
			handlePageLimit,
			handlePageClick,
			handleFilterChange,
			handleSearchChange,
			reportType: ReportType,
			reportStatus: ReportStatus,
			handleFilterChange,
			viewDetails,
			deleteModeration,
			handleOkayPress,
			sortOrder,
			sortColumn,
			handleSortChange,
			findByReportEntity,
			getValue,
			ReportEntityType,
		}
	},
}
</script>

<style scoped>
::v-deep .detail-icon {
	color: #888888;
}

.card-body-style {
	padding: 0 !important;
}

.delete-icon {
	width: 14px;
	height: 14px;
}
</style>
