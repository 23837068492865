import { injectBaseConstantMethods } from "./BaseConstants";

export const ReportType = {
  Scam: 1,
  HateSpeech: 2,
  Violence: 3,
  SaleOfIllegalGoods: 4,
  FalseInformation: 5,
  Other: 6,
};

const displayTextKeys = {
  [ReportType.Scam]: "Scam",
  [ReportType.HateSpeech]: "Hate Speech",
  [ReportType.Violence]: "Violence",
  [ReportType.SaleOfIllegalGoods]: "Sale Of Illegal Goods",
  [ReportType.FalseInformation]: "False Information",
  [ReportType.Other]: "Other",
};

export default injectBaseConstantMethods(ReportType, displayTextKeys);
