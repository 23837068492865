<template>
	<b-card class="body-container">
		<div class="loader-container" v-if="isLoading">
			<b-spinner class="loader" variant="primary"></b-spinner>
		</div>
		<div class="loader-container" v-if="!isLoading && !fishSpot">
			<p class="msg">Fish Spot Not Found</p>
		</div>
		<b-container v-if="fishSpot && !isLoading">
			<div>
				<b-row>
					<b-col cols="12" md="6" lg="4" sm="12">
						<LabelText
							label="Customer"
							:text="(fishSpot && fishSpot.User.FullName) || '-'"
							showRightSection
							textClasses="font-weight-bolder mt-0"
						>
							<b-avatar
								size="md"
								:src="getMediaPath(fishSpot.User)"
								:text="
									fishSpot.User && fishSpot.User.FullName[0]
								"
								class="avatar"
							/>
						</LabelText>

						<div class="neg-margin">
							<LabelText
								label="Ratings"
								text=""
								showRightSection
								textClasses="font-weight-bolder"
							>
								<Ratings :rateInNumber="fishSpot.Rating" />
							</LabelText>
						</div>
						<div class="neg-margin">
							<LabelText
								label="Price"
								:text="`$` + fishSpot.SellPrice"
								textClasses="font-weight-bolder"
							/>
						</div>
					</b-col>
					<b-col cols="12" md="6" lg="4" sm="12">
						<div class="mb-2">
							<p class="add-notes-title top-add-notes">
								Additional Notes:
							</p>
							<p class="font-weight-bolder review-height">
								{{ fishSpot.AdditionalNotes || '-' }}
							</p>
						</div>
					</b-col>
					<b-col cols="12" md="12" lg="4" sm="12">
						<Button
							buttonText="Delete Spot"
							buttonVariant="danger"
							classes="mb-1 w-50"
							:clickHandler="handleDeletePress"
						/>
					</b-col>
				</b-row>
				<hr />

				<div v-if="fishSpot && fishSpot.CatchLog.length">
					<div
						v-for="(item, index) in fishSpot.CatchLog"
						:key="index"
						class="mb-2"
					>
						<b-row>
							<h4
								class="
									text-secondary
									pl-1
									mb-3
									font-weight-bolder
								"
							>
								{{ formatCatchNumber(index + 1) }} Catch Details
							</h4>
						</b-row>
						<b-row>
							<b-col cols="12" md="6" lg="4" sm="12">
								<LabelText
									label="Date"
									:text="catchDate(item.createdAt)"
									textClasses="font-weight-bolder"
								/>
								<LabelText
									label="Fish Specie"
									:text="item.FishSpecie.Name"
									textClasses="font-weight-bolder"
								/>
								<LabelText
									label="Location"
									:text="fishSpot.Address"
									textClasses="font-weight-bolder"
								/>

								<LabelText
									label="Time"
									:text="formatTime(fishSpot.CreatedAt)"
									textClasses="font-weight-bolder"
								/>
							</b-col>
							<b-col cols="12" md="6" lg="4" sm="12">
								<LabelText
									label="GPS Coordinates"
									:text="`${fishSpot.Location.coordinates[0]}-${fishSpot.Location.coordinates[1]}`"
									textClasses="font-weight-bolder"
								/>
								<LabelText
									label="Weather Details"
									:text="`${item.Meta.OutLook}, ${item.Meta.Temperature}, ${item.Meta.WindSpeed} winds`"
									textClasses="font-weight-bolder"
								/>
								<LabelText
									label="Gear Used"
									:text="getGearsUsed(item.Gears)"
									textClasses="font-weight-bolder"
								/>
							</b-col>
							<b-col cols="12" md="12" lg="4" sm="12">
								<p class="add-notes-title">Additional Notes:</p>
								<p class="font-weight-bolder additional-notes">
									{{ item.AdditionalNotes || '-' }}
								</p>
							</b-col>
						</b-row>

						<b-row>
							<div class="mt-1 ml-1">
								<ImageGallery :galleryConfig="item.Media" />
							</div>
						</b-row>
						<hr />
					</div>
				</div>
			</div>
		</b-container>
		<ConfirmModal
			id="fish-spot-detail-modal"
			:isLoading="isProcessing"
			:isSuccess="deleteSuccess"
			successText="Gear Deleted Successfully !"
			:onOkayPress="handleOkayPress"
			:onYesPress="handleYesPress"
			messageText="Are you sure you want to delete this gear?"
		/>
	</b-card>
</template>

<script>
import {
	BRow,
	BCol,
	BCard,
	BCardBody,
	BTableLite,
	BCardText,
	BButton,
	BAlert,
	BLink,
	BAvatar,
	BSpinner,
	VBModal,
	BContainer,
} from 'bootstrap-vue'
import moment from 'moment'

import LabelText from '../../components/LabelText.vue'
import Ratings from '../../components/Ratings.vue'
import Button from '../../components/Button.vue'
import ImageGallery from '../../components/ImageGallery.vue'
import ConfirmModal from '../../components/ConfirmModal.vue'
import { APP_ROUTES } from '../../helpers/routeHelpers'
import { getFishSpotDetails, deleteFishSpot } from '../../api/api.service'
import { getMediaPath } from '../../utils/mediaUtils'

export default {
	name: 'FishingGearDetails',
	components: {
		BRow,
		BCol,
		BCard,
		BCardBody,
		BTableLite,
		BCardText,
		BButton,
		BAlert,
		BLink,
		BSpinner,
		BContainer,
		BAvatar,
		Ratings,
		Button,
		ImageGallery,
		LabelText,
		ConfirmModal,
		VBModal,
	},
	created() {
		this.isLoading = true
		getFishSpotDetails(this.$route.params.fishSpotId)
			.then((res) => {
				this.fishSpot = res.Location
			})
			.catch((err) => {})
			.finally(() => {
				this.isLoading = false
			})
	},
	methods: {
		formatCatchNumber(val) {
			return moment.localeData().ordinal(val)
		},
		formatTime(val) {
			return moment(val).format('HH:mm')
		},
		catchDate(date) {
			return moment(date).format('LL')
		},
		getMediaPath,
		handleYesPress() {
			this.isProcessing = true

			deleteFishSpot(Number(this.$route.params.fishSpotId))
				.then((res) => {
					this.deleteSuccess = true
				})
				.finally(() => {
					this.isProcessing = false
				})
		},
		handleOkayPress() {
			this.deleteSuccess = false
			this.$router.replace(APP_ROUTES.FISH_SPOT)
		},
		handleDeletePress() {
			this.$bvModal.show('fish-spot-detail-modal')
		},
		getGearsUsed(gearsList) {
			if (!Boolean(gearsList.length)) {
				return '-'
			}

			let str = ''

			gearsList.forEach((item) => {
				str = str + item.Name + ' '
			})

			return str
		},
	},
	data() {
		return {
			fishSpot: null,
			isLoading: false,
			isLoading: false,
			isProcessing: false,
			deleteSuccess: false,
		}
	},
}
</script>

<style lang="scss">
@import '../~@core/scss/base/pages/app-ecommerce.scss';
</style>

<style scoped>
.review-height {
	line-height: 25px;
}
.add-notes-title {
	color: #888888;
}
.top-add-notes {
	margin-top: 5px;
}
.add-notes-title + p {
	color: black;
}
.loader-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 60vh;
}
.loader {
	margin-bottom: 8px;
	width: 4rem;
	height: 4rem;
}

.msg {
	font-size: 20px;
	font-weight: 500;
}

.avatar {
	border: 1px solid white;
}
.body-container {
	margin-top: -10px;
}
.neg-margin {
	margin-top: -12px;
}
</style>
