import { getReviewsList } from "../../api/api.service";

export default {
  namespaced: true,

  // State
  state: {
    fetching: false,
    reviewsList: [],
    totalReviews: 0,
  },

  // Getter:
  getters: {
    getReviewById: (state) => (id) => {
      return state.reviewsList.filter((review) => review.Id === id);
    },
  },

  // Mutations:
  mutations: {
    reviewsRequest: (state) => {
      state.fetching = true;
      state.reviewsList = [];
    },
    reviewsSuccess: (state, action) => {
      state.fetching = false;
      state.reviewsList = action.payload;
      state.totalReviews = action.totalReviews;
    },
    reviewsFail: (state, payload) => {
      state.fetching = false;
    },
  },
  // Actions:
  actions: {
    // For fetching all reviews
    async getReviewsListAction({ commit }, params = {}) {
      const { signal, ...queryParams } = params;
      commit("reviewsRequest");
      try {
        let reviewsResponse = await getReviewsList(queryParams, signal);

        commit("reviewsSuccess", {
          payload: reviewsResponse.Reviews,
          totalReviews: reviewsResponse.TotalReviews,
        });
      } catch (err) {
        if (err.name !== "AbortError") {
          commit("reviewsFail");
        }
      }
    },
  },
};
