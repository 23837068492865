<template>
	<div class="align-wrapper mb-2" :class="[containerClasses]">
		<p class="label" :class="[labelClasses]">{{ label }}: &nbsp;</p>
		<div v-if="showRightSection">
			<div class="">
				<slot />
			</div>
		</div>
		<p class="text font-weight-normal text-margin" :class="[textClasses]">
			{{ text }}
		</p>
	</div>
</template>

<script>
import { BAvatar } from 'bootstrap-vue'
export default {
	name: 'LabelText',
	components: {
		BAvatar,
	},
	props: {
		label: String,
		text: String,
		containerClasses: String,
		textClasses: String,
		showRightSection: Boolean,
		labelClasses: String,
	},
}
</script>

<style scoped>
.align-wrapper {
	display: flex;
	align-items: center;
}

.mb-8 {
	margin-bottom: 8px !important;
}

.align-wrapper p {
	margin-bottom: 0px;
	font-size: 14px;
	color: #000000;
}
.align-wrapper p.label {
	font-size: 14px;
	color: #888888;
}

.font-12 {
	font-size: 12px !important;
}
.text-margin {
	margin-bottom: 3px !important;
}
</style>
