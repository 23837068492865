<template>
	<b-card>
		<b-container class="bv-example-row">
			<Title text="Order Details" />
			<b-row class="mt-2">
				<b-col md="6" lg="6" sm="12">
					<LabelText
						label="Customer"
						:text="orderDetails.User.FullName || '-'"
						showRightSection
						textClasses="font-weight-bolder"
					>
						<b-avatar
							size="md"
							:src="getMediaPath(orderDetails.User.Media)"
							:text="
								!getMediaPath(orderDetails.User.Media) &&
								orderDetails.User.FullName &&
								orderDetails.User.FullName[0]
							"
						/>
					</LabelText>
					<LabelText
						label="No of times Purchased"
						:text="purchaseCount || '-'"
						textClasses="font-weight-bolder"
					/>
					<LabelText
						label="Voucher Status"
						:text="
							VoucherStatus.getDisplayTextKey(
								orderDetails.Voucher.Status
							) || '-'
						"
						textClasses="font-weight-bolder"
					/>
					<LabelText
						label="Purchase Date"
						:text="
							convertDateTime({
								date: orderDetails.PurchaseDate,
								customFormat: dateTimeFormat.appDateFormat,
								dateOnly: true,
							}) || '-'
						"
						textClasses="font-weight-bolder"
					/>

					<LabelText
						label="Purchase Time"
						:text="
							convertTime({
								tz: 'LOCAL_TIME',
								time: orderDetails.CreatedAt,
								format: dateTimeFormat.appTimeFormat,
							})
						"
						textClasses="font-weight-bolder"
					/>
					<LabelText
						label="Location"
						:text="orderDetails.Voucher.Location.Address || '-'"
						textClasses="font-weight-bolder"
					/>
					<LabelText
						label="GPS Coordinates"
						:text="`${orderDetails.Voucher.Location.Location.coordinates[0]} - ${orderDetails.Voucher.Location.Location.coordinates[1]}`"
						textClasses="font-weight-bolder"
					/>
				</b-col>
				<b-col md="6" lg="4" sm="12">
					<div class="d-flex flex-row align-items-center">
						<p class="mr-2">Seller :</p>
						<user-info
							:subText="
								orderDetails.Voucher.Location.User
									.PhoneNumber || '-'
							"
							:fullName="
								orderDetails.Voucher.Location.User.FullName ||
								'-'
							"
							:avatarImage="
								getMediaPath(
									orderDetails.Voucher.Location.User.Media
								) || null
							"
							iconSize="md"
							containerClasses="mb-1"
						/>
					</div>

					<LabelText
						label="Gifted"
						:text="
							(orderDetails.Voucher.Type == VoucherType.Gift
								? 'Yes'
								: 'No') || '-'
						"
						textClasses="font-weight-bolder"
					/>
					<div>
						<LabelText
							label="Price"
							:text="` $ ${orderDetails.NetAmount || '0'} + $${
								orderDetails.FishFinFee || '0'
							} (fishfin fee) + ${
								orderDetails.GatewayFee
							} (payment processing fee)`"
							textClasses="font-weight-bolder"
						/>
					</div>
					<p>Additional Notes:</p>
					<p class="additional-notes">
						{{
							orderDetails.Voucher.Location.AdditionalNotes || '-'
						}}
					</p>
				</b-col>
			</b-row>
			<hr />

			<Title
				v-if="!orderDetails.Voucher.Location.CatchLog.length"
				text="No Catch Logs"
				classes="mt-3"
			/>
			<div
				v-for="(item, index) in orderDetails.Voucher.Location.CatchLog"
				:key="index"
			>
				<Title
					:text="`${formatCatchNumber(index + 1)} Catch Details`"
					classes="mt-3"
				/>
				<b-row class="mt-3">
					<b-col md="4" lg="4" sm="12">
						<LabelText
							label="Date"
							:text="
								convertDateTime({
									date: item.LogDate,
									customFormat: dateTimeFormat.appDateFormat,
									dateOnly: true,
								})
							"
							textClasses="font-weight-bolder"
						/>
						<LabelText
							label="Fish Specie"
							:text="item.FishSpecie.Name"
							textClasses="font-weight-bolder"
						/>
						<LabelText
							label="Location"
							:text="orderDetails.Voucher.Location.Country || '-'"
							textClasses="font-weight-bolder"
						/>

						<LabelText
							label="Time"
							:text="
								convertTime({
									tz: 'LOCAL_TIME',
									time: item.FishSpecie.CreatedAt,
									format: dateTimeFormat.appTimeFormat,
								})
							"
							textClasses="font-weight-bolder"
						/>
					</b-col>

					<b-col md="4" lg="5" sm="12">
						<LabelText
							label="GPS Coordinates"
							text="n/a"
							textClasses="font-weight-bolder"
						/>

						<LabelText
							label="Weather Details"
							:text="Object.values(item.Meta).join(',' + ' ')"
							textClasses="font-weight-bolder"
						/>

						<LabelText
							label="Gear Used"
							:text="
								ConvertArrDataToStr(item.Gears, ',', 'Name') ||
								'-'
							"
							textClasses="font-weight-bolder"
						/>

						<LabelText
							label="Water Condition"
							text="n/a"
							textClasses="font-weight-bolder"
						/>
					</b-col>

					<b-col md="4" lg="3" sm="12" class="pt-1">
						<p>Additional Notes:</p>
						<p class="additional-notes">
							{{ item.AdditionalNotes || '-' }}
						</p>
					</b-col>
				</b-row>

				<b-row class="mb-2">
					<img
						class="mt-2 gallery-img ml-1"
						v-for="(obj, index) in item.Media"
						:key="index"
						:src="getMediaPath(obj)"
					/>
					<!-- <img
            class="mt-2 gallery-img ml-1"
            :src="require('@/assets/images/gallery/fish-up.png')"
          />
          <img
            class="mt-2 gallery-img ml-1"
            :src="require('@/assets/images/gallery/fish-left.png')"
          />
          <img
            class="mt-2 gallery-img ml-1"
            :src="require('@/assets/images/gallery/fish.png')"
          /> -->
				</b-row>

				<hr v-if="index === 0" />
			</div>
		</b-container>
	</b-card>
</template>

<script>
import {
	BRow,
	BCol,
	BCard,
	BCardBody,
	BTableLite,
	BCardText,
	BButton,
	BAlert,
	BLink,
	BAvatar,
	BContainer,
} from 'bootstrap-vue'
import moment from 'moment'

import LabelText from '../../components/LabelText.vue'
import Ratings from '../../components/Ratings.vue'
import Title from '../../components/Title.vue'
import UserInfo from '../../components/UserInfo.vue'
import { getOrderDetailsById } from '../../api/api.service'
import { getRouteId } from '../../utils/getRouteId'
import { getMediaPath } from '../../utils/commonUtils'
import { ConvertArrDataToStr } from '../../utils/ArrToStr'
import {
	dateTimeFormat,
	convertDateTime,
	convertTime,
} from '../../utils/dateFormatUtils'
import { VoucherStatus, VoucherType } from '../../constants'

export default {
	name: 'LocationOrderDetails',
	components: {
		BRow,
		BCol,
		BCard,
		BCardBody,
		BTableLite,
		BCardText,
		BButton,
		BAlert,
		BLink,
		BContainer,
		BAvatar,
		UserInfo,
		LabelText,
		Ratings,
		Title,
	},
	data() {
		return {
			orderDetails: {},
			purchaseCount: null,
			idParam: '',
			getRouteId,
			getMediaPath,
			ConvertArrDataToStr,
			dateTimeFormat,
			convertDateTime,
			convertTime,
			VoucherStatus,
			VoucherType,
		}
	},
	created() {
		this.idParam = this.getRouteId('orderId')
	},
	beforeMount() {
		getOrderDetailsById(this.idParam)
			.then((res) => {
				this.orderDetails = res.Transaction
				this.purchaseCount = res.PurchaseCount
			})
			.catch((err) => {})
	},
	methods: {
		formatCatchNumber(val) {
			return moment.localeData().ordinal(val)
		},
	},
}
</script>

<style lang="scss">
@import '../~@core/scss/base/pages/app-ecommerce.scss';
</style>

<style scoped>
.review-height {
	line-height: 25px;
}
.review-title {
	color: #888888;
}

.additional-notes {
	font-weight: 700;
	color: #000;
}

.gallery-img {
	width: 127px;
	height: 155px;
}
</style>
