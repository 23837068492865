<template>
  <div>
    <b-container fluid>
      <b-row class="align-items-center">
        <b-col cols="12" sm="12" md="12" lg="12" class="pl-0">
          <Nav :menuItems="menuData"
        /></b-col>
      </b-row>
    </b-container>

    <router-view />
  </div>
</template>

<script>
import { BContainer, BRow, BCol } from "bootstrap-vue";

import Nav from "../../../components/Nav.vue";
import { APP_ROUTES } from "../../../helpers/routeHelpers";

export default {
  name: "FishingGears",
  components: {
    Nav,
    BContainer,
    BRow,
    BCol,
  },
  data() {
    return {
      menuData: [
        {
          menuName: "Fishing Gear Categories",
          menuRoute: APP_ROUTES.SETTINGS_FISH_GEAR_CATEGORIES,
        },
        {
          menuName: "Fishing Gears",
          menuRoute: APP_ROUTES.SETTINGS_FISH_GEAR_LIST,
        },
      ],
    };
  },
};
</script>
