<template>
	<div class="table-container">
		<b-card body-class="card-body-style">
			<search-header
				searchInputClasses="w-50"
				:showStatusFilter="false"
				:onSearchChange="handleSearchChange"
				:searchText="searchText"
				:pageLimitValue="pageLimit"
				:onPageLimitChange="handlePageLimitChange"
			></search-header>
			<data-table
				:perPageLimit="pageLimit"
				entriesName="Groups"
				:showPagination="true"
				:config="config"
				:data="data"
				:selectedPageValue="page"
				:perPage="data.length"
				:onHandlePageChange="handlePageChange"
				:onSortClick="handleSortChange"
				:totalRecords="total"
				:isLoading="isFetching"
			>
				<!-- <template #cell(No)="data">
					<span class="font-weight-bolder">
						{{ data.index + 1 }}
					</span>
				</template> -->

				<template #cell(Id)="data">
					<span class="font-weight-bolder">
						{{ data.value }}
					</span>
				</template>

				<template #cell(Name)="data">
					<span class="font-weight-bolder">
						{{ data.item.Name || '-' }}
					</span>
				</template>

				<template #cell(Visibility)="data">
					<span class="font-weight-bold">
						{{
							GroupsVisibility.getDisplayTextKey(
								data.item.Visibility
							)
						}}
					</span>
				</template>

				<template #cell(FullName)="data">
					<user-info
						iconSize="md"
						:fullName="data.item.AdminUser.FullName || '-'"
						:avatarImage="getMediaPath(data.item.AdminUser.Media)"
						nameClasses="font-weight-bolder"
					/>
				</template>

				<template #cell(CreatedAt)="data">
					<span class="purchase-date">
						{{
							convertDateTime({
								date: data.item.CreatedAt,
								customFormat: dateTimeFormat.appDateFormat,
								dateOnly: true,
							})
						}}
					</span>
				</template>
				<template #cell(PostsCount)="data">
					<span class="time"> {{ data.item.PostsCount }} </span>
				</template>

				<template #cell(MembersCount)="data">
					<span class="time">
						{{ data.item.MembersCount }}
					</span>
				</template>

				<template #cell(Actions)="data">
					<div class="text-nowrap">
						<feather-icon
							:id="`${data.item.Id}-detail-icon`"
							icon="EyeIcon"
							size="16"
							class="mx-1 detail-icon cursor-pointer"
							v-on:click="viewDetails(data.item.Id)"
						/>
						<b-tooltip
							title="Detail"
							:target="`${data.item.Id}-detail-icon`"
						/>

						<img
							v-b-modal="'delete-group-modal'"
							@click="handleDeleteClick(data.item.Id)"
							:id="`${data.item.Id}-action-icon`"
							class="delete-icon"
							:src="deleteIcon"
						/>

						<b-tooltip
							title="Delete"
							class="cursor-pointer"
							:target="`${data.item.Id}-action-icon`"
						/>
					</div>
				</template>
			</data-table>
		</b-card>
		<ConfirmModal
			id="delete-group-modal"
			:isLoading="isProcessing"
			:isSuccess="deleteSuccess"
			successText="Group deleted successfully!"
			:onOkayPress="handleOkayPress"
			:onYesPress="handleYesPress"
			messageText="Are you sure you want to delete this group?"
		/>
	</div>
</template>

<script>
import { BCard, BCardBody, BBadge, BLink, BTooltip } from 'bootstrap-vue'
import { reactive, ref } from '@vue/composition-api'

import DataTable from '../../components/data-table/DataTable.vue'
import UserInfo from '../../components/UserInfo.vue'
import SearchHeader from '../../components/SearchHeader.vue'
import ConfirmModal from '../../components/ConfirmModal.vue'
import {
	FishSpotLocationStatus,
	SortOrder,
	GroupsVisibility,
} from '../../constants'
import { APP_ROUTES, getRoute } from '../../helpers/routeHelpers'
import { getGroupsListing, deleteGroup } from '../../api/api.service'
import { getMediaPath } from '../../utils/mediaUtils'
import { useLocalPagination } from '../../hooks/useLocalPagination'
import { dateTimeFormat, convertDateTime } from '../../utils/dateFormatUtils'
import DeleteIcon from '../../assets/images/icons/delete.svg'

const config = [
	// 	{
	// 	label: 'No',
	// 	key: 'No',
	// },
	{
		label: 'ID',
		key: 'Id',
	},
	{
		label: 'GROUP NAME',
		key: 'Name',
	},
	{
		label: 'GROUP TYPE',
		key: 'Visibility',
	},
	{
		label: 'ADMIN',
		key: 'FullName',
	},
	{
		label: 'DATE CREATED',
		key: 'CreatedAt',
		isSortable: true,
	},
	{
		label: 'TOTAL POSTS',
		key: 'PostsCount',
		// isSortable: true,
	},
	{
		label: 'TOTAL MEMBERS',
		key: 'MembersCount',
		// isSortable: true,
	},
	{
		label: 'ACTIONS',
		key: 'Actions',
	},
].map((item) => {
	return { ...item, tdClass: 'table-row' }
})

export default {
	name: 'GroupsListing',
	components: {
		DataTable,
		UserInfo,
		BCard,
		BCardBody,
		BBadge,
		BLink,
		BTooltip,
		SearchHeader,
		ConfirmModal,
	},
	setup(props, context) {
		const filters = reactive({ Status: FishSpotLocationStatus.SOLD })
		const selectedId = ref(null)
		const isProcessing = ref(false)
		const deleteSuccess = ref(false)

		const {
			data,
			isFetching,
			total,
			page,
			searchText,
			pageLimit,
			sortOrder,
			sortColumn,
			handleSearchChange,
			handlePageClick,
			handlePageLimit,
			handleSortChange,
		} = useLocalPagination(
			getGroupsListing,
			() => ({
				Direction: SortOrder.DESC,
				Column: 'CreatedAt',
				...(filters.Status && { Status: filters.Status }),
			}),
			[filters],
			'Q',
			'Groups',
			'TotalGroups'
		)

		const spotLocationStatus = Object.values(FishSpotLocationStatus).map(
			(val) => ({
				label: FishSpotLocationStatus.getDisplayTextKey(val),
				value: FishSpotLocationStatus.getDisplayTextKey(val),
				id: val,
			})
		)

		const handlePageChange = (pageNumber) => {
			handlePageClick(pageNumber)
		}

		const handlePageLimitChange = (val) => {
			page.value = 1

			handlePageLimit(val)
		}

		const handleStatusChange = (item) => {
			page.value = 1
			if (!item?.id) {
				filters.Status = null
				return
			}
			filters.Status = item.id
		}

		const handleYesPress = () => {
			isProcessing.value = true

			deleteGroup(selectedId.value)
				.then(() => {
					data.value = data.value.filter(
						(item) => item.Id !== selectedId.value
					)
					deleteSuccess.value = true
				})
				.finally(() => {
					isProcessing.value = false
				})
		}

		const handleOkayPress = () => {
			deleteSuccess.value = false
			selectedId.value = null
		}

		const handleDeleteClick = (id) => {
			selectedId.value = id
		}

		const viewDetails = (id) => {
			context.root.$router.push(
				getRoute(APP_ROUTES.GROUP_DETAILS, { groupId: id })
			)
		}

		return {
			deleteIcon: DeleteIcon,
			config,
			data,
			total,
			page,
			pageLimit,
			isFetching,
			sortOrder,
			sortColumn,
			searchText,
			FishSpotLocationStatus,
			spotLocationStatus,
			filters,
			isProcessing,
			deleteSuccess,
			handleOkayPress,
			handleDeleteClick,
			handleYesPress,
			handleSearchChange,
			handlePageChange,
			handlePageLimit,
			handleSortChange,
			handleStatusChange,
			getMediaPath,
			viewDetails,
			handlePageLimitChange,
			dateTimeFormat,
			convertDateTime,
			GroupsVisibility,
		}
	},
}
</script>

<style scoped>
::v-deep .active-icon {
	color: #28c76f;
}

::v-deep .inactive-icon {
	color: #d88416;
}

::v-deep .detail-icon {
	color: #888888;
}

.delete-icon {
	width: 14px;
	height: 14px;
	cursor: pointer;
}

.time {
	font-weight: 500;
}

.card-body-style {
	padding: 0 !important;
}
::v-deep .table-row {
	font-weight: bold;
}

::v-deep .address-cordinates {
	color: #888888;
	font-size: 12px;
}
.table-container {
	margin-top: -10px;
}
</style>
