import { $themeConfig } from "@themeConfig";

export const initialState = {
  isVerticalMenuCollapsed: $themeConfig.layout.menu.isCollapsed,
};

export default {
  namespaced: true,
  state: initialState,
  getters: {},
  mutations: {
    UPDATE_VERTICAL_MENU_COLLAPSED(state, val) {
      state.isVerticalMenuCollapsed = val;
    },
  },
  actions: {},
};
