<template>
	<b-card>
		<b-container class="bv-example-row">
			<Title text="Trip Details" />
			<b-row class="mt-2">
				<b-col md="6" lg="6" sm="12">
					<LabelText
						label="Customer"
						:text="orderDetails.User.FullName || '-'"
						showRightSection
						textClasses="font-weight-bolder"
					>
						<b-avatar
							size="md"
							:src="getMediaPath(orderDetails.User.Media)"
							:text="
								!getMediaPath(orderDetails.User.Media) &&
								orderDetails.User.FullName &&
								orderDetails.User.FullName[0]
							"
						/>
					</LabelText>

					<LabelText
						label="Location"
						:text="
							orderDetails.TripBooking.GuideTrip.FishSpot
								.Address || '-'
						"
						textClasses="font-weight-bolder"
					/>
					<LabelText
						label="Trip Name"
						:text="orderDetails.TripBooking.GuideTrip.Title || '-'"
						textClasses="font-weight-bolder"
					/>
					<LabelText
						label="Booking ID"
						:text="orderDetails.TripBooking.OrderNumber || '-'"
						textClasses="font-weight-bolder"
					/>
					<LabelText
						label="Captain Name"
						:text="
							orderDetails.TripBooking.GuideTrip.CaptainName ||
							'-'
						"
						textClasses="font-weight-bolder"
					/>
					<LabelText
						label="Captain Phone"
						:text="`${
							orderDetails.TripBooking.GuideTrip
								.CaptainContactCountryCode || ''
						} ${
							orderDetails.TripBooking.GuideTrip
								.CaptainContactNumber || '-'
						}`"
						textClasses="font-weight-bolder"
					/>
					<LabelText
						label="Booking Date"
						:text="orderDetails.TripBooking.BookingDate || '-'"
						textClasses="font-weight-bolder"
					/>
					<LabelText
						label="Trip Time"
						:text="`${getTripDuration(
							orderDetails.TripBooking.GuideTrip.TripDuration,
							orderDetails.TripBooking.GuideTrip.DepartureTime
						)}`"
						textClasses="font-weight-bolder"
					/>

					<LabelText
						label="Trip Type"
						:text="`${GuideTripType.getDisplayTextKey(
							orderDetails.TripBooking.GuideTrip.Type
						)}`"
						textClasses="font-weight-bolder"
					/>
					<LabelText
						label="No of Persons Booked"
						:text="
							!orderDetails.TripBooking.NumberOfAdult &&
							!orderDetails.TripBooking.NumberOfChildren
								? `${
										orderDetails.TripBooking
											.NumberOfPerson || 1
								  } Persons`
								: `${orderDetails.TripBooking.NumberOfAdult} Adults + ${orderDetails.TripBooking.NumberOfChildren} Children`
						"
						textClasses="font-weight-bolder"
					/>
				</b-col>
				<b-col md="6" lg="4" sm="12">
					<div class="d-flex flex-row align-items-center">
						<p class="mr-2">Seller :</p>
						<user-info
							:subText="
								orderDetails.TripBooking.GuideTrip.Guide.User
									.PhoneNumber || '-'
							"
							:fullName="
								orderDetails.TripBooking.GuideTrip.Guide.User
									.FullName || '-'
							"
							:avatarImage="
								getMediaPath(
									orderDetails.TripBooking.GuideTrip.Guide
										.User.Media
								) || null
							"
							iconSize="md"
							containerClasses="mb-1"
						/>
					</div>

					<div class="d-flex flex-row">
						<LabelText
							label="Price"
							:text="` $ ${orderDetails.TotalAmount || '0'} + $${
								orderDetails.FishFinFee || '0'
							} (transaction fee) + ${
								orderDetails.GatewayFee
							} (payment processing fee)`"
							textClasses="font-weight-bolder"
						/>
					</div>
					<LabelText
						v-if="
							orderDetails.TripBooking.Meta &&
							orderDetails.TripBooking.Meta.PaymentType ===
								TripPaymentType.DEPOSIT
						"
						label="Down Payment"
						:text="`$${orderDetails.TripBooking.DownPayment}`"
						textClasses="font-weight-bolder"
					/>
					<LabelText
						v-if="
							orderDetails.TripBooking.Meta &&
							orderDetails.TripBooking.Meta.PaymentType ===
								TripPaymentType.DEPOSIT
						"
						label="Remaining Payment"
						:text="`$${orderDetails.TripBooking.RemainingAmount}`"
						textClasses="font-weight-bolder"
					/>
					<LabelText
						label="Meeting Point"
						:text="`${
							orderDetails.TripBooking.GuideTrip
								.MeetingPointAddress || '-'
						}`"
						textClasses="font-weight-bolder"
					/>

					<p>Additional Notes:</p>
					<p class="additional-notes">
						{{ orderDetails.TripBooking.AdditionalNotes || '-' }}
					</p>
				</b-col>
			</b-row>
			<hr />
			<Title
				v-if="!orderDetails.TripBooking"
				text="No Details Found"
				classes="mt-3"
			/>
			<b-row>
				<b-col lg="6" md="6" sm="12">
					<Title text="Boat Details" classes="mt-3" />
					<b-row class="mt-3">
						<b-col md="12" lg="12" sm="12">
							<LabelText
								label="List Type"
								:text="
									orderDetails.TripBooking.GuideTrip
										.ListingType.Name || '-'
								"
								textClasses="font-weight-bolder"
							/>
							<LabelText
								label="Boat Type"
								:text="
									orderDetails.TripBooking.GuideTrip
										.BoatType || '-'
								"
								textClasses="font-weight-bolder"
							/>
							<LabelText
								label="Manufacturer"
								:text="
									orderDetails.TripBooking.GuideTrip
										.BoatManufacturer || '-'
								"
								textClasses="font-weight-bolder"
							/>

							<LabelText
								label="Boat Length"
								:text="
									orderDetails.TripBooking.GuideTrip
										.BoatLength +
										' ' +
										orderDetails.TripBooking.GuideTrip
											.BoatLengthUnit || '-'
								"
								textClasses="font-weight-bolder"
							/>

							<LabelText
								label="Build Year"
								:text="
									orderDetails.TripBooking.GuideTrip
										.BuildYear || '-'
								"
								textClasses="font-weight-bolder"
							/>

							<LabelText
								label="Boat Speed"
								:text="
									orderDetails.TripBooking.GuideTrip
										.BoatCruisingSpeed +
									' ' +
									orderDetails.TripBooking.GuideTrip
										.BoatCruisingSpeedUnit
								"
								textClasses="font-weight-bolder"
							/>
							<LabelText
								label="Boat Capacity"
								:text="
									orderDetails.TripBooking.GuideTrip
										.MaxPersonLimit || '-'
								"
								textClasses="font-weight-bolder"
							/>
						</b-col>
					</b-row>
				</b-col>

				<b-col lg="6" md="6" sm="12">
					<Title text="Amenities Provided" classes="mt-3" />
					<b-row class="mt-3">
						<b-col md="12" lg="12" sm="12">
							<LabelText
								label="Feature/Facilities"
								:text="
									ConvertArrDataToStr(
										orderDetails.TripBooking.GuideTrip
											.Facilities,
										',',
										'Name'
									) || '-'
								"
								textClasses="font-weight-bolder"
							/>
							<LabelText
								label="Gears"
								:text="
									ConvertArrDataToStr(
										orderDetails.TripBooking.GuideTrip
											.Gears,
										',',
										'Name'
									) || '-'
								"
								textClasses="font-weight-bolder"
							/>
							<LabelText
								label="Policies"
								:text="
									ConvertArrDataToStr(
										orderDetails.TripBooking.GuideTrip
											.Policies,
										',',
										'Name'
									) || '-'
								"
								textClasses="font-weight-bolder"
							/>

							<LabelText
								label="Amenities"
								:text="
									ConvertArrDataToStr(
										orderDetails.TripBooking.GuideTrip
											.Amenities,
										',',
										'Name'
									) || '-'
								"
								textClasses="font-weight-bolder"
							/>

							<p>Trip Description:</p>
							<p class="additional-notes">
								{{
									orderDetails.TripBooking.GuideTrip
										.Description || '-'
								}}
							</p>
						</b-col>
					</b-row>
				</b-col>
			</b-row>

			<hr />
			<!-- Need to add fix height in this row as to make overflow scroll -->
			<b-row>
				<b-col lg="6" md="6" sm="12">
					<Title text="Fishing Details" classes="mt-3" />
					<b-row class="mt-3">
						<b-col md="12" lg="12" sm="12">
							<p
								v-if="
									!orderDetails.TripBooking.GuideTrip
										.FishSpecies.length
								"
							>
								No Images
							</p>
							<div
								v-else
								class="d-flex flex-row align-items-center mb-1"
								v-for="(item, index) in orderDetails.TripBooking
									.GuideTrip.FishSpecies"
								:key="index"
							>
								<img
									:src="getMediaPath(item.Media)"
									class="fish-img"
								/>
								<p class="fish-name">{{ item.Name }}</p>
							</div>

							<!-- <LabelText
                label="Fishing Techniques"
                :text="
                  ConvertArrDataToStr(
                    orderDetails.TripBooking.GuideTrip.FishingTechniques,
                    ',',
                    'Name'
                  ) || '-'
                "
                textClasses="font-weight-bolder"
              /> -->
						</b-col>
					</b-row>
				</b-col>

				<b-col lg="6" md="6" sm="12">
					<Title text="Glimpse of Last Tour" classes="mt-3" />
					<b-row class="mt-1">
						<b-col md="12" lg="12" sm="12">
							<b-row class="mb-2">
								<p
									v-if="
										!orderDetails.TripBooking.GuideTrip
											.Medias.length
									"
								>
									No Past Trip Pictures
								</p>
								<img
									v-else
									v-for="(item, index) in orderDetails
										.TripBooking.GuideTrip.Medias"
									:key="index"
									class="mt-2 gallery-img ml-1"
									:src="getMediaPath(item)"
								/>
							</b-row>
						</b-col>
					</b-row>
				</b-col>
			</b-row>
		</b-container>
	</b-card>
</template>

<script>
import {
	BRow,
	BCol,
	BCard,
	BCardBody,
	BTableLite,
	BCardText,
	BButton,
	BAlert,
	BLink,
	BAvatar,
	BContainer,
} from 'bootstrap-vue'

import LabelText from '../../components/LabelText.vue'
import Ratings from '../../components/Ratings.vue'
import Title from '../../components/Title.vue'
import UserInfo from '../../components/UserInfo.vue'
import moment from 'moment'
import { getOrderDetailsById } from '../../api/api.service'
import { getRouteId } from '../../utils/getRouteId'
import { getMediaPath } from '../../utils/commonUtils'
import { GuideTripType, TripPaymentType } from '../../constants'
import { ConvertArrDataToStr } from '../../utils/ArrToStr'
export default {
	name: 'GuideOrderDetails',
	components: {
		BRow,
		BCol,
		BCard,
		BCardBody,
		BTableLite,
		BCardText,
		BButton,
		BAlert,
		BLink,
		BContainer,
		BAvatar,
		UserInfo,
		LabelText,
		Ratings,
		Title,
	},
	data() {
		return {
			orderDetails: {},
			purchaseCount: null,
			idParam: '',
			getRouteId,
			getMediaPath,
			GuideTripType,
			TripPaymentType,
			ConvertArrDataToStr,
		}
	},
	created() {
		this.idParam = this.getRouteId('orderId')
	},
	beforeMount() {
		getOrderDetailsById(this.idParam)
			.then((res) => {
				this.orderDetails = res.Transaction
				this.purchaseCount = res.PurchaseCount
			})
			.catch((err) => {})
	},
	methods: {
		getTripDuration(duration, starttime) {
			return moment(starttime, 'HH:mm:ss')
				.add(duration, 'hours')
				.format('HH:mm')
		},
	},
}
</script>

<style lang="scss">
@import '../~@core/scss/base/pages/app-ecommerce.scss';
</style>

<style scoped>
.gallery-img {
	width: 127px;
	height: 155px;
}

.fish-img {
	width: 121px;
	height: 48px;
}

.fee {
	font-weight: 500;
	color: #000;
}

.fish-name {
	font-weight: bold;
	margin-left: 13px;
	font-size: 14px;
	color: #000;
	margin-bottom: 0px;
}
.additional-notes {
	font-weight: bold;
	color: #000;
}
</style>
