import { injectBaseConstantMethods } from "./BaseConstants";

export const CommunityPostCategory = {
    News: 1,
    // Faqs: 2,
    Announcements: 3,
};

const displayTextKeys = {
    [CommunityPostCategory.News]: "Blogs",
    [CommunityPostCategory.Announcements]: "Announcements",
    // [CommunityPostCategory.Faqs]: "Faqs",
};

export default injectBaseConstantMethods(
    CommunityPostCategory,
    displayTextKeys
);
