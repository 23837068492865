var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',{staticClass:"mb-1",attrs:{"no-gutters":""}},[_c('div',{staticClass:"w-100 text-center"},[(_vm.isLoading)?_c('BSpinner',{staticClass:"spinner",attrs:{"variant":"primary"}}):_vm._e()],1),(!Object.keys(_vm.userBookings).length && !_vm.isLoading)?_c('p',{staticClass:"font-weight-bolder"},[_vm._v(" No Bookings ")]):_vm._e(),_vm._l((_vm.userBookings),function(bookings,bookingDate){return _c('b-col',{key:bookingDate,staticClass:"mb-1",attrs:{"lg":"12","md":"12","sm":"12"}},[(!_vm.isLoading)?_c('app-collapse',[_c('app-collapse-item',{attrs:{"headerTitleClass":"header-title","title":bookingDate},scopedSlots:_vm._u([{key:"header-content",fn:function(){return [(_vm.isFutureTrips)?_c('Button',{attrs:{"size":"md","buttonVariant":("" + (bookings[0].Status == _vm.TripsBookingStatus.TRIPCANCELLED
                ? 'danger'
                : 'outline-danger')),"classes":"wd-211","clickHandler":function () {
                bookings[0].Status != _vm.TripsBookingStatus.TRIPCANCELLED &&
                  _vm.isTripToCancle(bookingDate);
              },"buttonText":("" + (bookings[0].Status == _vm.TripsBookingStatus.TRIPCANCELLED
                ? 'Trip Cancelled'
                : 'Cancel Trip'))}}):_vm._e()]},proxy:true}],null,true)},[[_c('hr',{staticClass:"separator"}),_vm._l((bookings),function(item,index){return _c('div',{key:index,staticClass:"d-flex justify-content-between flex-wrap w-100"},[_c('div',{staticClass:"bold name"},[_c('user-info',{attrs:{"iconSize":"sm","fullName":_vm.validateValue(item.User.FullName),"avatarImage":_vm.getMediaPath(item.User.Media),"nameClasses":"f-bold"}})],1),_c('div',{staticClass:"bold"},[_c('LabelText',{attrs:{"label":"Phone Number","text":item.User.PhoneNumber,"textClasses":"f-bold"}})],1),_c('div',{staticClass:"bold"},[_c('label-text',{attrs:{"label":"Booking Date","text":_vm.formatBookingDate(item.BookingDate),"textClasses":"f-bold"}})],1),_c('div',{staticClass:"bold"},[_c('label-text',{attrs:{"label":"Booked Seats","text":_vm.validateValue(item.NumberOfPerson),"textClasses":"f-bold"}})],1),_c('div',{staticClass:"bold"},[_c('label-text',{attrs:{"label":"Price","text":("$" + (item.NetAmount)),"textClasses":"f-bold"}})],1),_c('div',{staticClass:"bold"},[_c('label-text',{attrs:{"label":"Payment Status","text":("" + (item.IsRefundable
                    ? 'To Be Refunded'
                    : _vm.PaymentStatus.getDisplayTextKey(item.TransactionStatus))),"textClasses":"f-bold"}})],1),_c('div',{on:{"click":function () { return _vm.onDetailClick(item); }}},[_c('feather-icon',{staticClass:"mx-1 detail-icon cursor-pointer",attrs:{"icon":"EyeIcon","size":"16"}})],1)])})]],2)],1):_vm._e()],1)}),_c('ConfirmModal',{attrs:{"id":"cancel-trip-modal","onYesPress":_vm.cancleATripHandler,"isLoading":_vm.tripCancleLoader,"isSuccess":_vm.isTripDeleteSuccess,"onOkayPress":_vm.handleOkayPress,"messageText":"Are you sure you want to cancel this trip?","successText":"Trip cancelled successfully !"}}),_c('b-modal',{staticClass:"custom-modal",attrs:{"id":"view-details","centered":"","hide-footer":"","hide-header":"","modal-content-class":"modal-container","no-close-on-backdrop":true,"no-close-on-esc":true},on:{"close":_vm.onClose,"hide":_vm.onClose}},[(_vm.selectedBooking)?_c('div',{staticClass:"detail-modal-body"},[_c('div',{staticClass:"detail-modal-header"},[_c('user-info',{attrs:{"iconSize":"md","fullName":_vm.validateValue(_vm.selectedBooking.User.FullName),"avatarImage":_vm.getMediaPath(_vm.selectedBooking.User.Media),"nameClasses":"f-bold"}}),_c('feather-icon',{staticClass:"cursor-pointer close-icon",attrs:{"icon":"XCircleIcon","size":"20"},on:{"click":function($event){return _vm.handleDetailModalClose()}}})],1),_c('div',{staticClass:"dm-body"},[_c('LabelText',{attrs:{"label":"Booking ID","text":_vm.selectedBooking.OrderNumber,"textClasses":"font-weight-bolder","containerClasses":"dm-label"}}),_c('LabelText',{attrs:{"label":"No of Persons Booked","text":!_vm.selectedBooking.NumberOfAdult &&
            !_vm.selectedBooking.NumberOfChildren
              ? ((_vm.selectedBooking.NumberOfPerson || 1) + " Persons")
              : ((_vm.selectedBooking.NumberOfAdult) + " Adults + " + (_vm.selectedBooking.NumberOfChildren) + " Children"),"containerClasses":"dm-label","textClasses":"font-weight-bolder"}}),_c('LabelText',{attrs:{"label":"Total Amount","text":("$" + (_vm.selectedBooking.TotalAmount || '0')),"containerClasses":"dm-label","textClasses":"font-weight-bolder"}}),(
            _vm.selectedBooking.Meta &&
            _vm.selectedBooking.Meta.PaymentType === _vm.TripPaymentType.DEPOSIT
          )?_c('LabelText',{attrs:{"label":"Down Payment","text":("$" + (_vm.selectedBooking.DownPayment) + " (" + (_vm.selectedBooking.Meta && _vm.selectedBooking.Meta.DepositPercent
              ? _vm.selectedBooking.Meta.DepositPercent + '%'
              : '') + ")"),"containerClasses":"dm-label","textClasses":"font-weight-bolder"}}):_vm._e(),(
            _vm.selectedBooking.Meta &&
            _vm.selectedBooking.Meta.PaymentType === _vm.TripPaymentType.DEPOSIT
          )?_c('LabelText',{attrs:{"label":"Remaining Payment","text":("$" + (_vm.selectedBooking.RemainingAmount)),"containerClasses":"dm-label","textClasses":"font-weight-bolder"}}):_vm._e(),_c('LabelText',{attrs:{"label":"Transaction Fee","text":("$" + (_vm.selectedBooking.TransactionFee || '0')),"containerClasses":"dm-label","textClasses":"font-weight-bolder"}}),_c('LabelText',{attrs:{"label":"Payment Processing Fee","text":("$" + (_vm.selectedBooking.GatewayFee || '0')),"containerClasses":"dm-label","textClasses":"font-weight-bolder"}}),_c('LabelText',{attrs:{"label":"Paid Amount","text":("$" + (_vm.selectedBooking.NetAmount || '0')),"containerClasses":"dm-label","textClasses":"font-weight-bolder"}}),_c('div',{staticClass:"additional-notes-wrapper"},[_c('p',{staticClass:"additional-notes-title"},[_vm._v("Notes:")]),_c('p',{staticClass:"additional-notes"},[_vm._v(" "+_vm._s(_vm.selectedBooking.AdditionalNotes || "-")+" ")])])],1)]):_vm._e()])],2)}
var staticRenderFns = []

export { render, staticRenderFns }