<template>
	<BasicModal
		id="create-referral-modal"
		:onClose="handleModalCloseLocal"
		modalTitle="Create Referral Code"
	>
		<template #showForm>
			<div>
				<b-form @submit.prevent="handleSubmit">
					<b-form-group
						label-cols="6"
						label-cols-lg="4"
						label="Referral Code:"
						label-for="referral-code"
					>
						<b-form-input
							max="10"
							id="referral-code"
							placeholder="Type Referral Code"
							:state="isReferraCodeInvalid"
							:value="referralCode"
							@input="handleSetReferralCode"
							aria-describedby="referral-code"
						/>
						<b-form-invalid-feedback id="referral-code">
							Referral code must consist of min 5 or max 10 alphanumeric
							characters
						</b-form-invalid-feedback>
					</b-form-group>

					<b-form-group
						label-cols="6"
						label-cols-lg="4"
						label="Member Name:"
						label-for="members-name"
					>
						<v-select
							:debounce="500"
							v-model="selected"
							id="members-name"
							placeholder="Search Members Name"
							@search="handleUsersSearch"
							label="title"
							:options="membersList"
						/>
					</b-form-group>

					<div class="d-flex justify-content-end mt-1">
						<div class="w-50">
							<Button
								buttonVariant="primary"
								buttonText="Save"
								classes="w-100"
								type="submit"
								:isLoading="createReferralCodeLoading"
								:clickHandler="onSubmit"
							/>
						</div>
					</div>
				</b-form>
			</div>
		</template>
	</BasicModal>
</template>

<script>
import {
	BCard,
	BCardBody,
	BBadge,
	BLink,
	BTooltip,
	BFormInput,
	BFormGroup,
	BForm,
	BFormRadioGroup,
	BFormInvalidFeedback,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import Button from '../../components/Button.vue'
import BasicModal from '../../components/BasicModal.vue'

export default {
	name: 'CreateReferralModal',
	data: () => {
		return {
			selected: null,
		}
	},
	components: {
		BCard,
		BCardBody,
		BBadge,
		BLink,
		BTooltip,
		BFormRadioGroup,
		BFormInput,
		BFormGroup,
		BForm,
		BFormInvalidFeedback,
		vSelect,
		BasicModal,
		Button,
	},

	props: [
		'referralCode',
		'memberSelected',
		'membersList',
		'handleUsersSearch',
		'handleSetReferralCode',
		'handleSetSelectedMember',
		'handleCreateNewCodeClick',
		'handleModalClose',
		'onSubmit',
		'createReferralCodeLoading',
		'isReferraCodeInvalid',
	],

	methods: {
		handleModalCloseLocal() {
			this.handleModalClose()
			this.selected = null
		},
	},
	watch: {
		selected(newVal, oldVal) {
			this.handleSetSelectedMember(newVal)
		},
	},

	mounted() {
		this.handleUsersSearch()
	},
}
</script>
