<template>
  <b-button-toolbar
    :class="styleClasses"
    aria-label="Toolbar with button groups and dropdown menu"
  >
    <b-dropdown
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      class="mx-1"
      right
      :text="buttonText"
      :variant="variant"
    >
      <slot></slot>
    </b-dropdown>
  </b-button-toolbar>
</template>

<script>
import {
  BButtonToolbar,
  BButtonGroup,
  BButton,
  BDropdown,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  name: "DropdownButton",
  components: {
    BButtonToolbar,
    BButtonGroup,
    BButton,
    BDropdown,
  },
  props: {
    buttonText: {
      type: String,
    },
    styleClasses: String,
    variant: {
      type: String,
    },
  },
  directives: {
    Ripple,
  },
};
</script>

<style scoped>
</style>