import { injectBaseConstantMethods } from "./BaseConstants";

export const purchaseType = {
  LOCATION: 5,
  GUIDE: 6,
};
const displayTextKeys = {
  [purchaseType.GUIDE]: "Guide",
  [purchaseType.LOCATION]: "Location",
};

const labelClass = {
  [purchaseType.GUIDE]: "",
  [purchaseType.LOCATION]: "",
};

export default injectBaseConstantMethods(
  purchaseType,
  displayTextKeys,
  labelClass
);
