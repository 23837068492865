var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('b-container',{staticClass:"bv-example-row"},[_c('Title',{attrs:{"text":"Order Details"}}),_c('b-row',{staticClass:"mt-2"},[_c('b-col',{attrs:{"md":"6","lg":"6","sm":"12"}},[_c('LabelText',{attrs:{"label":"Customer","text":_vm.orderDetails.User.FullName || '-',"showRightSection":"","textClasses":"font-weight-bolder"}},[_c('b-avatar',{attrs:{"size":"md","src":_vm.getMediaPath(_vm.orderDetails.User.Media),"text":!_vm.getMediaPath(_vm.orderDetails.User.Media) &&
								_vm.orderDetails.User.FullName &&
								_vm.orderDetails.User.FullName[0]}})],1),_c('LabelText',{attrs:{"label":"No of times Purchased","text":_vm.purchaseCount || '-',"textClasses":"font-weight-bolder"}}),_c('LabelText',{attrs:{"label":"Voucher Status","text":_vm.VoucherStatus.getDisplayTextKey(
								_vm.orderDetails.Voucher.Status
							) || '-',"textClasses":"font-weight-bolder"}}),_c('LabelText',{attrs:{"label":"Purchase Date","text":_vm.convertDateTime({
								date: _vm.orderDetails.PurchaseDate,
								customFormat: _vm.dateTimeFormat.appDateFormat,
								dateOnly: true,
							}) || '-',"textClasses":"font-weight-bolder"}}),_c('LabelText',{attrs:{"label":"Purchase Time","text":_vm.convertTime({
								tz: 'LOCAL_TIME',
								time: _vm.orderDetails.CreatedAt,
								format: _vm.dateTimeFormat.appTimeFormat,
							}),"textClasses":"font-weight-bolder"}}),_c('LabelText',{attrs:{"label":"Location","text":_vm.orderDetails.Voucher.Location.Address || '-',"textClasses":"font-weight-bolder"}}),_c('LabelText',{attrs:{"label":"GPS Coordinates","text":((_vm.orderDetails.Voucher.Location.Location.coordinates[0]) + " - " + (_vm.orderDetails.Voucher.Location.Location.coordinates[1])),"textClasses":"font-weight-bolder"}})],1),_c('b-col',{attrs:{"md":"6","lg":"4","sm":"12"}},[_c('div',{staticClass:"d-flex flex-row align-items-center"},[_c('p',{staticClass:"mr-2"},[_vm._v("Seller :")]),_c('user-info',{attrs:{"subText":_vm.orderDetails.Voucher.Location.User
									.PhoneNumber || '-',"fullName":_vm.orderDetails.Voucher.Location.User.FullName ||
								'-',"avatarImage":_vm.getMediaPath(
									_vm.orderDetails.Voucher.Location.User.Media
								) || null,"iconSize":"md","containerClasses":"mb-1"}})],1),_c('LabelText',{attrs:{"label":"Gifted","text":(_vm.orderDetails.Voucher.Type == _vm.VoucherType.Gift
								? 'Yes'
								: 'No') || '-',"textClasses":"font-weight-bolder"}}),_c('div',[_c('LabelText',{attrs:{"label":"Price","text":(" $ " + (_vm.orderDetails.NetAmount || '0') + " + $" + (_vm.orderDetails.FishFinFee || '0') + " (fishfin fee) + " + (_vm.orderDetails.GatewayFee) + " (payment processing fee)"),"textClasses":"font-weight-bolder"}})],1),_c('p',[_vm._v("Additional Notes:")]),_c('p',{staticClass:"additional-notes"},[_vm._v(" "+_vm._s(_vm.orderDetails.Voucher.Location.AdditionalNotes || '-')+" ")])],1)],1),_c('hr'),(!_vm.orderDetails.Voucher.Location.CatchLog.length)?_c('Title',{attrs:{"text":"No Catch Logs","classes":"mt-3"}}):_vm._e(),_vm._l((_vm.orderDetails.Voucher.Location.CatchLog),function(item,index){return _c('div',{key:index},[_c('Title',{attrs:{"text":((_vm.formatCatchNumber(index + 1)) + " Catch Details"),"classes":"mt-3"}}),_c('b-row',{staticClass:"mt-3"},[_c('b-col',{attrs:{"md":"4","lg":"4","sm":"12"}},[_c('LabelText',{attrs:{"label":"Date","text":_vm.convertDateTime({
									date: item.LogDate,
									customFormat: _vm.dateTimeFormat.appDateFormat,
									dateOnly: true,
								}),"textClasses":"font-weight-bolder"}}),_c('LabelText',{attrs:{"label":"Fish Specie","text":item.FishSpecie.Name,"textClasses":"font-weight-bolder"}}),_c('LabelText',{attrs:{"label":"Location","text":_vm.orderDetails.Voucher.Location.Country || '-',"textClasses":"font-weight-bolder"}}),_c('LabelText',{attrs:{"label":"Time","text":_vm.convertTime({
									tz: 'LOCAL_TIME',
									time: item.FishSpecie.CreatedAt,
									format: _vm.dateTimeFormat.appTimeFormat,
								}),"textClasses":"font-weight-bolder"}})],1),_c('b-col',{attrs:{"md":"4","lg":"5","sm":"12"}},[_c('LabelText',{attrs:{"label":"GPS Coordinates","text":"n/a","textClasses":"font-weight-bolder"}}),_c('LabelText',{attrs:{"label":"Weather Details","text":Object.values(item.Meta).join(',' + ' '),"textClasses":"font-weight-bolder"}}),_c('LabelText',{attrs:{"label":"Gear Used","text":_vm.ConvertArrDataToStr(item.Gears, ',', 'Name') ||
								'-',"textClasses":"font-weight-bolder"}}),_c('LabelText',{attrs:{"label":"Water Condition","text":"n/a","textClasses":"font-weight-bolder"}})],1),_c('b-col',{staticClass:"pt-1",attrs:{"md":"4","lg":"3","sm":"12"}},[_c('p',[_vm._v("Additional Notes:")]),_c('p',{staticClass:"additional-notes"},[_vm._v(" "+_vm._s(item.AdditionalNotes || '-')+" ")])])],1),_c('b-row',{staticClass:"mb-2"},_vm._l((item.Media),function(obj,index){return _c('img',{key:index,staticClass:"mt-2 gallery-img ml-1",attrs:{"src":_vm.getMediaPath(obj)}})}),0),(index === 0)?_c('hr'):_vm._e()],1)})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }