import { injectBaseConstantMethods } from './BaseConstants'

export const VerificatioinCodes = {
    VERIFIED: 1,
    UNVERIFIED: 2,
}
const displayTextKeys = {
    [VerificatioinCodes.VERIFIED]: 'Verified',
    [VerificatioinCodes.UNVERIFIED]: 'Unverified',
}

const labelClass = {
    [VerificatioinCodes.VERIFIED]: '',
    [VerificatioinCodes.UNVERIFIED]: '',
}

export default injectBaseConstantMethods(
    VerificatioinCodes,
    displayTextKeys,
    labelClass
)
