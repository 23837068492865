<template>
	<!-- Add conditional modal title base on action take by user to add/ edit -->
	<BasicModal
		id="email-name-modal"
		:onClose="handleModalClose"
		modalTitle="Create New Template"
	>
		<template #showForm>
			<!-- If User Clicks on Add / Edit Button -->

			<div>
				<b-form @submit.prevent="onSubmit">
					<b-form-group
						label-cols="4"
						label="Template Name"
						label-for="Template Name:"
						label-size="md"
						class="mt-1"
					>
						<b-form-input
							required
							:value="templateName"
							@input="handleChange"
							placeholder="Type Template Name"
							id="template-name"
							size="md"
							class="w-100"
						></b-form-input>
					</b-form-group>

					<!-- Show when edit  -->
					<div class="d-flex justify-content-end">
						<div class="w-50">
							<Button
								buttonVariant="primary"
								buttonText="Start Making"
								classes="w-100"
								type="submit"
								:isLoading="isLoading"
							/>
						</div>
					</div>
				</b-form>
			</div>
		</template>
	</BasicModal>
</template>

<script>
import {
	BCard,
	BCardBody,
	BBadge,
	BLink,
	BTooltip,
	BFormInput,
	BFormGroup,
	BForm,
} from 'bootstrap-vue'

import Button from '../../components/Button.vue'
import BasicModal from '../../components/BasicModal.vue'

export default {
	name: 'EmailNameModal',
	data: () => {
		return {}
	},
	components: {
		BCard,
		BCardBody,
		BBadge,
		BLink,
		BTooltip,
		BFormInput,
		BFormGroup,
		BForm,
		BasicModal,
		Button,
	},

	props: {
		actionType: {
			default: 'Add',
			type: String,
		},
		handleModalClose: {
			type: Function,
		},

		templateName: {
			type: String,
			default: '',
		},
		handleChange: {
			type: Function,
			default: () => {},
		},
		onSubmit: {
			type: Function,
			default: () => {},
		},
	},
}
</script>
