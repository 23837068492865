<template>
	<!-- content -->
	<div class="blog-detail-wrapper">
		<b-row>
			<!-- blogs -->

			<b-col cols="12">
				<template v-if="fetching">
					<div class="text-center text-primary my-2">
						<b-spinner class="align-middle"></b-spinner>
					</div>
				</template>
				<b-card class="post-card" v-if="!fetching">
					<div class="edit-button">
						<Button
							:clickHandler="viewDetails"
							buttonVariant="primary"
							classes="form-button"
							buttonText="Edit"
						></Button>
					</div>
					<b-carousel
						id="carousel-interval"
						indicators
						:interval="400000"
						class="img-container"
						img-height="400"
					>
						<b-carousel-slide
							v-for="(item, index) in currentPost.Media"
							:key="index"
							:img-src="getMediaPath(item)"
							class="img-item"
						></b-carousel-slide>
					</b-carousel>
					<!-- <b-card-img
            class="detail-img"
            :src="currentPost.Media ? getMediaPath(currentPost.Media[0]) : null"
            height="394"
          ></b-card-img> -->
					<b-card-title class="card-title">{{
						currentPost.Title
					}}</b-card-title>
					<b-card-text class="card-sub-title"
						>{{
							currentPost.Category
								? currentPost.Category.Title
								: '-'
						}}
						-
						{{
							convertDateTime({
								date: currentPost.CreatedAt,
								customFormat:
									dateTimeFormat.dateWithYearMonthFormat,
								dateOnly: true,
							})
						}}</b-card-text
					>
					<b-card-text class="card-hash">{{
						getHashes()
					}}</b-card-text>
					<b-card-text
						class="card-bottom-text"
						v-html="currentPost.Content"
					></b-card-text>
				</b-card>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import {
	BFormInput,
	BMedia,
	BAvatar,
	BMediaAside,
	BMediaBody,
	BImg,
	BLink,
	BFormGroup,
	BInputGroup,
	BInputGroupAppend,
	BCard,
	BRow,
	BCol,
	BBadge,
	BCardText,
	BDropdown,
	BDropdownItem,
	BForm,
	BFormTextarea,
	BFormCheckbox,
	BButton,
	BCardImg,
	BSpinner,
	BIconChatLeftText,
	BCarousel,
	BCarouselSlide,
} from 'bootstrap-vue'
import {
	computed,
	onMounted,
	reactive,
	toRefs,
	ref,
} from '@vue/composition-api'

import { getCommunityPostById } from '../../api/api.service'
import { useStore } from '../../store'
import { getMediaPath } from '../../utils/commonUtils'
import { dateTimeFormat, convertDateTime } from '../../utils/dateFormatUtils'
import Button from '../../components/Button.vue'

export default {
	name: 'CommunityPostDetail',
	components: {
		BFormInput,
		BMedia,
		BAvatar,
		BMediaAside,
		BMediaBody,
		BLink,
		BCard,
		BRow,
		BCol,
		BFormGroup,
		BInputGroup,
		BInputGroupAppend,
		BImg,
		BBadge,
		BCardText,
		BDropdown,
		BForm,
		BDropdownItem,
		BFormTextarea,
		BFormCheckbox,
		BButton,
		BCardImg,
		BSpinner,
		Button,
		BCarousel,
		BCarouselSlide,
	},
	setup: (props, context) => {
		const postId = Number(context.root.$route.params.communityPostId)
		const store = useStore()
		const state = reactive({
			currentPost: {},
			fetching: false,
		})

		state.currentPost =
			computed(() =>
				store.getters['communityPost/getCommunityPostById'](postId)
			).value || {}

		onMounted(() => {
			if (!state.currentPost.Id) {
				state.fetching = true
			}
			getCommunityPostById(postId)
				.then((res) => {
					state.currentPost = res.CommunityPost
				})
				.finally(() => {
					state.fetching = false
				})
		})

		function viewDetails() {
			context.root.$router.push(
				`/community-post/update/${state.currentPost.Id}`
			)
		}
		function getHashes() {
			if (state.currentPost.Hashtag) {
				return state.currentPost?.Hashtag.map(
					(item) => `#${item.Title}`
				).join(' ')
			}
			return ''
		}
		return {
			...toRefs(state),
			getMediaPath,
			convertDateTime,
			dateTimeFormat,
			getHashes,
			viewDetails,
		}
	},
}
</script>


// <style lang="scss">
// @import "@core/scss/vue/pages/page-blog.scss";
//
</style>
<style scoped>
.card-title {
	color: #003071;
	font-size: 18px;
	font-weight: bold !important;
}

.detail-img {
	margin-bottom: 50px;
}

.card-sub-title {
	color: #888888;
	font-size: 14px;
	margin-bottom: 10px;
}
.card-hash {
	color: #888888;
	font-size: 14px;
}

.card-bottom-text {
	font-size: 14px;
	color: black;
}

.edit-button {
	position: absolute;
	top: 50px;
	right: 45px;
	z-index: 1;
}
.post-card {
	position: relative;
}
.img-slide-item {
	height: 394px;
}
.img-item {
	max-height: 50vh !important;
	width: 100% !important;
}
::v-deep.carousel-item > img {
	height: 400px !important;
	object-fit: fill;
}
</style> 

