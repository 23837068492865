<template>
  <b-card>
    <div class="loader-container" v-if="isLoading">
      <b-spinner class="loader" variant="primary"></b-spinner>
    </div>
    <div class="loader-container" v-if="!isLoading && !currentGear">
      <p class="msg">Gear Not Found</p>
    </div>
    <b-container fluid v-if="currentGear && !isLoading">
      <div>
        <b-row>
          <h4 class="text-secondary pl-1 mb-3 font-weight-bolder">
            Gear Details
          </h4>
        </b-row>
        <b-row>
          <b-col cols="12" md="6" lg="6" sm="12">
            <LabelText
              label="Seller"
              showRightSection
              textClasses="font-weight-bolder"
            >
              <UserInfo
                iconSize="md"
                :avatarImage="getMediaPath(currentGear.User.Media)"
                :fullName="`${
                  (currentGear.User.FullName && currentGear.User.FullName) ||
                  '-'
                }`"
              />
            </LabelText>
            <LabelText
              label="Date"
              :text="formatDate(currentGear.CreatedAt)"
              textClasses="font-weight-bolder"
            />
            <LabelText
              label="Category"
              :text="currentGear.GearCategory.Name"
              textClasses="font-weight-bolder"
            />
            <LabelText
              label="Location"
              :text="
                currentGear.SellingAddress.Address
                  ? currentGear.SellingAddress.Address
                  : '-'
              "
              textClasses="font-weight-bolder"
            />

            <LabelText
              label="Price"
              :text="currentGear.Price ? '$ ' + currentGear.Price : '$0'"
              textClasses="font-weight-bolder"
            />
          </b-col>
          <b-col cols="12" md="6" lg="6" sm="12">
            <p class="title">
              Status:<b-badge pill class="ml-1 status-pill">{{
                FishGearStatus.getDisplayTextKey(currentGear.Status)
              }}</b-badge>
            </p>
            <p class="additional-notes-title">Additional Notes:</p>
            <p class="font-weight-bolder additional-notes">
              {{ currentGear.AdditionalNote || "-" }}
            </p>
          </b-col>
        </b-row>
        <!-- Image Gallery -->
        <b-row v-if="!!currentGear.Media.length">
          <div class="mt-1 ml-1">
            <ImageGallery :galleryConfig="currentGear.Media" />
          </div>
        </b-row>
        <!-- Delete Trip -->
        <b-row>
          <div class="col-xs-12 col-sm-4 col-md-3 col-lg-3">
            <Button
              buttonText="Delete Gear"
              buttonVariant="danger"
              classes="mt-1 mb-1 w-100"
              :clickHandler="handleDeletePress"
            />
          </div>
        </b-row>
      </div>
    </b-container>
    <ConfirmModal
      :id="MODAL_ID"
      :isLoading="isProcessing"
      :isSuccess="deleteSuccess"
      successText="Gear Deleted Successfully !"
      :onOkayPress="handleOkayPress"
      :onYesPress="handleYesPress"
      messageText="Are you sure you want to delete this gear?"
    />
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BTableLite,
  BCardText,
  BButton,
  BAlert,
  BLink,
  BBadge,
  BAvatar,
  BContainer,
  BSpinner,
  VBModal,
} from "bootstrap-vue";

import LabelText from "../../components/LabelText.vue";
import Button from "../../components/Button.vue";
import ImageGallery from "../../components/ImageGallery.vue";
import ConfirmModal from "../../components/ConfirmModal.vue";
import { APP_ROUTES } from "../../helpers/routeHelpers";
import { FishGearStatus } from "../../constants";
import { getGearById, deleteGear } from "../../api/api.service";
import { getMediaPath } from "../../utils/mediaUtils";
import { convertDateTime } from "../../utils/dateFormatUtils";
import UserInfo from "@/components/UserInfo.vue";

const MODAL_ID = "fishing-gear-details-modal";

export default {
  name: "GearDetails",
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
    LabelText,
    BAvatar,
    Button,
    ImageGallery,
    BBadge,
    BContainer,
    BSpinner,
    VBModal,
    ConfirmModal,
    UserInfo,
  },
  beforeMount() {
    this.isLoading = true;
    getGearById(this.$route.params.gearId)
      .then((res) => {
        this.currentGear = res.Gear;
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
  methods: {
    formatDate(date) {
      return convertDateTime({ date, customFormat: "LL" });
    },
    handleYesPress() {
      this.isProcessing = true;

      deleteGear(Number(this.$route.params.gearId))
        .then((res) => {
          this.deleteSuccess = true;
        })
        .finally(() => {
          this.isProcessing = false;
        });
    },
    handleOkayPress() {
      this.deleteSuccess = false;
      this.$router.replace(APP_ROUTES.FISH_GEAR);
    },
    handleDeletePress() {
      this.$bvModal.show("fishing-gear-details-modal");
    },
    getMediaPath,
  },
  data() {
    return {
      MODAL_ID,
      currentGear: null,
      FishGearStatus,
      isLoading: false,
      isProcessing: false,
      deleteSuccess: false,
    };
  },
};
</script>

<style lang="scss">
@import "../~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style scoped>
.additional-notes {
  line-height: 25px;
  color: black;
}
.title {
  color: #888888;
}
.status-pill {
  color: #000000;
  background-color: #28c76f1f;
  padding: 5px 10px 5px 10px;
}

.loader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 60vh;
}

.loader {
  margin-bottom: 8px;
  width: 4rem;
  height: 4rem;
}

.msg {
  font-size: 20px;
  font-weight: 500;
}
</style>
