<template>
  <b-button-group class="w-100">
    <b-button
      v-for="(item, index) in buttonConfig"
      :key="index"
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      variant="outline-primary"
      class="g-btn"
      :class="item.activeClass && 'active'"
      :size="size"
      @click="item.trigger"
    >
      {{ item.btnText }}
    </b-button>
  </b-button-group>
</template>

<script>
import { BButtonGroup, BButton } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  name: "GroupedButton",
  components: {
    BButtonGroup,
    BButton,
  },
  props: {
    size: String,
    variant: String,
    groupContainerClasses: String,
    buttonVariant: String,
    buttonConfig: Object,
  },

  directives: {
    Ripple,
  },
};
</script>
<style scoped lang="scss">
.g-btn {
  font-size: 12px;
  padding: 1em;
}
</style>
 