import { injectBaseConstantMethods } from './BaseConstants'

const EmailSendType = {
  ALL_MEMBERS: 1,
  ALL_GUIDES: 2,
  SPECIFIC_USERS: 3,
}

const displayTextKeys = {
  [EmailSendType.ALL_MEMBERS]: 'All Members',
  [EmailSendType.ALL_GUIDES]: 'All Guides',
  [EmailSendType.SPECIFIC_USERS]: 'Specific Members',
}

const labelClass = {
  [EmailSendType]: '',
}

export default injectBaseConstantMethods(
  EmailSendType,
  displayTextKeys,
  labelClass
)
