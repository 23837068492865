var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{staticClass:"bv-example-row"},[(!_vm.isFetching.value && _vm.state.reviewOf.length === 0)?[_c('div',{staticClass:"d-flex justify-content-center"},[_c('img',{staticClass:"w-25 h-25 text-center",attrs:{"src":_vm.emptyImage}})])]:_vm._e(),_vm._l((_vm.state.reviewOf),function(item,index){return _c('div',{key:index},[_c('b-card',[_c('b-row',[_c('b-col',{attrs:{"col":"","md":"6","lg":"6","sm":"12"}},[_c('LabelText',{attrs:{"label":"Seller","showRightSection":"","textClasses":"font-weight-bolder"}},[_c('UserInfo',{attrs:{"iconSize":"md","fullName":item.Location
                  ? item.Location.User.FullName || '-'
                  : item.GuideTrip.User.FullName || '-',"avatarImage":item.Location
                  ? _vm.getMediaPath(item.Location.Media)
                  : _vm.getMediaPath(item.GuideTrip.Media)}})],1),_c('LabelText',{attrs:{"label":"Purchase Date","text":_vm.convertDateTime({
                date: item.PurchaseDate,
                customFormat: _vm.dateTimeFormat.appDateFormat,
                dateOnly: true,
              }),"textClasses":"font-weight-bolder ml-0"}}),_c('LabelText',{attrs:{"label":"Purchase Type","text":_vm.purchaseType.getDisplayTextKey(item.EntityType) || '-',"textClasses":"font-weight-bolder"}}),_c('LabelText',{attrs:{"label":"Listing Item","text":item.Location
                ? item.Location.Title || '-'
                : item.GuideTrip.Title || '-',"showRightSection":"","textClasses":"font-weight-bolder"}}),_c('LabelText',{attrs:{"label":"Location","text":item.Location
                ? item.Location.User.CountryCodeAlpha || '-'
                : item.GuideTrip.User.CountryCodeAlpha || '-',"showRightSection":"","textClasses":"font-weight-bolder ml-0"}}),_c('LabelText',{attrs:{"label":"Ratings","showRightSection":"","textClasses":"font-weight-bolder"}},[_c('Ratings',{attrs:{"rateInNumber":item.Rating}})],1)],1),_c('b-col',{attrs:{"col":"","md":"6","lg":"6","sm":"12"}},[_c('LabelText',{attrs:{"label":"Customer","showRightSection":"","textClasses":"font-weight-bolder"}},[_c('UserInfo',{attrs:{"iconSize":"md","avatarImage":_vm.getMediaPath(item.User.Media),"fullName":item.User.FullName || '-'}})],1),_c('LabelText',{attrs:{"label":"Price","text":("$ " + (item.Price || '-')),"textClasses":"font-weight-bolder"}}),_c('div',{},[_c('p',{staticClass:"review-title"},[_vm._v("Reviews:")]),_c('p',{staticClass:"font-weight-bolder review-height"},[_vm._v(" "+_vm._s(item.Content || "No Reviews")+" ")])])],1)],1)],1)],1)}),(_vm.isFetching)?[_c('div',{staticClass:"text-center text-primary my-2"},[_c('b-spinner',{staticClass:"align-middle"})],1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }