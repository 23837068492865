<template>
  <div class="table-container">
    <b-card body-class="card-body-style">
      <search-header
        searchInputClasses="w-50"
        :onSearchChange="handleSearchChange"
        :onFilterChange="handleStatusChange"
        :statusOptions="gearStatus"
        :filterValue="filters.Status"
        :filterConstants="FishGearStatus"
        :pageLimitValue="pageLimit"
        :searchText="searchText"
        :onPageLimitChange="handlePageLimitChange"
      ></search-header>
      <data-table
        :perPageLimit="pageLimit"
        entriesName="Gears"
        :config="config"
        :data="data"
        :isLoading="isFetching"
        :totalRecords="totalGears"
        :selectedPageValue="page"
        :perPage="data.length"
        :onHandlePageChange="handlePageChange"
        :onSortClick="handleSortChange"
        :currentSortOrder="sortOrder"
        :currentSortColumn="sortColumn"
      >
        <template #cell(Id)="data">
          <span class="font-weight-bolder"> {{ data.value }} </span>
        </template>

        <template #cell(ProductName)="data">
          <span class="font-weight-bolder"> {{ data.item.Name }} </span>
        </template>

        <template #cell(Category)="data">
          <span class="font-weight-bolder">
            {{ data.item.GearCategory.Name }}
          </span>
        </template>

        <template #cell(SellerName)="data">
          <span class="text-nowrap">
            <user-info
              iconSize="md"
              :avatarImage="getMediaPath(data.item.User.Media)"
              :fullName="`${
                (data.item.User && data.item.User.FullName) || '-'
              }`"
            />
          </span>
        </template>

        <template #cell(Location)="data">
          <span class="font-weight-bolder">
            {{ data.item.SellingAddress.State || "-" }}
            {{
              data.item.SellingAddress.Country &&
              ", " + data.item.SellingAddress.Country
            }}
          </span>
        </template>

        <template #cell(Status)="data">
          <template>
            <b-badge
              v-if="data.item.Status == FishGearStatus.ACTIVE"
              pill
              :variant="FishGearStatus.getLabelClass(data.item.Status)"
            >
              {{ FishGearStatus.getDisplayTextKey(data.item.Status) }}
            </b-badge>
            <b-badge
              v-else-if="data.item.Status == FishGearStatus.SOLD"
              pill
              variant="light-secondary"
            >
              {{ FishGearStatus.getDisplayTextKey(data.item.Status) }}
            </b-badge>
          </template>
        </template>

        <template #cell(Price)="data">
          <span class="font-weight-bolder">
            ${{ data.item.Price || "0" }}
          </span>
        </template>

        <template #cell(Actions)="data">
          <div class="text-nowrap">
            <feather-icon
              :id="`fish-gear-row-${data.item.Id}-detail-icon`"
              icon="EyeIcon"
              size="16"
              class="mx-1 detail-icon cursor-pointer"
              v-on:click="handleViewDetails(data)"
            />
            <b-tooltip
              title="Detail"
              :target="`fish-gear-row-${data.item.Id}-detail-icon`"
            />
            <img
              v-b-modal="'fishing-gear-modal'"
              @click="handleDeleteClick(data.item.Id)"
              :id="`fish-guide-row-${data.item.Id}-action-icon`"
              class="delete-icon"
              :src="require('@/assets/images/icons/delete.svg')"
            />
            <b-tooltip
              title="Delete"
              class="cursor-pointer"
              :target="`fish-guide-row-${data.item.Id}-action-icon`"
            />
          </div>
        </template>
      </data-table>
      <!-- `</b-card-body>` -->
    </b-card>
    <ConfirmModal
      id="fishing-gear-modal"
      :isLoading="isProcessing"
      :isSuccess="deleteSuccess"
      successText="Gear Deleted Successfully !"
      :onOkayPress="handleOkayPress"
      :onYesPress="handleYesPress"
      messageText="Are you sure you want to delete this gear?"
    />
  </div>
</template>

<script>
import { BCard, BCardBody, BBadge, BLink, BTooltip } from "bootstrap-vue";
import { computed, reactive, ref } from "@vue/composition-api";

import DataTable from "../../components/data-table/DataTable.vue";
import UserInfo from "../../components/UserInfo.vue";
import SearchHeader from "../../components/SearchHeader.vue";
import ConfirmModal from "../../components/ConfirmModal.vue";
import { APP_ROUTES, getRoute } from "../../helpers/routeHelpers";
import { FishGearStatus, FishGearTypes, SortOrder } from "../../constants";
import { useStore } from "../../store";
import { usePaginatedRequest } from "../../hooks/usePaginatedRequest";
import { GET_GEAR_LIST, DELETE_GEAR } from "../../store/fishing-gear";
import { getMediaPath } from "../../utils/mediaUtils";

const config = [
  {
    label: "ID",
    key: "Id",
  },
  {
    label: "PRODUCT NAME",
    key: "ProductName",
  },
  {
    label: "CATEGORY",
    key: "GearCategoryId",
    isSortable: true,
  },
  {
    label: "SELLER NAME",
    key: "SellerName",
  },
  {
    label: "LOCATION",
    key: "Location",
  },
  {
    label: "STATUS",
    key: "Status",
    isSortable: true,
  },
  {
    label: "PRICE",
    key: "Price",
    isSortable: true,
  },
  {
    label: "ACTIONS",
    key: "Actions",
  },
];

export default {
  name: "FishGearListing",
  components: {
    BCard,
    BCardBody,
    BBadge,
    BLink,
    BTooltip,
    DataTable,
    UserInfo,
    SearchHeader,
    ConfirmModal,
  },

  setup(props, context) {
    const store = useStore();
    const isFetching = computed(() => store.state.fishingGear.fetching);
    const data = computed(() => store.state.fishingGear.list);
    const totalGears = computed(() => store.state.fishingGear.totalGears);
    const filters = reactive({ Status: null });
    const selectedId = ref(null);
    const isProcessing = ref(false);
    const deleteSuccess = ref(false);

    const gearStatus = Object.values(FishGearStatus).map((val) => ({
      label: FishGearStatus.getDisplayTextKey(val),
      value: FishGearStatus.getDisplayTextKey(val),
      id: val,
    }));

    const {
      page,
      searchText,
      pageLimit,
      sortOrder,
      sortColumn,
      handleSearchChange,
      handlePageClick,
      handlePageLimit,
      handleSortChange,
    } = usePaginatedRequest(
      GET_GEAR_LIST,
      () => ({
        Direction: SortOrder.DESC,
        Column: "Status",
        Type: FishGearTypes.MARKET_PLACE,
        ...(filters.Status && { Status: filters.Status }),
      }),
      [filters],
      "Name"
    );

    const handleViewDetails = ({ item }) => {
      context.root.$router.push(
        getRoute(APP_ROUTES.FISH_GEAR_DETAILS, { gearId: item.Id })
      );
    };

    const handlePageChange = (pageNumber) => {
      handlePageClick(pageNumber);
    };

    const handleYesPress = () => {
      isProcessing.value = true;

      store
        .dispatch(DELETE_GEAR, selectedId.value)
        .then((res) => {
          deleteSuccess.value = true;
        })
        .finally(() => {
          isProcessing.value = false;
        });
    };

    const handleDeleteClick = (id) => {
      selectedId.value = id;
    };

    const handleOkayPress = () => {
      deleteSuccess.value = false;
      selectedId.value = null;
    };

    const handleStatusChange = (item) => {
      page.value = 1;
      if (!item?.id) {
        filters.Status = null;
        return;
      }
      filters.Status = item.id;
    };
    const handlePageLimitChange = (val) => {
      page.value = 1;
      handlePageLimit(val);
    };

    return {
      config,
      data,
      isFetching,
      totalGears,
      FishGearStatus,
      page,
      searchText,
      sortOrder,
      sortColumn,
      isProcessing,
      deleteSuccess,
      gearStatus,
      filters,
      pageLimit,
      handleViewDetails,
      handlePageChange,
      handleSearchChange,
      handleStatusChange,
      handlePageLimitChange,
      handleSortChange,
      getMediaPath,
      handleDeleteClick,
      handleYesPress,
      handleOkayPress,
    };
  },
};
</script>

<style scoped>
::v-deep .active-icon {
  color: #28c76f;
}

::v-deep .inactive-icon {
  color: #d88416;
}

::v-deep .detail-icon {
  color: #888888;
}

.delete-icon {
  width: 14px;
  height: 14px;
  cursor: pointer;
}

.card-body-style {
  padding: 0 !important;
}
::v-deep .table-row {
  font-weight: bold;
}

.table-container {
  margin-top: -10px;
}
</style>
