<template>
	<b-row>
		<b-col xsm="12" sm="12" md="4" lg="4" xl="4" v-if="fromHistoryListing">
			<b-card>
				<LabelText
					containerClasses="flex-column align-items-start"
					label="Template Name"
					:text="isFetching ? '...' : templateName"
					textClasses="font-weight-bolder"
				/>
				<LabelText
					label="Sent Date"
					:text="isFetching ? '...' : templateSentDate"
					textClasses="font-weight-bolder"
				/>
				<LabelText
					containerClasses="flex-column align-items-start"
					label="Sent to"
					:text="isFetching ? '...' : sentToUsersList"
					textClasses="font-weight-bolder"
				/>
			</b-card>
		</b-col>

		<b-col
			xsm="12"
			sm="12"
			:md="`${fromTemplatesListing ? '12' : '8'}`"
			:lg="`${fromTemplatesListing ? '12' : '8'}`"
			:xl="`${fromTemplatesListing ? '12' : '8'}`"
			align="center"
			class="w-100 h-100"
		>
			<b-card class="d-flex justify-content-center">
				<div class="d-flex justify-content-between align-items-center">
					<span
						v-if="fromTemplatesListing"
						class="
							font-weight-bolder
							template-name
							d-flex
							justify-content-start
							mb-1
						"
					>
						{{ templateName }}
					</span>
					<Button
						containerClasses="d-flex justify-content-end mb-1"
						v-if="fromTemplatesListing"
						buttonText="Send Email"
						buttonVariant="primary"
						size="md"
						classes="btn-outline-grey"
						:clickHandler="openSendEmailModal"
					/>
				</div>

				<div class="template-preview" ref="emailView">
					<div class="spinner-container" v-if="isFetching">
						<b-spinner variant="primary" class="spinner" size="md" />
					</div>
				</div>
			</b-card>
		</b-col>
		<SendEmailModal
			:onSubmit="handleSubmitSaveAndSend"
			:isSendingEmail="isSendingMail"
		/>
	</b-row>
</template>

<script>
import { BRow, BCol, BCard, BImg, BSpinner } from 'bootstrap-vue'
import LabelText from '../../components/LabelText.vue'
import {
	emailTemplateHistoryUsers,
	getEmailTemplatesHistoryById,
	sendEmail,
	getEmailTemplateById,
} from '../../api/api.service'
import { EmailSendType } from '@/constants'
import { dateTimeFormat, convertDateTime } from '@/utils/dateFormatUtils'
import showToast from '@/hooks/useToast'
import Button from '@/components/Button.vue'
import SendEmailModal from './SendEmailModal.vue'
import { APP_ROUTES } from '@/helpers/routeHelpers'

export default {
	name: 'ViewEmail',
	components: {
		BRow,
		BCol,
		BCard,
		BImg,
		BSpinner,
		LabelText,
		Button,
		SendEmailModal,
	},
	data: () => {
		return {
			isFetching: false,
			templateId: '',
			templateName: '',
			templateSentDate: '',
			sentToType: '',
			sentToUsersList: [],
			emailTemplateHTML: '',
			emailTemplateDesign: {},
			userIdList: [],
			fromTemplatesListing: false,
			fromHistoryListing: false,
			isSendingMail: false,
		}
	},
	methods: {
		openSendEmailModal() {
			this.$bvModal.show('send-email-modal')
		},

		closeSendModal() {
			this.$bvModal.hide('send-email-modal')
		},

		async handleSubmitSaveAndSend(data) {
			let payload = {
				TemplateId: Number(this.templateId),
				...(Boolean(data?.users?.length) && { UserIds: data?.users }),
				SendToEmailType: data?.sendToType,
			}

			try {
				this.isSendingMail = true
				let res = await sendEmail(payload)
				showToast(this, {
					Title: `Email has been sent successfully!`,
					Icon: 'CheckCircleIcon',
					Text: res.Message,
					Variant: 'success',
				})
				this.closeSendModal()
				this.$router.push(APP_ROUTES.EMAILS_HISTORY_LISTING)
			} catch (err) {
				showToast(this, {
					Title: 'Error',
					Variant: 'danger',
					Icon: 'XCircleIcon',
					Text: err.Message,
				})
			} finally {
				this.isSendingMail = false
			}
		},

		async getEmailTemplateHistoryDetails() {
			try {
				this.isFetching = true
				let res = await getEmailTemplatesHistoryById(this.templateId)
				let historyDetails = res?.SentEmail

				if (historyDetails?.SendToType == EmailSendType.SPECIFIC_USERS) {
					let users = await emailTemplateHistoryUsers(this.templateId)

					this.sentToUsersList = users?.Users?.map(
						(item) => item?.User?.FullName
					).join(',' + ' ')
				} else {
					this.sentToUsersList = EmailSendType.getDisplayTextKey(
						historyDetails?.SendToType
					)
				}

				this.templateName = historyDetails?.EmailTemplate?.Name
				this.templateSentDate = convertDateTime({
					date: historyDetails?.CreatedAt,
					customFormat: dateTimeFormat.appDateFormat,
					dateOnly: true,
				})
				this.emailTemplateHTML = historyDetails?.Html
				this.emailTemplateDesign = historyDetails?.HtmlData
				this.$refs.emailView.innerHTML = historyDetails?.Html
			} catch (err) {
				showToast(this, {
					Title: 'Error',
					Variant: 'danger',
					Icon: 'XCircleIcon',
					Text: err.Message,
				})
			} finally {
				this.isFetching = false
			}
		},

		async getEmailTemplatDetails() {
			try {
				this.isFetching = true
				let res = await getEmailTemplateById(this.templateId)

				this.templateName = res?.Template?.Name
				this.emailTemplateHTML = res?.Template?.Html
				this.emailTemplateDesign = res?.Template?.HtmlData
				this.$refs.emailView.innerHTML = this.emailTemplateHTML
			} catch (err) {
				showToast(this, {
					Title: 'Error',
					Variant: 'danger',
					Icon: 'XCircleIcon',
					Text: err.Message,
				})
			} finally {
				this.isFetching = false
			}
		},

		filterPath() {
			if (this.$route?.params?.templateId) {
				this.fromHistoryListing = false
				this.fromTemplatesListing = true
			} else if (this.$route?.params?.historyTemplateId) {
				this.fromHistoryListing = true
				this.fromTemplatesListing = false
			}
		},
	},
	mounted() {
		this.templateId =
			this.$route?.params?.templateId || this.$route?.params?.historyTemplateId
		this.filterPath()
		this.fromHistoryListing && this.getEmailTemplateHistoryDetails()
		this.fromTemplatesListing && this.getEmailTemplatDetails()
	},
}
</script>

<style scoped>
.spinner-container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 80vh;
	width: 100%;
}
.template-preview {
	pointer-events: none;
	user-select: none;
}
</style>