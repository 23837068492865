import { injectBaseConstantMethods } from "./BaseConstants";

const TransactionStatus = {
  REFUNDED: 10,
  CANCELLED: 11,
  FAILED: 12,
  PAID: 20,
  PENDING_CAPTURE: 21,
};

const displayTextKeys = {
  [TransactionStatus.REFUNDED]: "Refunded",
  [TransactionStatus.CANCELLED]: "Cancelled",
  [TransactionStatus.FAILED]: "Failed",
  [TransactionStatus.PAID]: "Paid",
  [TransactionStatus.PENDING_CAPTURE]: "Pending Capture",
};

const labelClass = {
  [TransactionStatus.REFUNDED]: "light-info",
  [TransactionStatus.CANCELLED]: "light-warning",
  [TransactionStatus.FAILED]: "light-danger",
  [TransactionStatus.PAID]: "light-success",
  [TransactionStatus.PENDING_CAPTURE]: "light-primary",
};

export default injectBaseConstantMethods(
  TransactionStatus,
  displayTextKeys,
  labelClass
);
