<template>
  <p class="title" :class="classes">{{ text }}</p>
</template>

<script>
export default {
  name: "Title",
  props: {
    text: String,
    classes: String,
  },
};
</script>

<style lang="scss">
@import "../assets/scss/variables/_variables.scss";

.title {
  color: $secondary;
  font-size: 14px;
  font-weight: 900;
}
</style>
scoped>
