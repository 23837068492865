<template>
	<b-card>
		<b-container class="bv-example-row">
			<b-row>
				<h4 class="text-secondary pl-1 font-weight-bolder mb-2">
					Captain Details
				</h4>
			</b-row>
			<b-row class="">
				<b-col sm="12" md="4" lg="4">
					<LabelText
						label="Captain Name"
						:text="data.CaptainName || '-'"
						textClasses="font-weight-bolder"
					/>
				</b-col>
				<b-col sm="12" md="4" lg="4">
					<LabelText
						label="Captain Number"
						:text="`${data.CaptainContactCountryCode || ''} ${
							data.CaptainContactNumber || '-'
						}`"
						textClasses="font-weight-bolder"
					/>
				</b-col>
			</b-row>
			<hr />
			<b-row>
				<h4 class="text-secondary pl-1 font-weight-bolder mb-2">
					Booking Details
				</h4>
			</b-row>
			<b-row class="">
				<b-col sm="12" md="4" lg="4">
					<LabelText
						label="Trip Type"
						:text="GuideTripType.getDisplayTextKey(data.Type)"
						textClasses="font-weight-bolder"
					/>
					<LabelText
						v-if="data.PaymentType === TripPaymentType.DEPOSIT"
						label="Down Payment"
						:text="data.DepositPercent + ' %'"
						textClasses="font-weight-bolder"
					/>
				</b-col>
				<b-col sm="12" md="4" lg="4">
					<LabelText
						v-if="
							data.Type === GuideTripType.PRIVATE &&
							data.PricingType === TripPricingType.GROUP &&
							data.AdditionalPersonPricing ===
								TripAdditionalPersonPricingType.ENABLED
						"
						label="Total Price Based on"
						:text="data.MinBookingPerson || '-'"
						textClasses="font-weight-bolder"
					/>
					<LabelText
						v-else-if="
							data.PricingType === TripPricingType.PERSON ||
							data.Type === GuideTripType.SHARED
						"
						label="Minimum Persons Per Booking"
						:text="data.MinBookingPerson || '-'"
						textClasses="font-weight-bolder"
					/>
					<LabelText
						v-if="
							data.Type === GuideTripType.PRIVATE &&
							data.PricingType === TripPricingType.GROUP &&
							data.AdditionalPersonPricing ===
								TripAdditionalPersonPricingType.ENABLED
						"
						label="Additional person charges"
						:text="`$${data.AdditionalPerPersonAmount || '0'}`"
						textClasses="font-weight-bolder"
					/>
				</b-col>
				<b-col sm="12" md="4" lg="4">
					<LabelText
						label="Price"
						:text="`$${data.PricePerPerson}  ${
							data.Type === GuideTripType.PRIVATE &&
							data.PricingType === TripPricingType.GROUP
								? ''
								: 'Per Person'
						}`"
						textClasses="font-weight-bolder"
					/>
				</b-col>
			</b-row>
			<hr />
			<b-row>
				<h4 class="text-secondary pl-1 font-weight-bolder mb-2">
					Boat Details
				</h4>
			</b-row>
			<b-row class="">
				<b-col sm="12" md="4" lg="4">
					<LabelText
						label="Listing Type"
						:text="data.ListingType.Name || '-'"
						textClasses="font-weight-bolder"
					/>
					<LabelText
						label="Boat Length"
						:text="`${data.BoatLength} ${data.BoatLengthUnit}`"
						textClasses="font-weight-bolder"
					/>
					<LabelText
						label="Boat Capacity"
						:text="`${data.MaxPersonLimit}`"
						textClasses="font-weight-bolder"
					/>
				</b-col>
				<b-col sm="12" md="4" lg="4">
					<LabelText
						label="Boat Type"
						:text="data.BoatType || '-'"
						textClasses="font-weight-bolder"
					/>
					<LabelText
						label="Build Year"
						:text="data.BuildYear || '-'"
						textClasses="font-weight-bolder"
					/>
				</b-col>
				<b-col sm="12" md="4" lg="4">
					<LabelText
						label="Boat Manufacturer"
						:text="data.BoatManufacturer || '-'"
						textClasses="font-weight-bolder"
					/>
					<LabelText
						label="Speed"
						:text="
							data.BoatCruisingSpeed
								? `${data.BoatCruisingSpeed} ${data.BoatCruisingSpeedUnit}`
								: '-'
						"
						textClasses="font-weight-bolder"
					/>
				</b-col>
			</b-row>
			<hr />
			<!-- Features / Facilities -->
			<b-row class="">
				<b-col sm="12" md="4" lg="4" class="">
					<h4 class="text-secondary font-weight-bolder mb-2">
						Features / Facilities
					</h4>
					<span class="font-weight-bolder alone-text">
						{{
							ConvertArrDataToStr(data.Facilities, ',', 'Name') ||
							'-'
						}}
					</span>
				</b-col>
				<!-- Policies -->
				<b-col sm="12" md="4" lg="4" class="">
					<h4 class="text-secondary font-weight-bolder mb-2">
						Policies
					</h4>
					<span class="font-weight-bolder alone-text">
						{{
							ConvertArrDataToStr(data.Policies, ',', 'Name') ||
							'-'
						}}</span
					>
				</b-col>
				<b-col sm="12" md="4" lg="4"> </b-col>
			</b-row>
			<hr />
			<!-- Gears -->

			<b-row class="">
				<b-col sm="12" md="4" lg="4" class="">
					<h4 class="text-secondary mb-2 font-weight-bolder">
						Gears
					</h4>
					<span class="font-weight-bolder alone-text">
						{{
							ConvertArrDataToStr(data.Gears, ',', 'Name') || '-'
						}}</span
					>
				</b-col>
				<!-- Amenities -->
				<b-col sm="12" md="4" lg="4" class="">
					<h4 class="text-secondary mb-2 font-weight-bolder">
						Amenities
					</h4>
					<span class="font-weight-bolder alone-text">
						{{
							ConvertArrDataToStr(data.Amenities, ',', 'Name') ||
							'-'
						}}
					</span>
				</b-col>
				<b-col sm="12" md="4" lg="4"> </b-col>
			</b-row>
			<hr />

			<b-row class="">
				<b-col sm="12" md="4" lg="4" class="">
					<h4 class="text-secondary mb-2 font-weight-bolder">
						Meeting Point
					</h4>
					<span class="font-weight-bolder alone-text">
						{{ data.MeetingPointAddress || '-' }}</span
					>
				</b-col>
				<b-col sm="12" md="4" lg="4" class="">
					<h4 class="text-secondary mb-2 font-weight-bolder">
						Trip Description
					</h4>
					<span class="font-weight-bolder alone-text">
						{{ data.Description || '-' }}
					</span>
				</b-col>
				<b-col sm="12" md="4" lg="4"> </b-col>
			</b-row>
			<hr />
			<!-- Fishing Details -->
			<b-row>
				<h4 class="text-secondary pl-1 font-weight-bolder mb-2">
					Fishing Details
				</h4>
			</b-row>
			<b-row class="">
				<b-col sm="12" md="6" lg="6">
					<LabelText
						label="Fishing Location"
						:text="
							`${data.FishSpot.Address}, ${data.FishSpot.State}, ${data.FishSpot.Country} ` ||
							'-'
						"
						textClasses="font-weight-bolder"
					/>
				</b-col>
				<b-col sm="12" md="6" lg="6">
					<!-- <LabelText
            label="Fishing Techniques"
            :text="ConvertArrDataToStr(data.FishingTechniques, ',', 'Name')"
            textClasses="font-weight-bolder"
          /> -->
				</b-col>

				<b-col sm="12" md="6" lg="6">
					<LabelText
						label="Target Fish Type"
						:text="
							ConvertArrDataToStr(
								data.FishSpecies,
								',',
								'Name'
							) || '-'
						"
						textClasses="font-weight-bolder"
					/>
				</b-col>
			</b-row>
			<hr />
			<!-- Past Trip Pictures -->
			<b-row class="">
				<h4 class="text-secondary pl-1 font-weight-bolder">
					Past Trip Pictures
				</h4>
			</b-row>
			<!-- Image Gallery -->
			<b-row>
				<div class="mt-1 ml-1" v-if="data.Medias.length">
					<ImageGallery :galleryConfig="data.Medias" />
				</div>
				<div v-else class="pl-1">
					<p>No past trips</p>
				</div>
			</b-row>
			<!-- Delete Trip -->
			<b-row>
				<div class="col-sm-12 col-md-2 col-lg-3">
					<Button
						buttonText="Delete Trip"
						buttonVariant="danger"
						classes="mt-1 mb-1 w-75"
						:clickHandler="
							() => this.$bvModal.show('delete-trip-modal')
						"
					/>
				</div>
			</b-row>
		</b-container>
		<ConfirmModal
			id="delete-trip-modal"
			:onYesPress="deleteTripHandler"
			:isLoading="isLoading"
			:isSuccess="isDeleteSuccess"
			:onOkayPress="handleOkayPress"
			messageText="Are you sure you want to delete this trip?"
			successText="Trip deleted successfully !"
		/>
	</b-card>
</template>

<script>
import {
	BRow,
	BCol,
	BCard,
	BCardBody,
	BTableLite,
	BCardText,
	BButton,
	BAlert,
	BLink,
	VBToggle,
	BCardTitle,
	BImg,
} from 'bootstrap-vue'

import LabelText from '../../components/LabelText.vue'
import Button from '../../components/Button.vue'
import ImageGallery from '../../components/ImageGallery.vue'
import ConfirmModal from '../../components/ConfirmModal.vue'
import { getATripDetail } from '../../api/api.service'
import { ConvertArrDataToStr } from '../../utils/ArrToStr'
import { getRouteId } from '../../utils/getRouteId'
import {
	GuideTripType,
	TripPricingType,
	TripPaymentType,
	TripAdditionalPersonPricingType,
} from '../../constants'

export default {
	name: 'Trip Details',
	components: {
		BRow,
		BCol,
		BCard,
		BCardBody,
		BTableLite,
		BCardText,
		BButton,
		BAlert,
		BLink,
		BCardTitle,
		BImg,
		LabelText,
		VBToggle,
		Button,
		ImageGallery,
		ConfirmModal,
	},

	data() {
		return {
			getRouteId,
			ConvertArrDataToStr,
			isLoading: false,
			isDeleteSuccess: false,
			routeId: '',
			data: {},
			GuideTripType,
			TripPricingType,
			TripPaymentType,
			TripAdditionalPersonPricingType,
			galleryConfigData: [
				{
					classes: 'd-inline-block mr-1 mb-1',
					height: '120',
					width: '',
					source: require('@/assets/images/gallery/image-01.png'),
				},
				{
					classes: 'd-inline-block mr-1 mb-1',
					height: '120',
					width: '',
					source: require('@/assets/images/gallery/image-02.png'),
				},
				{
					classes: 'd-inline-block mr-1 mb-1',
					height: '120',
					width: '',
					source: require('@/assets/images/gallery/image-03.png'),
				},
				{
					classes: 'd-inline-block mr-1 mb-1',
					height: '120',
					width: '',
					source: require('@/assets/images/gallery/image-04.png'),
				},
			],
		}
	},
	methods: {
		deleteTripHandler() {
			;(this.isLoading = true),
				this.$store
					.dispatch('guides/deleteEntireTrip', this.routeId)
					.then(() => {
						this.isLoading = false
						this.isDeleteSuccess = true
					})
					.catch(() => {
						this.isLoading = false
					})
					.finally(() => {
						this.isLoading = false
						this.isDeleteSuccess = true
					})
		},

		handleOkayPress() {
			this.isDeleteSuccess = false
			this.$router.go(-1)
		},
	},
	created() {
		this.routeId = this.getRouteId('tripId')

		getATripDetail(this.routeId)
			.then((res) => {
				let { GuideTrip } = res
				this.data = { ...GuideTrip }
			})
			.catch((err) => {})
	},
}
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-ecommerce.scss';
</style>

<style scoped>
.alone-text {
	color: black;
}
</style>
