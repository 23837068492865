<template>
  <b-avatar
    :src="src"
    :size="size"
    variant="light-primary"
    class="badge-minimal"
    :class="classes"
  >
  </b-avatar>
</template>

<script>
import { BAvatar } from "bootstrap-vue";

export default {
  components: { BAvatar },
  name: "Avatar",
  props: {
    size: {
      type: String,
      default: "lg",
    },
    src: String,
    classes: String,
  },
};
</script>
