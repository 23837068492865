<template>
  <b-container class="bv-example-row">
    <!-- Will show no data image -->
    <template v-if="!isFetching.value && state.reviewOf.length === 0">
      <div class="d-flex justify-content-center">
        <img :src="emptyImage" class="w-25 h-25 text-center" />
      </div>
    </template>

    <div v-for="(item, index) in state.reviewOf" :key="index">
      <b-card>
        <b-row>
          <b-col col md="6" lg="6" sm="12">
            <LabelText
              label="Seller"
              showRightSection
              textClasses="font-weight-bolder"
            >
              <UserInfo
                iconSize="md"
                :fullName="
                  item.Location
                    ? item.Location.User.FullName || '-'
                    : item.GuideTrip.User.FullName || '-'
                "
                :avatarImage="
                  item.Location
                    ? getMediaPath(item.Location.Media)
                    : getMediaPath(item.GuideTrip.Media)
                "
              />
            </LabelText>
            <LabelText
              label="Purchase Date"
              :text="
                convertDateTime({
                  date: item.PurchaseDate,
                  customFormat: dateTimeFormat.appDateFormat,
                  dateOnly: true,
                })
              "
              textClasses="font-weight-bolder ml-0"
            />
            <LabelText
              label="Purchase Type"
              :text="purchaseType.getDisplayTextKey(item.EntityType) || '-'"
              textClasses="font-weight-bolder"
            />
            <LabelText
              label="Listing Item"
              :text="
                item.Location
                  ? item.Location.Title || '-'
                  : item.GuideTrip.Title || '-'
              "
              showRightSection
              textClasses="font-weight-bolder"
            />
            <LabelText
              label="Location"
              :text="
                item.Location
                  ? item.Location.User.CountryCodeAlpha || '-'
                  : item.GuideTrip.User.CountryCodeAlpha || '-'
              "
              showRightSection
              textClasses="font-weight-bolder ml-0"
            />

            <LabelText
              label="Ratings"
              showRightSection
              textClasses="font-weight-bolder"
            >
              <Ratings :rateInNumber="item.Rating" />
            </LabelText>
          </b-col>
          <b-col col md="6" lg="6" sm="12">
            <LabelText
              label="Customer"
              showRightSection
              textClasses="font-weight-bolder"
            >
              <UserInfo
                iconSize="md"
                :avatarImage="getMediaPath(item.User.Media)"
                :fullName="item.User.FullName || '-'"
              />
            </LabelText>

            <LabelText
              label="Price"
              :text="`$ ${item.Price || '-'}`"
              textClasses="font-weight-bolder"
            />
            <div class="">
              <p class="review-title">Reviews:</p>

              <p class="font-weight-bolder review-height">
                {{ item.Content || "No Reviews" }}
              </p>
            </div>
          </b-col>
        </b-row>
      </b-card>
    </div>
    <template v-if="isFetching">
      <div class="text-center text-primary my-2">
        <b-spinner class="align-middle"></b-spinner>
      </div>
    </template>
  </b-container>
</template>

<script>
import { computed, onMounted, reactive, ref } from "@vue/composition-api";

import LabelText from "../../components/LabelText.vue";
import Ratings from "../../components/Ratings.vue";
import UserInfo from "../../components/UserInfo.vue";

import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BTableLite,
  BCardText,
  BButton,
  BAlert,
  BLink,
  BAvatar,
  BOverlay,
  BSpinner,
} from "bootstrap-vue";

import { useStore } from "../../store";
import { getMediaPath } from "../../utils/commonUtils";
import { getReviewById } from "../../api/api.service";
import {
  dateTimeFormat,
  convertDateTime,
  convertTime,
} from "../../utils/dateFormatUtils";
import { purchaseType } from "../../constants";

export default {
  name: "SellerReview",
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BAlert,
    BLink,
    LabelText,
    BAvatar,
    BOverlay,
    BSpinner,
    Ratings,
    UserInfo,
  },
  setup(props, context) {
    const store = useStore();
    const userId = Number(context.root.$route.params.reviewId);
    let isFetching = ref(false);
    let state = reactive({
      reviewOf: computed(() =>
        store.getters["ratingReviews/getReviewById"](userId)
      ).value,
    });

    onMounted(() => {
      if (state.reviewOf.length === 0) {
        isFetching.value = true;
        getReviewById(userId)
          .then((res) => {
            isFetching.value = false;
            let response = res.Review;
            state.reviewOf = [{ ...response }];
          })
          .catch((err) => {
            isFetching.value = false;
          });
      }
    });

    return {
      emptyImage: require("@/assets/images/gallery/no-data.png"),
      getMediaPath,
      state,
      dateTimeFormat,
      convertDateTime,
      convertTime,
      purchaseType,
      isFetching,
    };
  },
};
</script>

<style lang="scss">
@import "../~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style scoped>
.review-height {
  line-height: 25px;
  color: black;
}
.review-title {
  color: #888888;
}
.card-body {
  padding-bottom: 0px !important;
}
</style>
