<template>
  <b-form-group>
    <v-select
      @input="onChange"
      class="select-box"
      v-model="selectedOption"
      label="title"
      :filterable="true"
      :options="options"
      :class="classes"
      @open="onOpen"
      @close="onClose"
      ref="load"
    >
      <template #list-footer>
        <li v-if="isFetching" class="load-text">Loading...</li>
        <li v-show="hasNextPage" ref="load" class="loader"></li>
      </template>
    </v-select>
  </b-form-group>
</template>

<script>
import { BFormGroup } from "bootstrap-vue";
import vSelect from "vue-select";

export default {
  components: {
    BFormGroup,
    vSelect,
  },
  data() {
    return {
      observer: null,
    };
  },
  computed: {
    hasNextPage() {
      return true;
    },
  },
  watch: {
    selectedOption: function () {},
  },
  mounted() {
    this.observer = new IntersectionObserver(this.infiniteScroll);
  },
  methods: {
    async onOpen() {
      if (this.hasNextPage) {
        await this.$nextTick();
        this.observer.observe(this.$refs.load);
      }
    },
    onClose() {
      this.observer.disconnect();
    },
    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        await this.$nextTick();
        ul.scrollTop = scrollTop;

        if (
          this.$props.onScrollToBottom &&
          typeof this.$props.onScrollToBottom === "function"
        ) {
          this.$props.onScrollToBottom();
        }
      }
    },
  },

  props: {
    options: {
      type: Array,
      default: [],
    },
    selectedOption: {
      type: Object || null,
      default: null,
    },
    placeholder: {
      type: String,
      default: "Select From list",
    },
    onChange: Function,
    isFetching: Boolean,
    onScrollToBottom: Function,
    classes: String,
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style>
.select-box {
  width: 200px !important;
  height: 38px !important;
  border-color: #d8d6de !important;
}
.fullWidth {
  width: 100% !important;
}

.loader {
  height: 10px;
  width: 10px;
}
.select-box .vs__dropdown-menu {
  height: 270px;
}

.load-text {
  display: flex;
  justify-content: center;
}
</style>
