import { injectBaseConstantMethods } from "./BaseConstants";

const ConfigSettings = {
  MonthlySubscriptionFee: 1,
  TransactionFee: 2,
  LogCatchDistance: 3,
  VoucherExpiresIn: 4,
  VoucherValidFor: 5,
};

const displayTextKeys = {
  [ConfigSettings.MonthlySubscriptionFee]: "monthlySubscriptionFee",
  [ConfigSettings.TransactionFee]: "transactionFee",
  [ConfigSettings.LogCatchDistance]: "logCatchDistance",
  [ConfigSettings.VoucherExpiresIn]: "voucherExpiresIn",
  [ConfigSettings.VoucherValidFor]: "voucherValidFor",
};

const labelClass = {
  [ConfigSettings.MonthlySubscriptionFee]: "",
  [ConfigSettings.TransactionFee]: "",
  [ConfigSettings.LogCatchDistance]: "",
  [ConfigSettings.VoucherExpiresIn]: "",
  [ConfigSettings.VoucherValidFor]: "",
};

export default injectBaseConstantMethods(
  ConfigSettings,
  displayTextKeys,
  labelClass,
);
