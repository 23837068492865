import { injectBaseConstantMethods } from './BaseConstants'

const EmailTemplateSentStatus = {
  SENT_ONES: 1,
  SENT_TO_MANY: 2,
  NOT_SENT: 3,
}

const displayTextKeys = {
  [EmailTemplateSentStatus.SENT_ONES]: 'Sent 1 time',
  [EmailTemplateSentStatus.SENT_TO_MANY]: 'Sent 3 times',
  [EmailTemplateSentStatus.NOT_SENT]: 'Not Sent yet/Draft',
}

const labelClass = {
  [EmailTemplateSentStatus.SENT_ONES]: 'sent-one-time',
  [EmailTemplateSentStatus.SENT_TO_MANY]: 'sent-many-times',
  [EmailTemplateSentStatus.NOT_SENT]: 'not-sent-yet',
}

export default injectBaseConstantMethods(
  EmailTemplateSentStatus,
  displayTextKeys,
  labelClass
)
