<template>
	<b-card class="card" v-on:click="() => viewDetails(communityPost.Id)">
		<b-card-title class="card-title post-title">{{
			communityPost.Title
		}}</b-card-title>

		<b-card-sub-title class="card-sub-title"
			>{{ communityPost.Category.Title }} -
			{{
				convertDateTime({
					date: communityPost.CreatedAt,
					customFormat: dateTimeFormat.dateWithYearMonthFormat,
					dateOnly: true,
				})
			}}</b-card-sub-title
		>

		<div
			class="post-img-container w-100 blur"
			:style="`
      background-image: url(${getMediaPath(communityPost.Media[0])});`"
		>
			<div class="img-blur-wrapper"></div>
			<b-img
				fluid
				class="mb-2 post-img"
				:src="getMediaPath(communityPost.Media[0])"
			/>
		</div>

		<b-card-text class="card-hash">{{ hash }}</b-card-text>
		<!-- <b-card-text
      class="card-bottom-text post-content"
      v-html="communityPost.Content"
    ></b-card-text> -->
	</b-card>
</template>

<script>
import {
	BRow,
	BCol,
	BCard,
	BCardBody,
	BCardTitle,
	BCardSubTitle,
	BCardText,
	BTableLite,
	BButton,
	BAlert,
	BLink,
	BImg,
	BBadge,
	BAvatar,
} from 'bootstrap-vue'
import { truncateString } from '../../utils/commonUtils'
import { dateTimeFormat, convertDateTime } from '../../utils/dateFormatUtils'
import { getMediaPath } from '../../utils/commonUtils'

import { APP_ROUTES, getRoute } from '../../helpers/routeHelpers'

export default {
	name: 'CommunityPostListItem',
	components: {
		BRow,
		BCol,
		BCard,
		BCardBody,
		BTableLite,
		BCardText,
		BCardTitle,
		BCardSubTitle,
		BButton,
		BAlert,
		BLink,
		BAvatar,
		BBadge,
		BImg,
	},
	props: {
		communityPost: Object,
	},
	methods: {
		viewDetails: function (id) {
			this.$router.push(
				getRoute(APP_ROUTES.COMMUNITY_POST_DETAILS, {
					communityPostId: id,
				})
			)
		},
	},
	data() {
		let hash = ''
		hash = this.communityPost.Hashtag.map((item) => `#${item.Title}`).join(
			' '
		)
		return {
			hash,
			truncateString,
			convertDateTime,
			dateTimeFormat,
			getMediaPath,
		}
	},
}
</script>

<style scoped>
.card {
	cursor: pointer;
	min-height: 350px;
}
.card-title {
	color: #003071;
	font-size: 18px;
}

.card-sub-title {
	color: #888888;
	font-size: 14px;
	margin-bottom: 10px;
}
.card-hash {
	color: #888888;
	font-size: 14px;
	margin-top: 10px;
}

.card-bottom-text {
	font-size: 14px;
	color: black;
}
.post-img-container {
	/* width: 440px !important; */
	height: 211px !important;
	z-index: 1 !important;
	position: relative !important;
}

.img-blur-wrapper {
	background: #212020c7 !important;
	width: 100% !important;
	height: 100% !important;
	position: absolute !important;
	z-index: -1 !important;
	backdrop-filter: blur(3px) !important;
}

::v-deep.post-img {
	width: 100% !important;
	height: 100% !important;
	object-fit: contain !important;
	z-index: 9999999999 !important;
}
.post-title {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
::v-deep.post-content {
	overflow: hidden !important;
	text-overflow: ellipsis !important;
	white-space: nowrap !important;
}
</style>
