<template>
	<BasicModal
		id="send-email-modal"
		:onClose="handleModalClose"
		modalTitle="Send Email"
	>
		<template #showForm>
			<div>
				<b-form @submit.prevent="handleSubmit">
					<b-form-group
						label="Select recipients to send email"
						class="w-75"
					>
						<b-form-radio-group
							v-model="sendTypeRadioSelected"
							:options="sendTypeRadioOption"
							class="demo-inline-spacing font-weight-bolder"
							name="radio-inline"
						/>
					</b-form-group>

					<v-select
						class="style-chooser"
						:disabled="sendTypeRadioSelected == 3 ? false : true"
						placeholder="Search Members Name"
						v-model="selectedMembers"
						multiple
						label="title"
						:options="membersList"
						@search="handleUsersSearch"
					/>
					<div class="d-flex justify-content-end mt-1">
						<div class="w-50">
							<Button
								:isLoading="isSendingEmail"
								buttonVariant="primary"
								buttonText="Send Email"
								classes="w-100"
								type="submit"
							/>
						</div>
					</div>
				</b-form>
			</div>
		</template>
	</BasicModal>
</template>

<script>
import {
	BCard,
	BCardBody,
	BFormInput,
	BFormGroup,
	BForm,
	BFormRadioGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

import Button from '../../components/Button.vue'
import BasicModal from '../../components/BasicModal.vue'
import { EmailSendType } from '../../constants'
import { getUserList } from '../../api/api.service'

export default {
	name: 'SendEmailModal',
	data: () => {
		return {
			sendTypeRadioSelected: null,
			sendTypeRadioOption: [
				{
					text: EmailSendType.getDisplayTextKey(
						EmailSendType.ALL_MEMBERS
					),
					value: EmailSendType.ALL_MEMBERS,
				},
				{
					text: EmailSendType.getDisplayTextKey(
						EmailSendType.ALL_GUIDES
					),
					value: EmailSendType.ALL_GUIDES,
				},
				{
					text: EmailSendType.getDisplayTextKey(
						EmailSendType.SPECIFIC_USERS
					),
					value: EmailSendType.SPECIFIC_USERS,
				},
			],
			membersList: [],
			selectedMembers: null,
		}
	},
	components: {
		BCard,
		BCardBody,
		BFormRadioGroup,
		BFormInput,
		BFormGroup,
		BForm,
		vSelect,
		BasicModal,
		Button,
	},

	methods: {
		async handleUsersSearch(search = '', loading) {
			try {
				loading(true)
				let result = await getUserList({
					Q: search,
					Page: 1,
					Limit: 10,
					Direction: 'DESC',
					Column: 'CreatedAt',
				})
				if (result.Users.length) {
					let optionForSelect = result.Users.map((item) => ({
						title: item?.FullName,
						value: item?.Id,
					}))
					this.membersList = [...optionForSelect]
				}
			} catch (error) {
				console.log('Error', error)
			} finally {
				loading(false)
			}
		},
		handleSubmit() {
			let data = {
				sendToType: this.sendTypeRadioSelected,
				...(this.sendTypeRadioSelected == 3 &&
					this.selectedMembers?.length > 0 && {
						users: [
							...this.selectedMembers.map((item) => item.value),
						],
					}),
			}
			this.onSubmit(data)
		},

		handleModalClose() {
			this.selectedMembers = null
			this.sendTypeRadioSelected = null
			this.membersList = []
		},
	},
	watch: {
		async sendTypeRadioSelected(newVal) {
			if (newVal == 3) {
				try {
					let result = await getUserList({
						Page: 1,
						Limit: 10,
						Direction: 'DESC',
						Column: 'CreatedAt',
					})
					if (result.Users.length) {
						let optionForSelect = result.Users.map((item) => ({
							title: item?.FullName,
							value: item?.Id,
						}))
						this.membersList = [...optionForSelect]
					}
				} catch (error) {
					console.log('Error', error)
				} finally {
				}
			}
		},
	},
	props: ['onSubmit', 'isSendingEmail'],
}
</script>


<style>
.vs__dropdown-option--selected {
	display: none !important;
}
</style>




