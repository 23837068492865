<template>
	<vue-perfect-scrollbar
		:settings="perfectScrollbarSettings"
		class="ps-customizer-area scroll-area cust-scrollbar"
	>
		<div class="spinner-container h-75" v-if="isLoading">
			<b-spinner variant="primary" class="spinner" />
		</div>
		<!-- :subText="
						convertDateTime({
							date: item.CreatedAt,
							customFormat: dateTimeFormat.appDateFormat,
							dateOnly: true,
						})
					" -->
		<b-list-group class="list-container">
			<b-list-group-item
				class="pl-0 pr-0"
				v-for="item in usersList"
				:key="item.Id"
				><UserInfo
					:avatarImage="getMediaPath(item.Media)"
					:fullName="item.FullName"
			/></b-list-group-item>
		</b-list-group>
	</vue-perfect-scrollbar>
</template>

<script>
import { BListGroup, BListGroupItem, BSpinner } from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

import UserInfo from './UserInfo.vue'
import { getLikedUersProfile } from '../api/api.service'
import { getMediaPath } from '@/utils/commonUtils'
import { dateTimeFormat, convertDateTime } from '@/utils/dateFormatUtils'
import showToast from '@/hooks/useToast'

export default {
	components: {
		BListGroup,
		BListGroupItem,
		BSpinner,
		UserInfo,
		VuePerfectScrollbar,
	},

	data() {
		return {
			isLoading: false,
			usersList: [],
			getMediaPath,
			dateTimeFormat,
			convertDateTime,
		}
	},

	props: {
		viewedUsers: {
			type: Array,
			default: [
				{
					id: '0',
					userName: 'Jonh Mike',
					userAvatar: require('@/assets/images/avatars/2.png'),
					userTimeStamp: '19 Dec, 2021',
				},
				{
					id: '0',
					userName: 'Gregory Moaldonado',
					userAvatar: require('@/assets/images/avatars/2.png'),
					userTimeStamp: '5 min ago',
				},
				{
					id: '0',
					userName: 'Leonard Cohen',
					userAvatar: require('@/assets/images/portrait/small/avatar-s-5.jpg'),
					userTimeStamp: '19 Dec, 2021',
				},
				{
					id: '0',
					userName: 'Matilda Robertson',
					userAvatar: require('@/assets/images/portrait/small/avatar-s-7.jpg'),
					userTimeStamp: '19 Dec, 2021',
				},
				{
					id: '0',
					userName: 'Martha Barnes',
					userAvatar: require('@/assets/images/portrait/small/avatar-s-10.jpg'),
					userTimeStamp: '19 Dec, 2021',
				},
				{
					id: '0',
					userName: 'Monaco',
					userAvatar: require('@/assets/images/portrait/small/avatar-s-8.jpg'),
					userTimeStamp: '19 Dec, 2021',
				},
				{
					id: '0',
					userName: 'Minnie Hoffman',
					userAvatar: require('@/assets/images/portrait/small/avatar-s-20.jpg'),
					userTimeStamp: '19 Dec, 2021',
				},
				{
					id: '0',
					userName: 'Lawrence Walton',
					userAvatar: require('@/assets/images/avatars/2.png'),
					userTimeStamp: '19 Dec, 2021',
				},
				{
					id: '0',
					userName: 'Monaco',
					userAvatar: require('@/assets/images/avatars/3.png'),
					userTimeStamp: '19 Dec, 2021',
				},
			],
		},
		submissionId: {
			type: String,
		},
	},

	beforeMount() {
		this.getUserLikedList(this.submissionId)
	},

	methods: {
		async getUserLikedList(id) {
			if (!id) {
				return
			}

			try {
				this.isLoading = true
				let users = await getLikedUersProfile(id)
				this.usersList = users.Users
				this.isLoading = false
			} catch (err) {
				this.isLoading = false

				showToast(this, {
					Title: 'Error',
					Icon: 'XCircleIcon',
					Text: err.Message,
					Variant: 'danger',
					Position: 'top-right',
				})
			} finally {
				this.isLoading = false
			}
		},
	},
}
</script>

<style scoped>
.list-container > * {
	border: 0 !important;
}
.ps-customizer-area {
	width: 100% !important;
	height: 537px !important;
}
.spinner {
	width: 3rem;
	height: 3rem;
}
.spinner-container {
	display: flex;
	justify-content: center;
	align-items: center;
}
</style>