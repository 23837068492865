export const emailConfig = {
  minHeight: '100',
  locale: 'en',
  projectId: 107827,
  tools: {
    image: {
      enabled: true,
    },
  },
  options: {},
  appearance: {
    theme: 'light',
    panels: {
      tools: {
        dock: 'right',
      },
    },
  },
}
