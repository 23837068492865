<template>
  <!-- Login-->
  <div>
    <b-card-title title-tag="h4" class="font-weight-bolder mb-1">
      Reset Password
    </b-card-title>

    <validation-observer ref="codeValidation" v-if="!codeVerified">
      <b-form class="auth-login-form mt-2" @submit.prevent>
        <b-form-group>
          <div class="d-flex justify-content-between">
            <label for="verify-code" class="font-weight-bolder"
              >Enter the verification code sent to your e-mail :</label
            >
          </div>
          <validation-provider
            #default="{ errors }"
            name="Code"
            rules="required"
          >
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid' : null"
            >
              <b-form-input
                id="verify-code"
                v-model="code"
                :state="errors.length > 0 ? false : null"
                class="form-control-merge"
                name="verify-code"
              />
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- submit buttons -->
        <Button
          type="submit"
          buttonVariant="primary"
          blockButton
          buttonText="Submit"
          :clickHandler="handleCodeSubmit"
        />
      </b-form>
    </validation-observer>

    <div v-if="codeVerified">
      <validation-observer ref="resetValidation">
        <b-form class="auth-login-form mt-2" @submit.prevent>
          <!-- email -->

          <!-- forgot password -->
          <b-form-group>
            <div class="d-flex justify-content-between">
              <label for="login-password" class="font-weight-bolder"
                >Enter New Password</label
              >
            </div>
            <validation-provider
              #default="{ errors }"
              name="Password"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="login-password"
                  v-model="password"
                  :state="errors.length > 0 ? false : null"
                  class="form-control-merge"
                  :type="passwordFieldType"
                  name="login-password"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="passwordToggleIcon"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <div class="d-flex justify-content-between">
              <label for="loginre-password" class="font-weight-bolder"
                >Re-type New Password</label
              >
            </div>
            <validation-provider
              #default="{ errors }"
              name="Confirm Password"
              rules="required"
            >
              <b-input-group
                class="input-group-merge"
                :class="errors.length > 0 ? 'is-invalid' : null"
              >
                <b-form-input
                  id="loginre-password"
                  v-model="confirmPassword"
                  :state="errors.length > 0 ? false : null"
                  class="form-control-merge"
                  :type="showConfirmPassword ? 'text' : 'password'"
                  name="loginre-password"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    class="cursor-pointer"
                    :icon="confirmPasswordToggleIcon"
                    @click="toggleConfirmPasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- submit buttons -->
          <Button
            type="submit"
            buttonVariant="primary"
            blockButton
            buttonText="Reset Password"
            :clickHandler="handleResetPassword"
          />
        </b-form>
      </validation-observer>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Input from "../../../components/Input.vue";
import Button from "../../../components/Button.vue";
import {
  forgotPasswordVerification,
  resetPassword,
} from "../../../api/api.service";
import { APP_ROUTES } from "../../../helpers/routeHelpers";
import { password } from "@/@core/utils/validations/validations";

export default {
  name: "AuthResetPassword",
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    Input,
    Button,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: "",
      password: "",
      confirmPassword: "",
      userEmail: "",
      // validation rulesimport store from '@/store/index'
      required,
      email,
      code: "",
      codeVerified: false,
      showConfirmPassword: false,
      tokenUUID: null,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    confirmPasswordToggleIcon() {
      return this.showConfirmPassword ? "EyeOffIcon" : "EyeIcon";
    },
  },
  methods: {
    toggleConfirmPasswordVisibility() {
      this.showConfirmPassword = !this.showConfirmPassword;
    },
    handleCodeSubmit() {
      this.$refs.codeValidation.validate().then((success) => {
        if (!success) {
          return;
        }

        let payload = {
          Code: this.code,
          TokenUUID: this.$route.params.tokenUUID,
        };

        forgotPasswordVerification(payload)
          .then((res) => {
            this.codeVerified = true;
            this.tokenUUID = res.TokenUUID;
          })
          .catch((err) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: err.Message,
                icon: "EditIcon",
                variant: "danger",
              },
            });
          });
      });
    },
    handleResetPassword() {
      this.$refs.resetValidation.validate().then((success) => {
        if (!success) {
          return;
        }
        if (this.password !== this.confirmPassword) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Passwords donot match",
              icon: "AlertCircleIcon",
              variant: "danger",
            },
          });

          return;
        }

        let payload = {
          Code: this.code,
          TokenUUID: this.tokenUUID,
          Password: this.password,
        };
        resetPassword(payload)
          .then((res) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: res.Message,
                icon: "AlertCircleIcon",
                variant: "success",
              },
            });
            this.$router.replace(APP_ROUTES.LOGIN);
          })
          .catch((err) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: err.Message,
                icon: "AlertCircleIcon",
                variant: "danger",
              },
            });
          });
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>

<style scoped>
.middle-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
}
</style>
