<template>
	<b-modal
		:id="id"
		centered
		hide-header
		hide-footer
		:no-close-on-backdrop="isLoading || isSuccess"
		:no-close-on-esc="isLoading || isSuccess"
	>
		<div v-if="!isLoading && isSuccess" class="container">
			<feather-icon icon="CheckCircleIcon" size="96" class="restrict success" />

			<p class="text">{{ successText }}</p>

			<div class="btn-container justify-content-center">
				<Button
					size="md"
					buttonVariant="primary"
					classes="wd-211"
					buttonText="Okay"
					:clickHandler="handleOkayPress"
				/>
			</div>
		</div>
		<div v-if="!isLoading && !isSuccess" class="container">
			<img :src="messageIcon" class="restrict" />

			<p class="text">{{ messageText }}</p>

			<div v-if="!hideActionBtns" class="btn-container">
				<Button
					size="md"
					buttonVariant="primary"
					classes="wd-211"
					buttonText="Yes"
					:clickHandler="onYesPress"
				/>

				<Button
					size="md"
					buttonVariant="danger"
					classes="wd-211"
					buttonText="No"
					:clickHandler="() => $bvModal.hide(id)"
				/>
			</div>
			<div v-if="errorOkBtn" class="d-flex justify-content-center">
				<Button
					size="md"
					buttonVariant="danger"
					classes="wd-211"
					:buttonText="errorOkBtnText"
					:clickHandler="() => $bvModal.hide(id)"
				/>
			</div>
		</div>
		<div v-if="isLoading" class="container">
			<b-spinner variant="primary" class="spinner"></b-spinner>
			<p class="text">Processing...</p>
		</div>
	</b-modal>
</template>

<script>
import { BModal, BButton, VBModal, BAlert, BSpinner } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import Button from '../components/Button.vue'

export default {
	name: 'ConfirmModal',
	components: {
		VBModal,
		BButton,
		BModal,
		BAlert,
		BSpinner,
		Button,
	},
	methods: {
		handleOkayPress() {
			this.$bvModal.hide(this.$props.id)
			setTimeout(() => {
				this.$props.onOkayPress()
			}, 300)
		},
	},
	props: {
		errorOkBtn: {
			type: Boolean,
			default: false,
		},
		errorOkBtnText: {
			type: String,
			default: 'OK',
		},
		hideActionBtns: {
			type: Boolean,
			default: false,
		},
		messageText: {
			type: String,
			default: 'Are you sure you want to delete this?',
		},
		messageIcon: {
			type: String,
			default: require('@/assets/images/icons/red-cross-icon.png'),
		},
		id: {
			type: String,
		},
		isLoading: Boolean,
		isSuccess: Boolean,
		successText: String,
		onOkayPress: Function,
		onYesPress: Function,
	},
	directives: {
		'b-modal': VBModal,
		Ripple,
	},
}
</script>

<style scoped>
.container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.restrict {
	width: 96px;
	height: 96px;
	margin-top: 40px;
	align-self: center;
}

.success {
	color: #28c76f;
}

.text {
	font-weight: 600;
	font-size: 20px;
	color: #000;
	align-self: center;
	text-align: center;
	margin-top: 38px;
}

.btn-container {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
	margin-top: 65px;
	margin-bottom: 10px;
}

.spinner {
	width: 5rem;
	height: 5rem;
	margin-top: 20px;
}

::v-deep .wd-211 {
	width: 200px;
}
</style>
